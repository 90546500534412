<template>
    <div class="page-background">
        <div class="main-big-text">
            <p>One-To-All</p>
            <p>Connectivity Platform</p>
            <p>Cereb is a layman-friendly configurations software to enable commercial-scale Internet-of-things (IoT) interoperability and automations.</p>
        </div>
        <div class="login-box">
            <!-- <div class="login-box-img-box">
                <img class="login-box-img-box-img" src="../assets/login-page-big-img.png">
            </div> -->
            <div class="login-box-login">
                <div class="logo-app_name-box">
                    <img class="login-box-login-logo" src="../assets/logo.png">
                    <div class="login-box-login-app-name">Cereb Platform</div>
                </div>

                <div class="login_text-to-signup_hyperlink-box" v-if="loginView === 'login'">                
                    <div class="login-form-username_or_email-text">Username or Email：</div>
                    <input class="login-form-username_or_email-input" type="text" v-model="username" name="username" />
                    <div class="login-form-password-text">Password：</div>
                    <input class="login-form-password-input" type="password" v-model="password" name="password" />   
					
					<div class="message-panel">
					</div>
                    
                    <el-button class="confirm-button" type="button" :loading="loginButtonLoading" @click="loginButtonClick">
                        <span class="confirm-text">
                            Log In
                        </span>
                    </el-button>
					
					<div class="button-panel">
						<div class="forget-password-box" @click="forgetPasswordClick">
							<a href="#">
								<span class="forget-password">Forget Password</span>
							</a>
						</div>
						
						<div class="sign-up-box" @click="signupClick">
							<a href="#">
								<span class="sign-up">
									Sign up
								</span>
							</a>
						</div>
					</div>
					
                </div>

                <div class="login_text-to-signup_hyperlink-box" v-if="loginView === 'verification_code'">                
                    <div class="verification_text">An email with your confirmation code has been sent to {{loginRespData.email}}.</div>

                    <div class="verification_code_form_text">Code:</div>
                    <input class="login-form-password-input" type="text" v-model="verificationCode" name="verificationCode" />   

                    <el-button class="verification_confirm_button" type="button" :loading="confirmButtonLoading" @click="confirmButtonClick">
                        <span class="confirm-text">
                            Continue
                        </span>
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {login, loginEmail, verifyLogin} from '../api'
import Isemail from 'isemail';

export default {
    name: 'Login',

    props: {
        msg: String,
		show_message: {
			type: String,
			default: () => '',
		},
    },

    data : function() {
        return {
            username: '',
            password: '',
            loginButtonLoading: false,

            loginRespData: {},
            verificationCode: '',
            confirmButtonLoading: false,

            loginView: 'login',
        }
    },

    methods : {
        // loginButtonClick() {
        //     // console.log(this.username + " " + this.password);
        //     if (this.username && this.password) {
        //         //this.loginButtonLoading = true;
        //         this.loginButtonLoading = true;
        //         let isEmail = Isemail.validate(this.username);
        //         let loginPromise = null;
        //         if (isEmail) {
        //             loginPromise = loginEmail(this.username, this.password);
        //         } else {
        //             loginPromise = login(this.username, this.password);
        //         }
        //         loginPromise.then((token) => {
        //             // handle success
        //             // console.log(token);
        //             localStorage.token = token
        //             this.$router.push({ 'name': 'home'})
        //         }).catch(err => {
        //             console.log(err)
        //             if (err === 'unauthorized') {
        //                 this.$message.error({center: true, message: 'invalid password'});
        //             } else {
        //                 this.$message.error({center: true, message: 'network error'});
        //             }
        //         }).finally(() => {
        //               //具体得意义   渲染出一个在登录的圈圈，且此时不能再点击登录
        //             //this.loginButtonLoading = false;
        //             this.loginButtonLoading = false;
        //         })
        //     } else {
        //         this.$message.error({center: true, message: 'Please enter the complete information！'})
        //     }
        // },

        loginButtonClick() {
            if (this.username && this.password) {
                this.loginButtonLoading = true;
                let isEmail = Isemail.validate(this.username);
                let loginPromise = null;
                if (isEmail) {
                    loginPromise = loginEmail(this.username, this.password);
                } else {
                    loginPromise = login(this.username, this.password);
                }
                loginPromise.then((data) => {
                    if (data && data.email && data.msgId) {
                        this.loginRespData = data
                        this.loginView = 'verification_code'
                    } else {
                        this.goHomePage(data.token)
                    }
                }).catch(err => {
                    console.log(err)
                    if (err === 'unauthorized') {
                        this.$message.error({center: true, message: 'Invalid password'});
                    } else {
                        this.$message.error({center: true, message: 'Network error'});
                    }
                }).finally(() => {
                    this.loginButtonLoading = false;
                })
            } else {
                this.$message.error({center: true, message: 'Please enter the complete information！'})
            }
        },

        confirmButtonClick() {
            if (this.verificationCode) {
                this.confirmButtonLoading = true;
                verifyLogin(this.loginRespData.msgId, this.verificationCode).then((rsp) => {
                    this.goHomePage(rsp.token)
                }).catch(err => {
                    console.log(err)
                    this.$message.error({center: true, message: 'Invalid verification code'});
                }).finally(() => {
                    this.confirmButtonLoading = false;
                })
            } else {
                this.$message.error({center: true, message: 'Please enter the complete information！'})
            }
        },

        goHomePage(token) {
            localStorage.token = token
            this.$router.push({ 'name': 'home'})
        },

        signupClick() {
            // localStorage.token = 'todo'
            console.log("signup------------------------------------------->")
            this.$router.push({ 'name': 'signup'}).catch(err => (err.log))
        },
        forgetPasswordClick() {
            // localStorage.token = 'todo'
            console.log("signup------------------------------------------->")
            this.$router.push({ 'name': 'requestResetPassword'}).catch(err => (err.log))
        }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
    /* Login Page */
    .login-box {transform: translate(-50%, -50%);}
    .login-box input {padding:0px 10px;}
    .login-box input.login-form-username_or_email-input {width:289px; font-size: 14px;}
    .login-box input.login-form-password-input {width:289px; font-size: 14px;}
    .login-box .message-panel {padding:10px 0px 5px; color:#FFFFFF; font-size:14px;}
    .login-box .button-panel {overflow:hidden;}
    .login-box .button-panel .forget-password-box {float:right; width:auto;}
    .login-box .button-panel .sign-up-box {float:left;}
    /* Login Page */




    .page-background {
        box-sizing: content-box;
        z-index: -2;
        position: absolute;
        width: 100%;
        height: 100%;
        /* background: linear-gradient(360deg, #212A3A 0%, #182130 100%); */
        background-image: url(../assets/page-background-img.png);
        position:fixed;
        top: 0;
        left: 0;    
        width:100%;
        height:100%;
        min-width: 1000px;
        z-index:-10;
        zoom: 1;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center 0;  
        background-size:100% 100%;
    }


    .login-box {
        z-index: -1;
        position: absolute;
        /*margin-left: -460px;
        margin-top: -230px;*/
        left: 75%;
        top: 50%;
		transform: translate(-50%, -50%);
        width: 418px;
        min-height: 460px;
        /* background: #112F7E; */
        border-radius: 2px;
    }


    /* .login-box-img-box { */
        /* z-index: 0; */
        /* position: absolute; */
		/* position: relative;
		float:left;
        width: 542px;
        min-height: 460px;
    } */
    /* .login-box-img-box-img {
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -219px;
        margin-top: -219px;
        max-width: 438px;
        max-height: 438px;
    } */



    .login-box-login {
        z-index: 0;
        /*position: absolute;*/
		position: relative;
		float:left;
        right: 0%;
        bottom: 0%;
        width: 378px;
        min-height: 460px;
        background: #FEFEFE;
        border-radius: 2px;
    }

    

    .logo-app_name-box {
        z-index: 1;
        position: absolute;
        margin-left: 34px;
        margin-top: 40px;
        margin-right: 34px;
        margin-bottom: 382px;
        width: 310px;
        height: 38px;
    }

    .login-box-login-logo {
        z-index: 2;
        position: absolute;
        left: 61px;
        top: 0px;
        width: 38px;
        height: 38px;
    }

    .login-box-login-app-name {
        z-index: 2;
        position: absolute;
        margin-left: 105px;
        margin-top: 5px;
        margin-right: 19px;
        margin-bottom: 5px;
        width: 142px;
        height: 26px;
        font-size: 19px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0B0E14;
        line-height: 26px;
    }


    .login_text-to-signup_hyperlink-box {
        z-index: 1;
        /*position: absolute;*/
        /*margin-left: 34px;
        margin-top: 108px;
        margin-right: 78px;
        margin-bottom: 22px;*/
		padding-left: 34px;
        padding-top: 108px;
        padding-right: 78px;
        padding-bottom: 22px;
        width: 310px;
        min-height: 330px;
    }


    .login-text {
        z-index: 2;
        width: 266px;
        height: 28px;
        font-size: 19px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }

    .login-form-username_or_email-text {
        z-index: 2;
        margin-top: 29px;
        width: 144px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #616161;
        line-height: 14px;
    }

    .login-form-username_or_email-input {
        box-sizing: content-box;
        z-index: 2;
        margin-top: 12px;
        /* width: 310px;
        height: 40px; */
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        font-size: 14px;
    }

    .login-form-password-text {
        z-index: 2;
        margin-top: 13px;
        width: 104px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #616161;
        line-height: 14px;
    }
    .verification_code_form_text {
        z-index: 2;
        margin-top: 19px;
        width: 104px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #616161;
        line-height: 14px;
    }
    input {
        /* background: #182130;  设置输入框背景色 */
        padding: 0px 0px;
        /* color: #FFFFFF; */
        /* outline: none; */
    }

    input:focus { outline: 1px solid #FCB900; }

    .login-form-password-input {
        box-sizing: content-box;
        z-index: 2;
        margin-top: 12px;
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        font-size: 14px;
    }

    a {
        text-decoration: none;
    }

    .forget-password-box {
        z-index: 2;
        margin-top: 14px;
        width: 310px;
        height: 16px;

        text-align: right;
    }
    .forget-password {
        z-index: 3;
        width: 141px;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    .sign-up-box {
        z-index: 2;
        margin-top: 15px;
        text-align: center;
    }
    .sign-up {
        z-index: 3;
        width: 51px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    .confirm-button {
        z-index: 2;
        margin-top: 19px;
        width: 310px;
        height: 44px;
        background: #FCB900;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }
    .verification_confirm_button {
        z-index: 2;
        margin-top: 43px;
        width: 310px;
        height: 44px;
        background: #FCB900;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    .confirm-text {
        width: 108px;
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
    }
    .verification_text {
        width: 310px;
        height: 48px;
        margin-top: 34px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #616161;
        line-height: 19px;
    }

    body {
    display: block;
    margin: 0px;
    }
</style>
<style scoped>
.page-background .main-big-text{
    float: left;
    /* width: 500px; */
    height: 400px;
    margin-top: 12%;
    margin-left: 12%;
}
.page-background .main-big-text p{
    padding: 0;
    margin: 25px 0 0 0;
}
.page-background .main-big-text p:nth-child(1){
    font-size: 43px;
    color: #FCB900;
    font-family: HarmonyOS_Sans_Black;
}
.page-background .main-big-text p:nth-child(2){
    font-size: 43px;
    color: #FEFEFE;
    font-family: HarmonyOS_Sans_Black;
}
.page-background .main-big-text p:nth-child(3){
    font-size: 14px;
    font-family: HarmonyOS_Sans;
    color: #FFFFFF;
    width: 419px;
    opacity: 0.8;
}
</style>