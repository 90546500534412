import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

// import Vuex from 'vuex'
import store from './store/index.js'

// import './plugins/element.js'

import Login from './pages/Login.vue'
import Signup from './pages/Signup.vue'
import RequestResetPassword from './pages/RequestResetPassword.vue'
import ResetPassword from './pages/ResetPassword.vue'
// import ResetPasswordCode from './pages/ResetPasswordCode.vue'

import Home from './pages/Home.vue'

import Setting from './pages/Setting.vue'
// import Control from './pages/Control.vue'
import {
	EventBus
} from "./event-bus.js";
import {setCookie} from './utils/cookie'

import CustomMessage from './custom-plugins/custom-message/CustomMessage.js'

import { Upload, Cascader, Tooltip, Input, Button, Select, Switch, Pagination, Dropdown, DropdownMenu, DropdownItem, Table, TableColumn, Loading, Row, Col, Header, Image, Message, Option, Scrollbar, Checkbox, CheckboxGroup } from 'element-ui';

import {Tabs, TabPane, Slider} from "element-ui";

import VueTypedJs from 'vue-typed-js';
Vue.use(VueTypedJs)

Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Slider)

Vue.use(Button)
Vue.use(Select)
Vue.use(Switch)
Vue.use(Pagination)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Loading)
Vue.use(Row)
Vue.use(Col)
Vue.use(Header)
Vue.use(Image)
Vue.prototype.$message = Message
Vue.use(Option)
// Vue.use(OptionGroup)
Vue.use(Scrollbar)
Vue.use(Input)
Vue.use(Tooltip)
Vue.use(Cascader)
Vue.use(Upload)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

Vue.use(CustomMessage)

Vue.use(VueRouter)
// Vue.use(Vuex)
Vue.config.productionTip = false
const routes = [{
		name: 'login',
		path: '/',
		component: Login
	}, {
		name: 'signup',
		path: '/',
		component: Signup
	}, {
		name: 'requestResetPassword',
		path: '/',
		component: RequestResetPassword
	},
	// {name: 'resetPasswordCode', path: '/', component: ResetPasswordCode},
	{
		name: 'resetPassword',
		path: '/',
		component: ResetPassword
	},

	{
		name: 'home',
		path: '/',
		component: Home
	},

	{
		name: 'setting',
		path: '/',
		component: Setting
	},

	// {
	// 	name: 'control',
	// 	path: '/vcontroller',
	// 	component: Control
	// },
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
	// mode: 'history',
	routes: routes // (缩写) 相当于 routes: routes
})

function isAuthenticated() {
	// 返回token字符串还是Boolean值？？？ 猜想是token字符串
	return localStorage.token
}

// 
router.beforeEach((to, from, next) => {

	// if (isAuthenticated()) {
	// 	next()
	// } else {
	// 	next({
	// 		'name': 'login'
	// 	})
	// }
	if (to.name === 'requestResetPassword' || to.name === 'resetPassword' || to.name === 'control') {
		next()
	} else if (!(to.name === 'login' || to.name === 'signup') && !isAuthenticated()) {
		next({
			'name': 'login'
		})
	} else if (to.name === 'login' && isAuthenticated()) {
		next({
			'name': 'home'
		})
	} else {
		next()
	}

	// if (to.path.substring(1,11) === 'none_login'){
	// 	next()
	// }
	// else if (to.name !== 'login' && !isAuthenticated()) {
	// 	next({
	// 		'name': 'login'
	// 	})
	// } else if (to.name === 'login' && isAuthenticated()) {
	// 	next({
	// 		'name': 'home'
	// 	})
	// } else {
	// 	next()
	// }

})

EventBus.$on('logout', () => {
	// 进入登录页面
	setCookie("access-token", "/", ".cereb.ai")
	localStorage.removeItem('token');
	if (router.currentRoute.name !== 'login') {
		router.go({
			'name': 'login'
		});
	}
});

/*
new Vue({
	router: router,
	render: h => h(App),
}).$mount('#app')
*/
new Vue({
	el: '#app',
	router: router,
	store: store,
	// store,
	render: h => h(App),
})

console.log('v20210714build001');