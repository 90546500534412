<template>
    <div>

        <div class="container page-connect-detail">
            <!-- <div class="breadcrumb">
                Connect / Detail
            </div> -->
            <div class="breadcrumb">
                <button class="go-connect-button" type="button" @click="$emit('goConnect')">
                    <span class="go-connect-button-text">
                        A.I.
                    </span>
                </button>
                <span> / Detail</span>
            </div>

            <div class="connect-brand-box">
                <div class="col-1">
                    <div class="connect-brand-image1"><img :src="connect.iconUrl"></div>
                </div>
                <div class="col-2">
                    <div class="connect-brand-title1">{{connect.name}}</div>
                    <div class="connect-brand-title2">{{connect.description}}</div>
                    <div v-if="!brandReady" class="connect-brand-not-ready">AI will be ready soon, for any
                        question, please contact us: info@cereb.ai</div>
                </div>
            </div>

            <div class="connect-button-panel">
                <button class="add-button cpt-btn1" type="button" @click="createAIDetail">
                    <span class="add-text">
                        Create
                    </span>
                </button>
            </div>

            <div class="connect-detail-list-box">
                <div class="connect-detail-list-header">
                    <div class="header-col col-1 padding1">
                        <span>ID</span>
                    </div>
                    <div class="header-col col-2 padding1"><span>Name</span></div>
                    <div class="header-col col-4 padding1"><span>Operation</span></div>
                </div>
                <div class="connect-detail-list-header-line"></div>

                <div class="connect-detail-list-body">
                    <!-- loop connect-detail for row -->
                    <div class="connect-detail-row" v-for="connectInstanceVo in connectInstanceVos"
                        :key="connectInstanceVo.id">
                        <div class="body-col col-1 padding1">
                            <div>
                                {{ connectInstanceVo.id }}
                            </div>
                        </div>
                        <div class="body-col col-2 padding1">
                            <div>
                                {{ connectInstanceVo.name }}
                            </div>
                        </div>
                        <div class="body-col col-4 padding1">
                            <div>
                                <div class="connect-detail-button-panel">
                                    <a href="#" class="cpt-connect-link2 stop-button"
                                        @click="editAIDetail(connectInstanceVo)">
                                        <span>
                                            Edit
                                        </span>
                                    </a>
                                    |
                                    <a href="#" class="cpt-connect-link2 delete-button"
                                        @click="deleteAIDetail(connectInstanceVo.id)">
                                        <span>
                                            Delete
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- loop connect-detail for row -->
                </div>

            </div>

        </div>

        <div class="popup-panel">
            <!-- Delete -->
            <div class="popup delete-connect-detail" v-bind:class="{show : show == 'popup-delete'}">
                <div class="popup-bg"></div>
                <div class="popup-content">
                    <div class="popup-delete-text">Delete?</div>
                    <div class="popup-button-panel">
                        <button class="cancel-delete-button cpt-btn2" type="button" @click="popupConfirmCancel">
                            <span class="cancel-delete-text">
                                Cancel
                            </span>
                        </button>

                        <div class="confirm_delete_box">
                            <el-button type="primary" :loading="confirmDeleteButtonLoadingStatus"
                                @click="popupConfirmDelete">Confirm</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Delete -->

            <cochl-setting v-if="show_setting === 'Cochl'" :connect="connect" :isCreate="isCreate" :isEdit="isEdit"
                :connectInstanceId="connectInstanceId" @closeSetting="closeSetting"></cochl-setting>

            <!-- <common-setting v-if="show_setting === 'Common'" @closeSetting="closeSetting"></common-setting> -->
        </div>

    </div>

</template>

<script>
    import {
        getConnectInstanceListByConnectId,
        deleteConnectInstance,
    } from '../api'

    // import CommonSetting from './CommonSetting';
    import CochlSetting from './CochlSetting';

    export default {

        components: {
            // CommonSetting,
            CochlSetting,
        },
        name: 'AIDetail', // TODO 这是什么
        props: {
            connect: Object,
        },
        emits: ['goConnect'],
        data() {
            return {
                show: '',

                currentEditedConnectInstanceName: '',

                show_setting: null,

                show_message: '',

                connectInstanceId: -1,

                connectInstanceVos: [],
                connectInstanceVoList: [],

                deletedConnnectInstanceId: -1,

                isEdit: false,
                isCreate: false,

                confirmDeleteButtonLoadingStatus: false,

                loadingStatus: false,
            }
        },
        computed: {
            brandReady: function () {
                if (this.connect.name === 'Cochl') {
                    return true;
                } else {
                    return false;
                }
            },
        },
        methods: {
            closeSetting() {
                this.isCreate = false,
                    this.isEdit = false
                this.show_setting = null

                this.getConnectInstanceList()
            },

            createAIDetail() {
                this.isCreate = true
                this.isEdit = false

                let connectNames = ['Cochl']

                if (connectNames.findIndex(connectName => connectName === this.connect.name) !== -1) {
                    this.show_setting = this.connect.name;
                } else {
                    this.show_setting = 'Common';
                }
            },

            editAIDetail(connectInstanceVo) {
                this.connectInstanceId = connectInstanceVo.id

                // console.log(this.connectInstanceId)

                this.isEdit = true
                this.isCreate = false
                this.currentEditedConnectInstanceName = connectInstanceVo.name

                this.show_setting = this.connect.name;
            },
            deleteAIDetail(connectInstanceId) {
                this.show = 'popup-delete';
                this.show_message = '';

                this.deletedConnnectInstanceId = connectInstanceId
            },
            popupConfirmCancel() {
                this.show = '';
                this.show_message = '';
            },
            popupConfirmDelete() {
                this.confirmDeleteButtonLoadingStatus = true;

                // this.show = '';
                this.show_message = '';

                deleteConnectInstance(this.connect.id, this.deletedConnnectInstanceId).then(() => {
                    this.getConnectInstanceList()
                    this.show = '';
                }).catch(() => {
                    this.$message.error({
                        center: true,
                        message: 'Network Error!'
                    });
                }).finally(() => {
                    this.confirmDeleteButtonLoadingStatus = false;
                })
            },

            getConnectInstanceList() {
                getConnectInstanceListByConnectId(this.connect.id).then(connectInstanceVoList => {
                    this.connectInstanceVos = []

                    this.connectInstanceVoList = [] // ******************* 可以优化写法

                    for (let i = 0; i < connectInstanceVoList.length; i++) {
                        this.connectInstanceVoList[i] = {
                            id: connectInstanceVoList[i].instanceId,
                            name: connectInstanceVoList[i].name,
                            attr: connectInstanceVoList[i].attr
                        }
                    }
                    this.connectInstanceVos = this.connectInstanceVoList
                })
            },
        },

        mounted: function () {
            this.getConnectInstanceList()
            // console.log(this.connect)
        },
        updated: function () {
            // console.log(this.IQs)
        }
    };
</script>

<style scoped>
    .breadcrumb {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        margin-top: 12px;
        margin-bottom: 23px;
    }

    .cpt-btn1 {
        background: #FCB900;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 0px;
        padding: 7px 23px;
        display: inline;
        cursor: pointer;
    }

    .cpt-btn1 span {
        font-weight: 500;
        color: #FFFFFF;
        line-height: 15px;
        font-size: 15px;
    }

    .cpt-btn2 {
        background: #212A3A;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 0px;
        padding: 7px 23px;
        display: inline;
        cursor: pointer;
    }

    .cpt-btn2 span {
        font-weight: 500;
        color: #999DA5;
        line-height: 15px;
        font-size: 15px;
    }

    .cpt-btn3 {
        background: #182130;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 0px;
        padding: 7px 23px;
        display: inline;
        cursor: pointer;
    }

    .cpt-btn3 span {
        font-weight: 500;
        color: #999DA5;
        line-height: 15px;
        font-size: 15px;
    }

    .cpt-connect-link2 {
        text-decoration: none;
    }

    .cpt-connect-link2 span {
        color: #FCB900;
    }

    .cpt-input1 {
        box-sizing: content-box;
        height: 39px;
        width: 94%;
        border-radius: 4px;
        border: 1px solid #333B47;
        background: #182130;
        color: #FFFFFF;
        padding: 0px 10px;
    }

    /* Connect Detail */
    .container.page-connect-detail {
        margin-left: 121px;
        margin-right: 121px;
        min-height: 537px;
    }

    .page-connect-detail .connect-brand-box {
        background: #182130;
        border-radius: 2px;
        padding: 22px;
        display: flex;
    }

    .page-connect-detail .connect-brand-image1 {
        width: 200px;
        height: 144px;
        background-color: #2C374A;
        margin-right: 26px;
    }

    .page-connect-detail .connect-brand-title1 {
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
        margin-bottom: 11px;
    }

    .page-connect-detail .connect-brand-title2 {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        opacity: 0.4;
    }

    .page-connect-detail .connect-button-panel {
        margin-top: 34px;
        margin-bottom: 26px;
    }

    .page-connect-detail .connect-detail-list-box {
        background: #182130;
        border-radius: 2px;
        font-size: 13px;
    }

    /* .page-connect-detail #connect-detail-list-header {overflow: hidden; color:#DDDDDD; padding:10px 30px;} */
    .page-connect-detail .connect-detail-list-header {
        overflow: hidden;
        color: #DDDDDD;
        padding: 10px 0px;
    }

    .page-connect-detail .connect-detail-list-header-line {
        border-bottom: 1px solid #FFFFFF;
        opacity: 0.04;
    }

    .page-connect-detail .connect-detail-list-header .header-col {
        float: left;
    }

    /* .page-connect-detail #connect-detail-list-box .col-1 {width:15%;}
.page-connect-detail #connect-detail-list-box .col-2 {width:20%;}
.page-connect-detail #connect-detail-list-box .col-3 {width:20%;}
.page-connect-detail #connect-detail-list-box .col-4 {width:40%;} */
    .page-connect-detail .connect-detail-list-box .col-1 {
        margin-left: 35px;
        width: 120px;
    }

    .page-connect-detail .connect-detail-list-box .col-2 {
        margin-left: 13.73px;
        width: 120px;
    }

    .page-connect-detail .connect-detail-list-box .col-3 {
        margin-left: 37.31px;
        width: 120px;
    }

    .page-connect-detail .connect-detail-list-box .col-4 {
        margin-left: 38.42px;
        width: auto;
    }

    /* .page-connect-detail #connect-detail-list-box .padding1 {padding: 10px 5px;} */
    .page-connect-detail .connect-detail-list-box .padding1 {
        padding: 10px 0px;
    }

    /* .page-connect-detail #connect-detail-list-body {overflow: hidden; color:#FFFFFF; padding:0px 30px 10px;} */
    .page-connect-detail .connect-detail-list-body {
        overflow: hidden;
        color: #FFFFFF;
        padding: 0px 0px 10px;
    }

    .page-connect-detail .connect-detail-list-body .body-col {
        float: left;
        display: flex;
    }

    .page-connect-detail .connect-detail-list-body .col-1,
    .page-connect-detail .connect-detail-list-body .col-2,
    .page-connect-detail .connect-detail-list-body .col-3 {
        opacity: 0.49;
    }

    .page-connect-detail .connect-detail-list-body .connect-detail-row {
        padding: 3px 0px;
        overflow: hidden;
    }

    .page-connect-detail .connect-detail-list-body .connect-detail-button-panel {
        color: #FCB900;
    }

    /* 需要edit时打开注释 */
    .page-connect-detail .cpt-connect-link2.stop-button {
        margin-right: 20px;
    }

    .page-connect-detail .cpt-connect-link2.start-button {
        margin-right: 20px;
    }

    .page-connect-detail .cpt-connect-link2.edit-button {
        margin: 0 20px;
    }

    .page-connect-detail .cpt-connect-link2.delete-button {
        margin-left: 20px;
    }

    /* Connect Detail */

    .popup {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 100%;
        z-index: 100;
        display: none;
    }

    .popup.show {
        display: block;
    }

    .popup .popup-bg {
        background: black;
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 100%;
        z-index: 1;
        opacity: 0.4;
    }

    .popup .popup-content {
        background-color: #182130;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        padding: 36px 32px 35px 32px;
        color: #FFFFFF;
    }

    .popup .popup-close {
        position: absolute;
        top: 36px;
        right: 32px;
        width: 22px;
        height: 22px;
        background: url('../assets/icons/icon-close.png') no-repeat center center;
        cursor: pointer;
    }

    .popup .popup-button-panel {
        text-align: right;
    }

    .popup.delete-connect-detail .popup-content {
        min-width: 354px;
    }

    .popup.delete-connect-detail .popup-delete-text {
        margin-bottom: 37px;
    }

    .popup.delete-connect-detail .cancel-delete-button {
        margin-right: 18px;
    }

    /* The container must be positioned relative: */
    .connect-brand-image1 img {
        width: 100%;
        height: 100%;
    }

    .show {
        display: block;
    }

    .go-connect-button {
        background: #212A3A;
        padding: 0px 0px;
        border-width: 0px;
        cursor: pointer;
    }

    .go-connect-button-text {
        font-size: 14px;
        /* color: #FFFFFF; */
        color: #fcb900;
    }

    .custom-select {
        position: relative;
        font-family: Arial;
    }

    .custom-select select {
        display: none;
        /*hide original SELECT element: */
    }

    .select-selected {
        background-color: #182130;
        border-radius: 4px;
        border: 1px solid #333B47;
    }

    /* Style the arrow inside the select element: */
    .select-selected:after {
        position: absolute;
        content: "";
        top: 17px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #D1D3D6 transparent transparent transparent;
    }

    /* Point the arrow upwards when the select box is open (active): */
    .select-selected.select-arrow-active:after {
        border-color: transparent transparent #fff transparent;
        top: 7px;
    }

    /* style the items (options), including the selected item: */
    .select-items div,
    .select-selected {
        color: #D1D3D6;
        /*color: #ffffff;*/
        padding: 8px 16px;
        /*border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;*/
        cursor: pointer;
    }

    /* Style items (options): */
    .select-items {
        position: absolute;
        /*background-color: DodgerBlue;*/
        background-color: #1B2536;
        border: 1px solid #2C3443;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        margin-top: 7px;
    }

    /* Hide the items when the select box is closed: */
    .select-hide {
        display: none;
    }

    .select-items div:hover,
    .same-as-selected {
        background-color: rgba(0, 0, 0, 0.1);
    }

    ::v-deep .popup-field1 .el-input__inner {
        -webkit-appearance: none;
        background-color: #333B47;
        background-image: none;

        width: 425px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #333B47;

        box-sizing: border-box;
        color: #FFFFFF;
        display: inline-block;
        font-size: inherit;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    ::v-deep .col-1 .el-input__inner {
        -webkit-appearance: none;
        background-color: #333B47;
        background-image: none;

        width: 154px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #333B47;

        box-sizing: border-box;
        color: #FFFFFF;
        display: inline-block;
        font-size: inherit;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    ::v-deep .col-3 .el-button--primary {
        color: #FFF;
        background-color: #212A3A;
        /* margin-left: 3px; */
        border: none;

        width: 67px;
    }

    ::v-deep .col-3 .el-button--primary {
        display: flex;
        justify-content: center;
        color: #FFF;
        background-color: #212A3A;
        margin-left: 4px;
    }

    ::v-deep .el-button--primary {
        width: 80px;
        height: 30px;
        background: #FCB900;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .confirm_delete_box {
        display: inline-block;
    }

    /* .display_inline_block {
        display: inline-block;
    } */

    .connect-brand-not-ready {
        width: 651px;
        height: 30px;
        background: rgba(256, 256, 256, 0.1);
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        /*font-family: PingFangSC-Regular, PingFang SC;*/
        font-weight: 400;
        color: white;
        line-height: 30px;
        margin-top: 8px;

    }
</style>