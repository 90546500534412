<template>
	<div class="popup-panel">
		<div class="popup cochl-setting popup-cochl-setting">
			<div class="popup-content">
				<div class="popup-field1">
					<div class="label-block"><label>Name:</label></div>
					<input class="cpt-input1" v-model="name" type="text" name="websocket-name" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ShadowWebsocket',
		data() {
			return {
				name: null,
			}
		},
		watch: {
			name: function () {
				this.$emit('inputByValue', 'websocket', this.name)
			},
		},
	}
</script>

<style scoped>
	/* Popup */
	.popup.cochl-setting .popup-field1 {
		margin-bottom: 15px;
	}

	.popup.cochl-setting .popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup.cochl-setting .popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup.cochl-setting .popup-field2 {
		margin-bottom: 15px;
	}

	.popup.cochl-setting .popup-field2 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup.cochl-setting .popup-field2 .label-block {
		margin-bottom: 12px;
	}


	/* Popup */
	::v-deep .popup-field1 .cpt-input1 {
		-webkit-appearance: none;
		background-color: #333B47;
		background-image: none;
		width: 100%;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333B47;
		box-sizing: border-box;
		color: #FFFFFF;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}
  ::v-deep .popup-field2 .el-input__inner {
    background-color: #333B47;
    background-image: none;
    border: 1px solid #333B47;
    color: #FFFFFF;
  }

</style>