<template>
  <transition name="fade">
    <div class="slider-dialog-root">
      <div class="brightness">
        <span>Brightness</span>
        <el-slider v-model="brightness" vertical height="224px" tooltip-class="custom-tooltip"
          :format-tooltip="formatBrightnessTooltip" @change="brightnessChange">
        </el-slider>
      </div>

      <div class="intensity">
        <span>Intensity</span>
        <el-slider v-model="intensity" vertical height="224px" :min="2700" :max="6500" tooltip-class="custom-tooltip"
          :format-tooltip="formatIntensityTooltip" @change="intensityChange">
        </el-slider>
      </div>

      <img src="../../assets/Map-Tab/close.svg" alt="" @click="parentRefresh(true)" />
    </div>
  </transition>
</template>

<script>
import { controlDevice } from "../../api.js";

export default {
  props: {
    // 从父组件接收的亮度
    receivedBrightness: {
      type: Number,
      required: true,
    },
    // 从父组件接收的湿度
    receivedIntensity: {
      type: Number,
      required: true,
    },
    // 组件自身是否重新渲染
    shouldRerender: {
      type: Boolean,
      required: true,
    },
    assetId: Number,
  },
  watch:{
    shouldRerender(){
        this.brightness = this.receivedBrightness;
        this.intensity = this.receivedIntensity;
    },
  },
  data() {
    return {
      brightness: this.receivedBrightness, // 亮度
      intensity: this.receivedIntensity, // 湿度 (2700K ~ 6500K)
    };
  },
  methods: {
    formatBrightnessTooltip(value) {
      // 0 -> 0%  100 -> 100%
      return value + "%";
    },
    formatIntensityTooltip(value) {
      // 2700 -> 2700K  6500 -> 6500K
      return value + "K";
    },
    parentRefresh(close) {
      // https://vuejs.org/guide/components/events.html#event-arguments
      // 返回已选择的亮度和湿度
      this.$emit("notify", {
        close: close,
        brightness: this.brightness,
        intensity: this.intensity,
      });
    },
    brightnessChange(value) {
      // controlDevice(this.assetId, {'value': Number((value / 100 * 254).toFixed(0))})
      controlDevice(this.assetId, {'value': value})
      .then((result) => {
        if (result.message === 'success') {
          this.brightness = value;
          this.parentRefresh(false)
          this.$message.success({center: true, message: 'Success'});
        } else {
          this.brightness = this.receivedBrightness;
          this.$message.error({center: true, message: "Failure"});
        }
      }).catch(() => {
        this.brightness = this.receivedBrightness;
        this.$message.error({center: true, message: "Error"});
      }).finally(() => {
        console.log(this.brightness)
      })
    },
    intensityChange(value) {
      this.intensity = value;
    },
  },
};
</script>

<style scoped>
/* 添加过渡动画效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slider-dialog-root {
  width: 321px;
  height: 346px;
  box-sizing: border-box;
  background: #171d20;
  border-radius: 0px 0px 2px 2px;
  padding: 24px 20px 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 9px;
}

img {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.slider-dialog-root span {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 28px;
}

.brightness {
  margin-right: 28px;
}

.brightness .el-slider>>>.el-slider__runway {
  width: 12px;
  border-radius: 12px;
  background: linear-gradient(180deg, #f7f7f7 0%, #262626 100%);
}

.brightness .el-slider>>>.el-slider__bar {
  background-color: initial;
}

.intensity .el-slider>>>.el-slider__runway {
  width: 12px;
  border-radius: 12px;
  background: linear-gradient(180deg, #0087ff 0%, #ffffff 50%, #ff8a00 100%);
}

.intensity .el-slider>>>.el-slider__bar {
  background-color: initial;
}

.el-slider>>>.el-slider__button-wrapper {
  left: calc(-36px / 2 + 12px / 2);
  /* -12px */
}

.el-slider>>>.el-slider__button {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.22);
  border: none;
  position: relative;
}

.el-slider>>>.el-slider__button::before {
  position: absolute;
  content: " ";
  top: calc(50% - 14px / 2);
  left: calc(50% - 14px / 2);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #dddddd;
}

.brightness,
.intensity {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<style>
div.el-tooltip__popper.custom-tooltip {
  background-color: #ffffff;
}

div.el-tooltip__popper.custom-tooltip span {
  font-size: 15px;
  font-weight: 500;
  color: #404040;
}

.el-tooltip__popper {
  padding: 7px 15px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

/* https://segmentfault.com/a/1190000021296111 */
/* tooltip 三角形边框颜色 */
div.el-tooltip__popper.custom-tooltip .popper__arrow {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}

/* tooltip 三角形内部背景颜色 */
div.el-tooltip__popper.custom-tooltip .popper__arrow::after {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
</style>
