<template>
  <ConnectInstancesDialog
    :show="true"
    :btnLoadding="confirmButtonLoadingStatus"
    :showEmailNotify="false"
    :showIntervalSecOptions="false"
    @clickClose="clickClose"
    @clickConfirm="clickConfirm"
  >
    <template v-slot:content>
      <div class="select_devices">
        <div class="label-block"><label>Devices:</label></div>

        <div v-if="integrationDeviceList.length === 0">No devices</div>

        <div v-if="integrationDeviceList.length > 0">
          <div class="devices_roll">
            <el-checkbox-group v-model="currentSelectedDeviceKeys" @change="handleCheckedDevicesChange">
              <div v-for="device in integrationDeviceList" :key="device.deviceKey">
                <el-checkbox :label="device.deviceKey">
                  {{device.name}}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
          <div style="margin: 25px 0;"></div>
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" >Select all</el-checkbox>
        </div>
      </div>
    </template>
  </ConnectInstancesDialog>
</template>

<script>
import {
  confirmConnectInstance,
} from "../api";

import ConnectInstancesDialog from "./template/ConnectInstancesDialog.vue";
export default {
  name: "SelectDevices",
  props: {
    connect: Object,
    integrationDeviceList: Array,
    selectedDeviceKeys: Array,
    connectInstanceVo: Object,
  },
  components: {
    ConnectInstancesDialog,
  },
  data() {
    return {
      confirmButtonLoadingStatus: false,

      currentSelectedDeviceKeys: JSON.parse(JSON.stringify(this.selectedDeviceKeys)),
      checkAll: false,
      isIndeterminate: true,
    };
  },
  computed: {},
  methods: {
    handleCheckAllChange(val) {
        this.currentSelectedDeviceKeys = val ? this.integrationDeviceList.map(device => device.deviceKey) : [];
        this.isIndeterminate = false;
      },
    handleCheckedDevicesChange(value) {
      let checkedCount = value.length;
      let sum = this.integrationDeviceList.length;
      this.checkAll = checkedCount === sum;
      this.isIndeterminate = checkedCount > 0 && checkedCount < sum;
    },

    clickClose() {
      this.$emit("clickClose");
    },

    // Click Confirm Btn
    clickConfirm() {
      if(this.integrationDeviceList.length === 0 || this.integrationDeviceList.length === undefined) {
        this.$message.info({
          center: true,
          message: "Now instance has no device!",
        });
        return;
      }

      let selectedDevices = this.integrationDeviceList.filter((device) =>
        this.currentSelectedDeviceKeys.includes(device.deviceKey)
      );
      if (selectedDevices.length > 0) {
        this.confirmButtonLoadingStatus = true;

        confirmConnectInstance(this.connectInstanceVo, selectedDevices)
          .then(() => {
            this.$emit("selectDevicesComfirm");
          })
          .catch((error) => {
            console.log(error)
            this.$message.error({
              center: true,
              message: "Network Error!",
            });
          })
          .finally(() => (this.confirmButtonLoadingStatus = false));
      } else {
        this.$message.error({
          center: true,
          message: "Please select at least one device!",
        });
      }
    },
  },

  // mounted: function() {
  //   this.currentSelectedDeviceKeys = JSON.parse(JSON.stringify(this.selectedDeviceKeys))
  //   console.log("this.selectedDeviceKeys: %s", this.selectedDeviceKeys);
  //   console.log("this.currentSelectedDeviceKeys: %s", this.currentSelectedDeviceKeys);
  // },

  // updated: function() {
  //   console.log(this.selectedDevice);
  // },

  // destroyed: function() {
  //   console.log(this.selectedDeviceKeys);
  // },
};
</script>

<style>
.select_devices .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #FCB900;
    border-color: #FCB900;
}
</style>

<style scoped>
.devices_roll {
  display: flex;
  flex-wrap: wrap;
  height: 300px;
  overflow-x: auto;
}
.devices_roll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color:#F5F5F5;
}

.devices_roll::-webkit-scrollbar-thumb {
  background-color:#555;
}

::v-deep .el-checkbox__label {
    display: inline-block;
    padding-left: 10px;
    line-height: 30px;
    font-size: 15px;
    color: #fff;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #FCB900;
}
</style>
