<template>
    <div class="component-dashboard">
        <!-- <MapConfigure></MapConfigure> -->
        
        <div class="container-first-box">
            <el-row :gutter="14">
                <el-col class="col-1">
                    <!-- <div v-bind:key="idx" v-for="(item, idx) in defaultWidgets"> -->
                    <one-of-container-first-box boxName='connected'>
                        <!-- <template #box-title>{{defaultWidgets[0] && defaultWidgets[0].widgetEntity.meta.title}}</template> -->
                        <template #box-title>{{'Connected'}}</template>
                        <template
                            #top-blue-line>{{defaultWidgets[0] && defaultWidgets[0].data && defaultWidgets[0].data.connected}}</template>
                        <!-- <template #top-blue-line>{{'200'}}</template> -->
                        <template
                            #top-blue-line-text>{{defaultWidgets[0] && defaultWidgets[0].data && defaultWidgets[0].data.unit}}</template>
                        <!-- <template #top-blue-line-text>{{'data point'}}</template> -->
                    </one-of-container-first-box>
                </el-col>
                <el-col class="col-2">
                    <one-of-container-first-box boxName='event-triggered'>
                        <template #box-title>{{'Event Triggered'}}</template>
                        <!-- 右上角下拉框 -->
                        <template #box-util>
                            <el-dropdown trigger="click" placement="bottom" @command="clickSelectEventTriggered">
                                <div class="triggered-dropdown-menu-box">
                                    {{eventTriggeredSelected}}<i class="el-icon-caret-bottom" />
                                </div>

                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command='Daily'>
                                        <div class="dropdown_body_text_box">
                                            <span
                                                :class="eventTriggeredSelected === 'Daily' ? 'dropdown_body_text_selected' :'dropdown_body_text_unselected'">
                                                Daily
                                            </span>
                                        </div>
                                        <div class="dropdown_body_selected_img_box"
                                            v-show="eventTriggeredSelected === 'Daily'">
                                            <img class="dropdown_body_selected_img"
                                                src="../assets/icons/dropdown_selected-body@1.png" />
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item command='Weekly'>
                                        <div class="dropdown_body_text_box">
                                            <span
                                                :class="eventTriggeredSelected === 'Weekly' ? 'dropdown_body_text_selected' :'dropdown_body_text_unselected'">
                                                Weekly
                                            </span>
                                        </div>
                                        <div class="dropdown_body_selected_img_box"
                                            v-show="eventTriggeredSelected === 'Weekly'">
                                            <img class="dropdown_body_selected_img"
                                                src="../assets/icons/dropdown_selected-body@1.png" />
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item command='Monthly'>
                                        <div class="dropdown_body_text_box">
                                            <span
                                                :class="eventTriggeredSelected === 'Monthly' ? 'dropdown_body_text_selected' :'dropdown_body_text_unselected'">
                                                Monthly
                                            </span>
                                        </div>
                                        <div class="dropdown_body_selected_img_box"
                                            v-show="eventTriggeredSelected === 'Monthly'">
                                            <img class="dropdown_body_selected_img"
                                                src="../assets/icons/dropdown_selected-body@1.png" />
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item command='Annually'>
                                        <div class="dropdown_body_text_box">
                                            <span
                                                :class="eventTriggeredSelected === 'Annually' ? 'dropdown_body_text_selected' :'dropdown_body_text_unselected'">
                                                Annually
                                            </span>
                                        </div>
                                        <div class="dropdown_body_selected_img_box"
                                            v-show="eventTriggeredSelected === 'Annually'">
                                            <img class="dropdown_body_selected_img"
                                                src="../assets/icons/dropdown_selected-body@1.png" />
                                        </div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                        <!-- 结束下拉框 -->
                        <template #top-blue-line>{{eventTriggeredNumber}}</template>
                        <!-- <template #top-blue-line>{{'166666'}}</template> -->
                    </one-of-container-first-box>
                </el-col>
                <el-col class="col-3">
                    <one-of-container-first-box boxName='workflow-execution-failure'>
                        <template #box-title>{{'Workflow Failure'}}</template>
                        <template
                            #top-blue-line>{{defaultWidgets[2] && defaultWidgets[2].data && defaultWidgets[2].data.workflowExecutionFailure}}</template>
                        <!-- <template #top-blue-line>{{'888888'}}</template> -->
                        <template
                            #top-blue-line-blue_unit>{{defaultWidgets[2] && defaultWidgets[2].data && defaultWidgets[2].data.unit}}</template>
                        <!-- <template #top-blue-line-blue_unit>{{'Hrs'}}</template> -->
                        <!-- <template #top-blue-line-icon><img src="../assets/icons/dashboard-select.png"></template> -->
                    </one-of-container-first-box>
                </el-col>
                <el-col class="col-4">
                    <one-of-container-first-box boxName='event-trend' componentWidth='100%'>
                        <template #box-title>{{'Event Trend'}}</template>
                        <template #line-chart>
                            <div class="event-trend-chart-box">
                                <!-- 使用图形的方式一： 直接用封装好的 -->
                                <v-chart class="event-trend-chart"
                                    :option="defaultWidgets[3] && defaultWidgets[3].chartOptions" />
                                <!-- <v-chart class="event-trend-chart" :option="option" /> -->
                            </div>
                            <!-- 使用图形的方式二： 自己定义盒子大小，echarts.init()即可， 详情看官网。 -->
                            <!-- <div id="my_line_chart" style="z-index: 20; width: 500px; height: 500px"></div> -->
                            <!-- <div id="my_line_chart"  style="z-index: 20; width: 287px; height: 120px"></div> -->
                        </template>
                    </one-of-container-first-box>
                </el-col>
            </el-row>
        </div>




        <div class="evant_detail-search_box-box">
            <div class="event-detail">Event Detail</div>
            <div class="search-box">
                <input class="search-input-form" type="text" @input="currentChange(1)" v-model="search"
                    name="search-text" placeholder="Search">
            </div>
        </div>
        <div class="container-last-box">

            <div class="table-first-part-box">
                <div class="first_dropdown_box">
                    <div class="first-table-dropdown-menu">
                        <el-dropdown trigger="click" placement="bottom" @command="firstSelectedMeunu">
                            <div class="table-dropdown-menu-box">
                                <div>
                                    <span class="el-dropdown-link" v-if="firstSelected === 'last60Minute'">Last 60
                                        Minute</span>
                                    <span class="el-dropdown-link" v-if="firstSelected === 'last2Hour'">Last 2
                                        Hour</span>
                                    <span class="el-dropdown-link" v-if="firstSelected === 'last24Hour'">Last 24
                                        Hour</span>
                                    <span class="el-dropdown-link all_style" v-if="firstSelected === 'all'">All</span>
                                    <img class="dropdown-img" v-show="firstSelected !== 'all'"
                                        src="../assets/icons/dashboard-table-top-selected.png">
                                    <img class="dropdown-img" v-show="firstSelected === 'all'"
                                        src="../assets/icons/dashboard-table-top-select.png">
                                </div>
                            </div>
                            <!-- <span id="el-dropdown-link" v-if="firstSelected === 'last60Minute'">Last 60 Minute</span>
                            <span id="el-dropdown-link" v-if="firstSelected === 'last2Hour'">Last 2 Hour</span>
                            <span id="el-dropdown-link" v-if="firstSelected === 'last24Hour'">Last 24 Hour</span>
                            <span id="el-dropdown-link" v-if="firstSelected === 'all'">All</span>
                            <img id="sensor-img" src="../assets/dashboard-table-top-selected.png"> -->

                            <el-dropdown-menu class="custom_class_dropdown_menu" slot="dropdown">
                                <el-dropdown-item :command="beforeHandleFirstSelected('last60Minute', 3600000)">
                                    <div class="dropdown_body_text_box">
                                        <span
                                            :class="{dropdown_body_text_unselected: firstSelected !== 'last60Minute', dropdown_body_text_selected: firstSelected === 'last60Minute'}">
                                            Last 60 Minute
                                        </span>
                                    </div>
                                    <div class="dropdown_body_selected_img_box"
                                        v-show="firstSelected === 'last60Minute'">
                                        <img class="dropdown_body_selected_img"
                                            src="../assets/icons/dropdown_selected-body@1.png" />
                                    </div>
                                </el-dropdown-item>

                                <el-dropdown-item :command="beforeHandleFirstSelected('last2Hour', 7200000)">
                                    <div class="dropdown_body_text_box">
                                        <span
                                            :class="{dropdown_body_text_unselected: firstSelected !== 'last2Hour', dropdown_body_text_selected: firstSelected === 'last2Hour'}">
                                            Last 2 Hour
                                        </span>
                                    </div>
                                    <div class="dropdown_body_selected_img_box" v-show="firstSelected === 'last2Hour'">
                                        <img class="dropdown_body_selected_img"
                                            src="../assets/icons/dropdown_selected-body@1.png" />
                                    </div>
                                </el-dropdown-item>

                                <el-dropdown-item :command="beforeHandleFirstSelected('last24Hour', 86400000)">
                                    <div class="dropdown_body_text_box">
                                        <span
                                            :class="{dropdown_body_text_unselected: firstSelected !== 'last24Hour', dropdown_body_text_selected: firstSelected === 'last24Hour'}">
                                            Last 24 Hour
                                        </span>
                                    </div>
                                    <div class="dropdown_body_selected_img_box" v-show="firstSelected === 'last24Hour'">
                                        <img class="dropdown_body_selected_img"
                                            src="../assets/icons/dropdown_selected-body@1.png" />
                                    </div>
                                </el-dropdown-item>

                                <el-dropdown-item :command="beforeHandleFirstSelected('all', 'all')">
                                    <div class="dropdown_body_text_box">
                                        <span
                                            :class="{dropdown_body_text_unselected: firstSelected !== 'all', dropdown_body_text_selected: firstSelected === 'all'}">
                                            All
                                        </span>
                                    </div>
                                    <div class="dropdown_body_selected_img_box" v-show="firstSelected === 'all'">
                                        <img class="dropdown_body_selected_img"
                                            src="../assets/icons/dropdown_selected-body@1.png" />
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <!-- <div class="botton-yellow-line"></div> -->
                </div>


                <div class="second_dropdown_box">
                    <div class="second-table-dropdown-menu">
                        <el-dropdown trigger="click" placement="bottom" @command="secondSelectedMeunu">
                            <div class="table-dropdown-menu-box">
                                <div>
                                    <span class="el-dropdown-link all_style" id="all_style"
                                        v-if="secondSelected === 'all'">All</span>
                                    <span class="el-dropdown-link" v-if="secondSelected === 'sensor'">Sensor</span>
                                    <span class="el-dropdown-link" v-if="secondSelected === 'app'">App</span>
                                    <img class="dropdown-img" v-show="secondSelected !== 'all'"
                                        src="../assets/icons/dashboard-table-top-selected.png">
                                    <img class="dropdown-img" v-show="secondSelected === 'all'"
                                        src="../assets/icons/dashboard-table-top-select.png">
                                </div>
                            </div>

                            <!-- <span id="el-dropdown-link" v-if="secondSelected === 'all'">All</span>
                            <span id="el-dropdown-link" v-if="secondSelected === 'sensor'">Sensor</span>
                            <span id="el-dropdown-link" v-if="secondSelected === 'app'">App</span>
                            <img id="sensor-img" src="../assets/dashboard-table-top-selected.png"> -->

                            <el-dropdown-menu class="custom_class_dropdown_menu" slot="dropdown">
                                <el-dropdown-item command="all">
                                    <div class="dropdown_body_text_box">
                                        <span
                                            :class="{dropdown_body_text_unselected: secondSelected !== 'all', dropdown_body_text_selected: secondSelected === 'all'}">
                                            All
                                        </span>
                                    </div>
                                    <div class="dropdown_body_selected_img_box" v-show="secondSelected === 'all'">
                                        <img class="dropdown_body_selected_img"
                                            src="../assets/icons/dropdown_selected-body@1.png" />
                                    </div>
                                </el-dropdown-item>

                                <el-dropdown-item command="sensor">
                                    <div class="dropdown_body_text_box">
                                        <span
                                            :class="{dropdown_body_text_unselected: secondSelected !== 'sensor', dropdown_body_text_selected: secondSelected === 'sensor'}">
                                            Sensor
                                        </span>
                                    </div>
                                    <div class="dropdown_body_selected_img_box" v-show="secondSelected === 'sensor'">
                                        <img class="dropdown_body_selected_img"
                                            src="../assets/icons/dropdown_selected-body@1.png" />
                                    </div>
                                </el-dropdown-item>

                                <el-dropdown-item command="app">
                                    <div class="dropdown_body_text_box">
                                        <span
                                            :class="{dropdown_body_text_unselected: secondSelected !== 'app', dropdown_body_text_selected: secondSelected === 'app'}">
                                            App
                                        </span>
                                    </div>
                                    <div class="dropdown_body_selected_img_box" v-show="secondSelected === 'app'">
                                        <img class="dropdown_body_selected_img"
                                            src="../assets/icons/dropdown_selected-body@1.png" />
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <!-- <div class="botton-yellow-line"></div> -->
                </div>


                <!-- <div id="sensor">Sensor</div>
				<img id="sensor-img" src="../assets/dashboard-table-top-select.png"> -->

            </div>

            <div class="table-second-part-box">
                <div class="table_header-and-table_body">
                    <el-table class="event-table" :data="events.list" :empty-text="'No Data'" :style="{'width': '100%'}"
                        :header-cell-style="{'background':'#182130', 'height': '53px', 'border-bottom': '1px solid #252F40'}"
                        :cell-style="{'background':'#182130', 'height': '44px', 'max-height': '44px', 'border-bottom': 'none'}"
                        @sort-change="changeTableSort">
                        <!-- :default-sort = "{prop: 'time', order: 'descending'}" -->
                        <el-table-column show-overflow-tooltip class-name="dashboard_table_id" min-width="50" prop="execution_id"
                            label="Execution ID" sortable='custom'>
                            <template slot-scope="{row}">
                                <span>{{ row.executionId }}</span>  
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip class-name="dashboard_table_type" prop="type"
                            label="Type" sortable='custom'>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip prop="name" label="Name" sortable='custom'>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="Time" sortable='custom'>
                            <template slot-scope="{row}">
                                <span>{{ row.timestamp | parseTime() }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip prop="event" label="Event" sortable='custom'>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="pagination-wrapper" v-if="events.totalCount > 0">
                    <el-pagination class="dashboard-table-pagination-box" background-color="black" background
                        layout="prev, pager, next" @current-change="currentChange" @prev-click="prevClick"
                        @next-click="nextClick" :page-count="pagerCount" :current-page.sync="currentPage"
                        :total="events.totalCount" :page-size="pagesize">
                    </el-pagination>
                </div>

            </div>
        </div>

        <!-- <div id="my_line_chart" style="z-index: 20; width: 500px; height: 500px"></div> -->
    </div>
</template>

<script>
    import oneOfContainerFirstBox from '../components/HomeOneOfContainerFirsttBox.vue';
    import {
        getUserWidgets,
        getEvents,
        webSocketClient,
        triggerEventAnalysis
    } from '../api'

    import {
        use
    } from "echarts/core";
    import {
        GridComponent
    } from 'echarts/components';
    import {
        LineChart
    } from 'echarts/charts';
    import {
        CanvasRenderer
    } from 'echarts/renderers';
    import VChart from "vue-echarts";
    import {
        parseTime
    } from "@/utils/timeUtil";


    use([GridComponent, LineChart, CanvasRenderer]);

    export default {
        filters: {
            parseTime,
        },
        components: {
            oneOfContainerFirstBox,
            VChart,
        },

        name: 'Dashboard',
        props: {
            msg: String,
        },

        data() {
            return {
                pageWidth: Number,

                // thisComponent: true,
                // timer: null,

                stompClient: null,

                stopped: false,

                userWidgets: [],

                events: {},

                currentPage: 1,
                pagesize: 10,
                pagerCount: 6,

                // paginationLength: this.tableData.length,

                type: '',
                // sinceTime: Date.now() - 3600000,
                // sinceTime: '0',
                sortParam: null,
                // tableData: [],

                // descendingByTimeTableData: [],

                lineChartData: [],

                option: {
                    // grid: 控制图形的宽高、与最近父级盒子的边界的距离
                    grid: {
                        // 垂直方向：height top bottom给出其中两个值，另外一个会自动算出来； 水平方向同理 width left right
                        // height: 80,
                        top: 15, // 数字不给出单位，单位为px; 还可以用 *%表示
                        bottom: 25
                    },
                    legend: {
                        textStyle: {
                            color: '#B7BABE'
                        }
                    },
                    // height: '100%',
                    xAxis: {
                        type: 'category',
                        data: [],
                        // data: [1,2,3,4,5,6,7,8,9,10,11,12], // 模拟数据作用, 记得将上面的打开
                        axisLabel: {
                            interval: 0,
                            rotate: 30
                        },
                        grid: {
                            bottom: '30%',
                        },
                        // 起到点与x轴对齐
                        boundaryGap: false
                    },
                    // height: '150px',
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            margin: 0,
                            textStyle: {
                                color: '#B7BABE', //更改坐标轴文字颜色
                                // fontSize : 14      //更改坐标轴文字大小
                            }
                        },
                        // y轴得分割段数，这只是个预估值，不是echart.js会根据可读性来做适当得调整
                        splitNumber: 2,
                    },
                    series: [{
                        data: [],
                        // data: [100,300,800,260,600,530,530,450,320,220,200,50], // 模拟数据作用, 记得将上面的打开
                        type: 'line'
                    }]
                },

                // firstSelected: 'last60Minute',
                firstSelected: 'all',
                secondSelected: 'all',
                eventTriggeredSelected: 'Daily',
                search: null

            }
        },

        computed: {
            sinceTime: function () {
                if (this.firstSelected === 'last60Minute') {
                    return new Date().getTime() - 3600000
                } else if (this.firstSelected === 'last2Hour') {
                    return new Date().getTime() - 7200000
                } else if (this.firstSelected === 'last24Hour') {
                    return new Date().getTime() - 86400000
                } else {
                    return 0
                }
            },
            defaultWidgets: function () {
                let widgets = []
                var idx = this.userWidgets.findIndex(w => w.widgetType === 'connected')
                // console.log(idx)
                widgets[0] = idx >= 0 ? this.userWidgets[idx] : null;
                idx = this.userWidgets.findIndex(w => w.widgetType === 'eventTriggered')
                // console.log(idx)
                widgets[1] = idx >= 0 ? this.userWidgets[idx] : null;
                idx = this.userWidgets.findIndex(w => w.widgetType === 'workflowExecutionFailure')
                // console.log(idx)
                widgets[2] = idx >= 0 ? this.userWidgets[idx] : null;
                idx = this.userWidgets.findIndex(w => w.widgetType === 'eventTrend')
                // console.log(idx)
                widgets[3] = idx >= 0 ? this.userWidgets[idx] : null;
                // Connected
                if (widgets[0] == null) {
                    widgets[0] = {
                        'data': {
                            'connected': 0,
                            'unit': 'data point'
                        }
                    }
                } else if (widgets[0].data == null) {
                    widgets[0].data = {
                        'connected': 0,
                        'unit': 'data point'
                    }
                }
                // Event Triggered
                if (widgets[1] == null) {
                    widgets[1] = {
                        'data': {
                            "eventCountAnnually": 0,
                            "eventCountDaily": 0,
                            "eventCountWeekly": 0,
                            "eventCountMonthly": 0
                        }
                    }
                } else if (widgets[1].data == null) {
                    widgets[1].data = {
                        'eventTriggered': 0
                    }
                }
                // Hours-Saved
                if (widgets[2] == null) {
                    widgets[2] = {
                        'data': {
                            'workflowExecutionFailure': 0,
                            'unit': 'times'
                        }
                    }
                } else if (widgets[2].data == null) {
                    widgets[2].data = {
                        'workflowExecutionFailure': 0,
                        'unit': 'times'
                    }
                }

                if (widgets[3] !== null) {
                    let trendWidget = widgets[3]
                    let chartOptions = Object.assign({}, this.option)

                    if (trendWidget.data && trendWidget.data.eventTrend && trendWidget.data.eventTrend.data &&
                        trendWidget.data.eventTrend.time) {
                        chartOptions.series[0].data = trendWidget.data.eventTrend.data
                        chartOptions.xAxis.data = trendWidget.data.eventTrend.time
                    }
                    widgets[3].chartOptions = chartOptions
                }
                // console.log(widgets)
                return widgets;
            },
            // tableDataFilter: function () {
            // let events = this.events.concat().map(e => {
            //     e.time = this.formatTime(e.timestamp);
            //     return e;
            // });

            // if (this.search) {

            //     events = events.filter((e) => {
            //         return String(e['name']).toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
            //             String(e['type']).toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
            //             String(e['event']).toLowerCase().indexOf(this.search.toLowerCase()) !== -1
            //     });

            //     // console.log(filtered.length)

            // }

            // if (this.sortParam && this.sortParam.order) {
            //     events = events.sort(this.stringAndNumberCompare(this.sortParam.prop, this.sortParam.order));
            // }
            // return this.events;

            //    let filtered = events.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize);

            //    console.log(filtered.length);

            //    return filtered;
            // },
            // currentTableItems: function () {
            // let filtered = this.tableDataFilter.slice((this.currentPage - 1) * this.pagesize, this.currentPage *
            //     this.pagesize);
            // return this.tableDataFilter.list;
            // }
            eventTriggeredNumber: function () {
                if (this.defaultWidgets[1] && this.defaultWidgets[1].data) {
                    switch (this.eventTriggeredSelected) {
                        case 'Daily':
                            return this.defaultWidgets[1].data.eventCountDaily
                        case 'Weekly':
                            return this.defaultWidgets[1].data.eventCountWeekly
                        case 'Monthly':
                            return this.defaultWidgets[1].data.eventCountMonthly
                        case 'Annually':
                            return this.defaultWidgets[1].data.eventCountAnnually
                        default:
                            return 0;
                    }
                }
                return 0
            },
        },
        methods: {
            // setTimer() {
            //     clearTimeout(this.timer)
            //     this.timer = setTimeout(() => {
            //         this.getUserWidgetList().finally(() => {
            //             if(this.thisComponent){
            //                 this.setTimer()  //使用finally递归的好处： 不会有卡死的情况
            //             }
            //         })
            //     }, 5000)
            // },

            // ************************************* paging *************************
            currentChange(val) {
                this.currentPage = val
                this.loadEvents()
            },
            prevClick() {
                if (this.currentPage !== 1) {
                    this.currentPage = this.currentPage - 1
                    // console.log(this.currentPage)
                }
                this.loadEvents()
            },
            nextClick() {
                if (this.currentPage !== this.tableData.length) {
                    this.currentPage = this.currentPage + 1
                    // console.log(this.currentPage)
                }
                this.loadEvents()
            },
            // ************************************* paging *************************

            // ************************************* format event time *************************
            // formatTime(preTime) {
            //     // formatTime(){
            //     // console.log(rowData)
            //     var t = new Date(preTime)
            //     // var t = new Date(1477386005*1000)
            //     // var a= d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();              /*d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1*/
            //     var time = t.getFullYear() + '-' + (t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() +
            //         1) + '-' + (t.getDate() < 10 ? "0" + t.getDate() : t.getDate()) + ' ' + (t.getHours() < 10 ?
            //         "0" + t
            //         .getHours() : t.getHours()) + ':' + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t
            //         .getMinutes()) + ':' + (t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds());
            //     // console.log(time)
            //     return time
            // },
            // ************************************* format event time *************************

            beforeHandleFirstSelected(timeInterval, specificSeconds) {
                return {
                    timeInterval,
                    specificSeconds
                }
            },
            loadEvents() {
                // let firstSelectedItem = window.localStorage.getItem('event_detail_selected_meunu')
                // this.firstSelected = firstSelectedItem ? firstSelectedItem : this.firstSelected

                // if (this.firstSelected === 'last60Minute') {
                //     this.sinceTime = new Date().getTime() - 3600000
                // } else if (this.firstSelected === 'last2Hour') {
                //     this.sinceTime = new Date().getTime() - 7200000
                // } else if (this.firstSelected === 'last24Hour') {
                //     this.sinceTime = new Date().getTime() - 86400000
                // } else {
                //     this.sinceTime = '0'
                // }

                // 基本参数
                let params = {
                    type: this.type === '' ? null : this.type,
                    sinceTime: this.sinceTime,
                    offset: this.currentPage,
                    limit: this.pagesize,
                    search: this.search ? encodeURI(this.search) : null
                }
                // 排序
                if (this.sortParam) {
                    params['prop'] = this.sortParam.prop
                    if (this.sortParam.order === 'ascending') {
                        params['order'] = true
                    } else if (this.sortParam.order === 'descending') {
                        params['order'] = false
                    } else {
                        params['prop'] = null
                    }
                }
                getEvents(params).then(events => {
                    this.events = events;
                })
            },
            firstSelectedMeunu(selectedMeunu) {
                this.firstSelected = selectedMeunu.timeInterval
                // if (selectedMeunu.specificSeconds !== 'all') {
                //     // this.sinceTime = Date.now() - selectedMeunu.specificSeconds
                //     // console.log(new Date().getTime() - selectedMeunu.specificSeconds) new Date().getTime() 与 Date.now()一样都可以产生毫秒时间戳
                //     // console.log(Date.now() - selectedMeunu.specificSeconds)
                //     this.sinceTime = new Date().getTime() - selectedMeunu.specificSeconds
                // } else {
                //     this.sinceTime = '0'
                // }

                window.localStorage.setItem('event_detail_selected_meunu', this.firstSelected)
                // console.log(this.sinceTime)
                this.currentChange(1)

            },
            clickSelectEventTriggered(selectedMeunu) {
                this.eventTriggeredSelected = selectedMeunu
                window.localStorage.setItem('event_triggered_selected_meunu', this.eventTriggeredSelected)

            },

            secondSelectedMeunu(selectedMeunu) {
                this.secondSelected = selectedMeunu
                if (selectedMeunu !== 'all') {
                    this.type = selectedMeunu
                } else {
                    this.type = ''
                }
                this.currentChange(1)

            },


            getUserWidgetList() {
                return getUserWidgets().then(userWidgets => {
                    this.userWidgets = userWidgets
                }).catch(this.defaultErrorHandling)
            },

            timeStampCompare(prop) {
                return function (event1, event2) {
                    var value1 = event1[prop];
                    var value2 = event2[prop];

                    let date1 = new Date(value1)
                    let date2 = new Date(value2)
                    return date2.getTime() - date1.getTime();
                }
            },
            stringAndNumberCompare(prop, order) {
                return function (event1, event2) {
                    var value1 = event1[prop];
                    var value2 = event2[prop];

                    if (order === 'ascending') {
                        if (typeof (value1) === 'string') {
                            return value1.localeCompare(value2);
                        }
                        if (typeof (value1) === 'number') {
                            return value1 - value2;
                        }
                    } else {
                        if (typeof (value1) === 'string') {
                            return value2.localeCompare(value1);
                        }
                        if (typeof (value1) === 'number') {
                            return value2 - value1;
                        }
                    }
                }
            },

            changeTableSort(paramObject) {
                console.log(paramObject)
                this.sortParam = paramObject;
                this.currentChange(1)
                // let events = this.events.concat()
                // if(paramObject.order !== null){
                //     events.sort(this.stringAndNumberCompare(paramObject.prop, paramObject.order));
                // }
                // else{
                //     // events = this.events
                //     this.events = this.descendingByTimeTableData.concat()
                // }
                // this.events = events;
            },

            refreshWidgetData() {

                let websocketUrl = '/ws/widget_data';
                // let websocketUrl = 'https://api.cereb.ai/v1/ws/widget_data';
                let websocketDestination = '/user/topic/single_widget_data';
                this.stompClient = webSocketClient(websocketUrl)

                let headers = {
                    "access-token": localStorage.token
                    // 'access-token': 'localStorage.token'
                }

                let that = this;

                this.stompClient.connect(headers, () => {
                    // console.log(!this.stompClient.connected)
                    // let _this = this

                    this.stompClient.subscribe(websocketDestination, function (response) {
                        let websocketDataVo = JSON.parse(response.body);
                        console.log(websocketDataVo)

                        let idx = that.userWidgets.findIndex(w => w.widgetType === websocketDataVo
                            .widgetType)

                        if (idx >= 0) {
                            that.userWidgets[idx].data = websocketDataVo.singleWidgetData
                        }

                    })
                }, () => {
                    if (!that.stopped && !that.stompClient.connected) {
                        setTimeout(that.refreshWidgetData, 1000)
                    }
                });
            },

            doTriggerEventAnalysis() {
                triggerEventAnalysis().finally(() => {
                    this.triggerTimer = setTimeout(this.doTriggerEventAnalysis, 30000);
                })
            },
        },
        mounted: function () {
            let firstSelectedItem = window.localStorage.getItem('event_detail_selected_meunu')
            let eventTriggeredItem = window.localStorage.getItem('event_triggered_selected_meunu')
            if (firstSelectedItem) {
                this.firstSelected = firstSelectedItem
            }
            if (eventTriggeredItem) {
                this.eventTriggeredSelected = eventTriggeredItem
            }
            this.getUserWidgetList();
            this.refreshWidgetData();
            this.loadEvents();
            this.doTriggerEventAnalysis();


            // getEvents(this.type, this.sinceTime).then(events => {
            //     // console.log(events)
            //     this.events = events;
            //     // for(let i=0; i<events.length; i++){
            //     //     events[i].time = this.formatTime(events[i].timestamp)
            //     // }

            //     // this.tableData = events.sort(this.timeStampCompare('time'))

            //     // this.descendingByTimeTableData = this.tableData.concat()
            // })

        },
        created: function () {
            // 每次进入界面时，先清除之前的所有定时器，然后启动新的定时器
            // clearTimeout(this.timer)
            // this.timer = null
            // this.setTimer()
        },
        destroyed: function () {
            // 每次离开当前界面时，清除定时器
            // clearTimeout(this.timer)
            // this.timer = null
            this.stopped = true;
            this.stompClient.disconnect();
            clearTimeout(this.triggerTimer);
            // this.socket.close();

            // this.thisComponent = false
        },
        updated: function () {
            // console.log(this.tableData.length)
            // let table_ID_box = document.getElementsByClassName('cell')
            // console.log(table_ID_box)
            // console.log('!this.stompClient.connected')
            // console.log(!this.stompClient.connected)
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* **********************************************************  container  *************************************************************************** */

    /* Search Box (Home, Assets) */
    .search-box {
        float: right;
        width: 360px;
        height: 48px;
        border: none;
    }

    .search-input-form {
        display: inline-block;
        border: none;
        z-index: 2;
        padding-left: 60px;
        width: 300px;
        height: 48px;
        color: #FFFFFF;
        background: #252F40 url('../assets/dashboard-search-magnifier.png') no-repeat;
        background-position-x: 12px;
        background-position-y: 12px;
    }

    input::-webkit-input-placeholder {
        z-index: 3;
        width: 45px;
        height: 17px;
        font-size: 14px;
        font-family: Helvetica;
        color: #FFFFFF transparent;
        line-height: 17px;
    }

    /* Search Box */

    .page-dashboard .paging-panel-block {
        background: rgb(24, 33, 48);
        overflow: hidden;
        padding: 0px 24px 21px;
    }

    .page-dashboard .paging-panel {
        float: right;
    }

    .page-dashboard .paging-panel .paging-btn {
        float: left;
        width: 32px;
        height: 32px;
        margin-left: 10px;
        background: #2F3A4C;
        border-radius: 6px;
        text-align: center;
        color: #FFFFFF;
        line-height: 32px;
        cursor: pointer;
    }

    .page-dashboard .paging-panel .paging-btn.active,
    .page-dashboard .paging-panel .paging-btn:hover {
        background: #FCB900;
    }

    .page-dashboard .select-items {
        background-color: #252F40;
        border-top: 2px solid #FCB900;
    }

    .page-dashboard .select-items div {
        padding: 14px 18px;
    }

    .page-dashboard .same-as-selected {
        background-color: #252F40;
        color: #FCB900;
    }

    .page-dashboard .select-selected {
        color: #FCB900;
        padding: 0px 18px;
        background-color: transparent;
        border-color: transparent;
    }

    .page-dashboard .select-selected:after {
        top: 10px;
        right: 4px;
        border-color: #FCB900 transparent transparent transparent;
    }

    .page-dashboard .select-selected.select-arrow-active:after {
        top: 3px;
        border-color: transparent transparent #FCB900 transparent;
    }

    .page-dashboard .select-items {
        margin-top: 14px;
    }

    .event-table {
        background: transparent;
    }

    /* .component-dashboard { */
    /*z-index: -1;*/
    /*position: absolute;*/
    /*left: 50%;*/
    /* top: 50%; */
    /*margin-left: -600px;*/
    /* margin-top: -295px; */
    /*margin-top: 25px;*/

    /*width: 1200px;*/
    /*height: 537px;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /* } */
    .component-dashboard {
        min-width: 900px;
    }

    .container-first-box {
        padding-left: 121px;
        padding-right: 121px;

        /* display: flex; */
        /* justify-content: space-between; */
        /* justify-content: space-between; */
        /*z-index: 0;*/

        /*        width: 1200px;
        height: 140px;
*/
    }

    .event-trend {
        display: inline-block;
        z-index: 1;
        width: 355px;
        height: 140px;
        background: #182130;
        border-radius: 2px;
    }

    .event-trend-chart-box {
        position: relative;
        z-index: 20;
        margin-left: 47px;
        margin-top: -5px;
        width: 287px;
        height: 115px;
    }

    /* .my_line_chart {
        position: relative;
        z-index: 10;
        margin-left: 47px;
        margin-top: -5px;
        width: 287px;
        height: 120px;
    } */

    .top-blue-line {
        display: block;
        border: none;
        z-index: 2;
        /* position: absolute; */
        margin-left: 0px;
        margin-top: 105px;
        margin-bottom: 0px;
        width: 355px;
        height: 3px;
        background: #274170;
        border-radius: 6px;
    }


    .evant_detail-search_box-box {
        /*display: inline-block;*/
        /*display: flex;*/
        /*align-items: center;*/
        /*z-index: 0;*/
        /*margin-top: 35px;*/
        /*width: 1200px;*/
        /*margin-left: 121px;*/
        /*margin-right: 121px;*/
        position: relative;
        height: 113px;
    }

    .search-box {
        position: absolute;
        top: 35px;
        right: 121px;
        /*display: inline-block; z-index: 1; margin-left: 723px;*/
        /*float:right; */
        width: 360px;
        height: 48px;
        border: none;
    }

    .search-input-form {
        display: inline-block;
        border: none;
        z-index: 2;
        padding-left: 60px;
        width: 300px;
        height: 48px;
        color: #FFFFFF;
        background: #252F40 url('../assets/dashboard-search-magnifier.png') no-repeat;
        background-position-x: 12px;
        background-position-y: 12px;
    }

    .event-detail {
        left: 134px;
        top: 48px;
        position: absolute;

        /*display: inline-block;*/
        /*z-index: 1;*/
        /*margin-left: 13px;*/
        /*width: 103px;*/
        height: 22px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        /*line-height: 22px;*/
        line-height: 22px;
    }

    .container-last-box {
        /*z-index: 0;*/
        /*margin-top: 30px;*/

        /*width: 1200px;*/
        margin-left: 121px;
        margin-right: 121px;
        padding-bottom: 36px;
        /*height: 500px;*/
        /*background: #182130;*/
        /*border-radius: 2px;*/
    }

    .table-first-part-box {
        display: flex;
        align-items: center;
        /*z-index: 1;*/
        /*width: 1200px;*/
        height: 53px;
        background: #252F40;
        border-radius: 0px 0px 2px 2px;
    }

    .first-table-dropdown-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        width: 150px;
        height: 53px;
    }

    .second_dropdown_box {
        margin-left: 50px;
    }

    .second-table-dropdown-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        width: 150px;
        height: 53px;
    }

    .table-dropdown-menu-box {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .triggered-dropdown-menu-box {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        cursor: pointer;
    }

    .el-dropdown-link {
        z-index: 3;
        width: 93px;
        height: 21px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FCB900;
        line-height: 21px;
    }

    .el-dropdown-link.all_style {
        color: #FFFFFF;
    }

    .dropdown-img {
        margin-left: 4px;
    }


    .el-dropdown-menu {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 0;
        margin-top: 18px;
        width: 150px;
        /* height: 199px; */
        background: #252F40;
        border-radius: 4px;
        border: 1px solid #303B4E;
    }

    .botton-yellow-line {
        /* margin-left: 15px; */
        width: 150px;
        height: 2px;
        background: #FCB900;
    }


    .table-second-part-box {
        position: relative;
        z-index: 1;
        /*width: 1200px;*/
        min-height: 604px;

        background: #182130;
        border-radius: 0px 0px 2px 2px;
    }

    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
        content: '';
        position: absolute;
        background-color: #182130;
        z-index: 1;
    }

    ::v-deep .el-table th>.cell {
        color: #dddddd;
        font-weight: 400;
        width: auto;
    }

    ::v-deep .el-table .cell {
        color: #cfd1d4;
        padding-left: 0px;
        padding-right: 0px;
    }

    ::v-deep .dashboard_table_id .cell {
        /* margin-left: 55.7px; */
        padding-left: 36px;
    }

    ::v-deep .dashboard_table_type .cell {
        /* margin-left: 49px; */
        padding-left: 53px;
    }

    ::v-deep .el-table__empty-block {
        background: #182130;
    }

    .el-popper ::v-deep .popper__arrow {
        border-bottom-color: #1EBEF4 !important;
        left: 50% !important;
        visibility: hidden;
    }

    /* ::v-deep #table_header_ID {
        color: red;
    } */

    .table_header-and-table_body {
        background: #212A3A;

        /*z-index: 1;*/
        /*width: 1200px;*/
        /*height: 495px;*/

        /* background: #182130; */

        /*padding: 0px;*/
    }

    .pagination-wrapper {
        width: 100%;
        height: 32px;
        position: absolute;
        bottom: 18px;
        right: 16px;
    }

    .dashboard-table-pagination-box {
        position: absolute;
        right: 21px;
        /*z-index: 1;*/
        /*margin-left: 660px;*/
        /*margin-top: 55px;*/
        /* margin-top: 586px; */
        /* margin-bottom: 24px; */
        /*width: 452px;*/
        height: 32px;

        background: #182130;

        padding: 0px;
    }

    ::v-deep .el-dropdown-menu__item {
        position: relative;
        padding: 0px;
        padding-left: 20px;
    }

    ::v-deep .el-dropdown-menu__item:hover {
        background: #2f3848;
    }

    /* .dropdown_body_row1_box{
        height: 36px;
    } */

    .dropdown_body_text_box {
        display: inline-block;
        width: 92.1px;
        /* height: 22px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FCB900;
        line-height: 22px; */
    }

    .dropdown_body_text_unselected {
        display: inline-block;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #DDDDDD;
        line-height: 22px;
    }

    .dropdown_body_text_selected {
        display: inline-block;
        width: 92.1px;
        height: 22px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FCB900;
        line-height: 22px;
    }

    .dropdown_body_selected_img_box {
        display: inline-block;
        position: relative;
        margin-left: 9px;
        width: 13px;
        height: 36px;
    }

    .dropdown_body_selected_img {
        position: relative;
        display: inline-block;
    }

    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #FCB900;
        color: #FFFFFF;
    }

    /* ::v-deep .el-pager .number {
        background-color: #2F3A4C !important;
    }
    ::v-deep .el-pager .number.active {
        background-color: #FCB900;
    } */

    ::v-deep .el-pagination.is-background .btn-next,
    ::v-deep .el-pagination.is-background .btn-prev,
    ::v-deep .el-pagination.is-background .el-pager li {
        margin: 0 5px;
        background-color: #2F3A4C;
        color: #FFFFFF;
        min-width: 30px;
        border-radius: 2px;
    }

    ::v-deep .el-pagination.is-background .btn-next.disabled,
    ::v-deep .el-pagination.is-background .btn-next:disabled,
    ::v-deep .el-pagination.is-background .btn-prev.disabled,
    ::v-deep .el-pagination.is-background .btn-prev:disabled,
    ::v-deep .el-pagination.is-background .el-pager li.disabled {
        color: #C0C4CC;
    }

    ::v-deep .el-dropdown-menu__item {
        list-style: none;
        line-height: 36px;
        /* padding: 0 20px; */
        margin: 0;
        font-size: 14px;
        color: #DDDDDD;
        cursor: pointer;
        outline: 0;
    }
</style>


<style scoped>
    .container-first-box .col-1,
    .container-first-box .col-2,
    .container-first-box .col-3 {
        width: 24%;
    }

    .container-first-box .col-4 {
        width: 28%;
    }

    /* 屏幕分辨率小于1500px */
    @media (max-width:1500px) {

        .container-first-box .col-1,
        .container-first-box .col-2,
        .container-first-box .col-3 {
            width: 23%;
        }

        .container-first-box .col-4 {
            width: 31%;
        }
    }

    /* 屏幕分辨率小于1350px */
    @media (max-width:1350px) {

        .container-first-box .col-1,
        .container-first-box .col-2,
        .container-first-box .col-3 {
            width: 22%;
        }

        .container-first-box .col-4 {
            width: 34%;
        }
    }

    /* 屏幕分辨率小于1200px */
    @media (max-width:1200px) {

        .container-first-box .col-1,
        .container-first-box .col-2,
        .container-first-box .col-3 {
            width: 20%;
        }

        .container-first-box .col-4 {
            width: 40%;
        }
    }

    /* 屏幕分辨率小于1050px */
    @media (max-width:1050px) {

        .container-first-box .col-1,
        .container-first-box .col-2,
        .container-first-box .col-3,
        .container-first-box .col-4 {
            width: 50%;
        }
    }
</style>

<style>
    .custom_class_dropdown_menu.el-popper .popper__arrow,
    .custom_class_dropdown_menu.el-popper .popper__arrow::after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: none;
    }
</style>