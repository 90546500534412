<template>
  <div class="temperature-content">
    <div class="title">Temperature</div>
    <div class="value">
      {{
          currentTemperatureType === "Celsius"
            ? temperature + "℃"
            : temperature + "℉"
      }}
    </div>
    <div class="bottom-right">
      <span @click="changeTemperatureType('Celsius')" :class="{ active: currentTemperatureType === 'Celsius' }">℃</span>
      <span @click="changeTemperatureType('Fahrenheit')"
        :class="{ active: currentTemperatureType === 'Fahrenheit' }">℉</span>
    </div>
  </div>
</template>

<script>
export default {
  //   components: {},
  data() {
    return {
      currentTemperatureType: "Celsius", // 默认显示摄氏度, 点击可在摄氏度 (Celsius) 与华氏度 (Fahrenheit) 之间切换显示
      temperature: "30", // 当前显示的温度
    };
  },
  methods: {
    changeTemperatureType(e) {
      if (this.currentTemperatureType === e) {
        return;
      }
      // 30℃ <-> 86℉
      if (this.currentTemperatureType === "Celsius") {
        // 摄氏度转华氏度
        this.temperature = 32 + this.temperature * 1.8;
      } else {
        // 华氏度转摄氏度
        this.temperature = (this.temperature - 32) / 1.8;
      }
      this.currentTemperatureType = e;
    },
  },
};
</script>

<style scoped>
.temperature-content {
  width: 301px;
  height: 195px;
  box-sizing: border-box;
  background: #3c4556;
  border-radius: 0px 0px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.temperature-content div {
  color: #ffffff;
}

.title {
  font-size: 16px;
  font-weight: 400;
}

.value {
  font-size: 31px;
  font-weight: 500;
  margin-top: 7px;
}

.bottom-right {
  width: 81px;
  height: 27px;
  background: rgba(19, 25, 27, 0.5);
  border-radius: 14px;
  border: 1px solid #333b47;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.bottom-right::before {
  content: " ";
  position: absolute;
  width: 1px;
  height: 13px;
  background-color: #3f4957;
}

span {
  cursor: pointer;
  user-select: none;
}

span.active {
  color: #fcb900;
}
</style>
