<template>
    <div class="container">
        <div v-if="shadowPage === true">
            <span class="content-text">
                Shadow, or named "Virtual Data Point" is the virtual representation of
                the physical device or aggregated data. For example, the shadow of an
                HVAC controller can be a QR code-based web that allows users to control
                the HVAC without any physical control panel. The aggregated data is a
                way to summarise data from multiple sources.
            </span>
            <div class="content-box">
                <div class="content-box-item" v-for="item in data" :key="item.id">
                    <p>{{ item.title }}</p>
                    <p>ID: {{ item.id }}</p>
                    <el-button
                        class="item-buttom"
                        size="small"
                        @click="entryShadowDetail(item)">{{ item.buttonName }}
                    </el-button>
                    <el-image
                        class="item-image"
                        :src="iconImg[item.type]"
                        fit="fill">
                    </el-image>
                </div>
            </div>
        </div>
        <shadow-detail
            v-if="shadowDetailPage"
            :shadow="shadow"
            @goShadow="goShadow">
        </shadow-detail>
    </div>
</template>

<script>
import shadow_btn1 from "../assets/icons/shadow-btn1@2x.png";
import shadow_btn2 from "../assets/icons/shadow-btn2@2x.png";
import shadow_btn3 from "../assets/icons/shadow-btn2@2x.png";
import shadow_btn4 from "../assets/icons/shadow-btn2@2x.png";
import shadow_dimming1 from "../assets/icons/shadow-btn2@2x.png";
import shadow_cochl from "../assets/icons/shadow-cochl@2x.png";
import shadow_mqtt from "../assets/icons/shadow-mqtt.svg";
import websocket from "../assets/icons/shadow-websocket-76X76.png";
import accontrol from "../assets/icons/vcontroller-logo@2x.png";
import ShadowDetail from "./ShadowDetail.vue";

const iconImg = {
    button1: shadow_btn1,
    button2: shadow_btn2,
    button3: shadow_btn3,
    button4: shadow_btn4,
    dimming1: shadow_dimming1,
    cochl: shadow_cochl,
    mqtt: shadow_mqtt,
    websocket: websocket,
    accontrol: accontrol,
};

export default {
    components: {
        ShadowDetail,
    },
    name: "Shadow",
    props: {},
    data() {
        return {
            data: [
                {
                    title: "Single Switch Button",
                    description:"The On/OFF 1 button provide a front-end template for user to easily create a single button for On/ Off switching for devices. To setup, it is required to choose the connected device to map with. If you haven’t connected any device yet, please do it first in “Connect” tab. Once this shadow is created, please find “On/Off” one button in the flow to connect with and create your data interactions.",
                    id: "S1",
                    type: "button1",
                    buttonName: "Button",
                },
                {
                    title: "Two Switch Button",
                    description:"The On/OFF 2 button provide a front-end template for user to easily create a single button for On/ Off switching for devices. To setup, it is required to choose the connected device to map with. If you haven’t connected any device yet, please do it first in “Connect” tab. Once this shadow is created, please find “On/Off” one button in the flow to connect with and create your data interactions.",
                    id: "S2",
                    type: "button2",
                    buttonName: "Button",
                },
                {
                    title: "Three Switch Button",
                    description:"The On/OFF 3 button provide a front-end template for user to easily create a single button for On/ Off switching for devices. To setup, it is required to choose the connected device to map with. If you haven’t connected any device yet, please do it first in “Connect” tab. Once this shadow is created, please find “On/Off” one button in the flow to connect with and create your data interactions.",
                    id: "S3",
                    type: "button3",
                    buttonName: "Button",
                },
                {
                    title: "Four Switch Button",
                    description:"The On/OFF 4 button provide a front-end template for user to easily create a single button for On/ Off switching for devices. To setup, it is required to choose the connected device to map with. If you haven’t connected any device yet, please do it first in “Connect” tab. Once this shadow is created, please find “On/Off” one button in the flow to connect with and create your data interactions.",
                    id: "S4",
                    type: "button4",
                    buttonName: "Button",
                },
                {
                    title: "Dimming Control",
                    description:"The Dimming Control provide a front-end template for user to easily create a single button for On/ Off switching for devices. To setup, it is required to choose the connected device to map with. If you haven’t connected any device yet, please do it first in “Connect” tab. Once this shadow is created, please find “On/Off” one button in the flow to connect with and create your data interactions.",
                    id: "S5",
                    type: "dimming1",
                    buttonName: "Button",
                },
                {
                    title: "Cochl",
                    description:"Cochl is a research-based company focusing on Machine Listening technology. Most of current sound AI systems were limited in speech recognition and Cochl is making AI understand any kind of non-verbal sounds such as dog bark, laugh, scream, sneeze, water tap and fire alarm. It can be used in a variety of industries such as smart home, robotics,automotive, search engine and so on.",
                    id: "C1",
                    type: "cochl",
                    buttonName: "AI",
                },
                {
                    title: "MQTT",
                    description:"MQTT provide a mqtt username and password for Cereb Platform user to use Flow MQTT related nodes.",
                    id: "MQTT",
                    type: "mqtt",
                    buttonName: "Connect",
                },
                {
                    title: "Websocket",
                    description:"WebSocket provide a destination and id for full-duplex communication on Cereb Platform.",
                    id: "Websocket",
                    type: "websocket",
                    buttonName: "Connect",
                },
                {
                    title: "AC Control",
                    description:"Air Conditioner Control provide a front-end template for user to easily create a single button for On/ Off switching for devices. To setup, it is required to choose the connected device to map with. If you haven’t connected any device yet, please do it first in “Connect” tab. Once this shadow is created, please find “On/Off” one button in the flow to connect with and create your data interactions.",
                    id: "AC",
                    type: "accontrol",
                    buttonName: "Connect",
                }
            ],
            iconImg: iconImg,
            shadowDetailPage: false,
            shadowPage: true,
            shadow: Object,
        };
    },
    methods: {
        entryShadowDetail(shadow) {
            this.shadowDetailPage = true;
            this.shadowPage = false;
            this.shadow = shadow;
        },
        goShadow() {
            this.shadowDetailPage = false;
            this.shadowPage = true;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
    margin: 0;
    padding: 0;
}

.container {
    z-index: 0;
    /* position: relative; */
    margin-left: 121px;
    margin-top: 25px;
    margin-right: 121px;
    margin-bottom: 121px;
    min-height: 537px;
    min-width: 800px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #ffffff;
}

/* 文字 */
.content-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

/* box区域 */
.content-box {
    margin-top: 45px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

/* 单个box */
.content-box .content-box-item {
    width: 25%;
    height: 182px;
    background: #182130;
    border-radius: 2px;
    padding: 0 30px 0;
    position: relative;
    margin: 17px 17px 0 0;
    min-width: 280px;
}

.content-box .content-box-item p:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
    margin: 38px 0 7px;
}

.content-box .content-box-item p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
}

.content-box .content-box-item .item-buttom {
    width: 90px;
    height: 29px;
    background: #0e8ce3;
    border-radius: 2px;
    border: none;
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    line-height: 13px;
    margin-top: 40px;
}

.content-box .content-box-item .item-image {
    width: 76px;
    height: 76px;
    position: absolute;
    right: 32px;
    top: 31px;
    border-radius: 50%;
}
</style>
