<template>
	<div>
		<ConnectInstancesDialog :showIntervalSecOptions="false" :show="(isEdit || isCreate) && !showSelectDevice" :btnLoadding="confirmButtonLoadingStatus"
			:defaultIntervalSecValue.sync="update_asset_data_interval_sec" :disconnectedEmailNotify.sync="disconnectedEmailNotify" @clickClose="clickClose"
			:confirmName="'Next'" @clickConfirm="clickConfirm">
			<template v-slot:content>
				<div class="popup-field1">
					<div class="label-block"><label>Name:</label></div>
					<input class="cpt-input1" v-model="connectInstanceVoName" type="text" name="salto-name" />
				</div>
				<div class="popup-oauth">
					<p>OAuth</p>
					<el-button :loading="signLifeSmartLoading" v-if="!isLifesmartLogin" class="popup-oauth-button"
						@click="lifeSmartLogin">
						<img src="../assets/icons/lifesmart-76X76.png">
						<span>Sign in with Lifesmart</span>
					</el-button>
					<div v-else class="popup-oauth-connected" @click="lifeSmartLogin">
						<img src="../assets/icons/lifesmart-re.png">
						<span>Connected</span>
					</div>
				</div>
			</template>
		</ConnectInstancesDialog>

		<SelectDevices 
			v-if="showSelectDevice" 
			:connectInstanceVo="connectInstanceVo"
			:integrationDeviceList="integrationDeviceList"
			:selectedDeviceKeys="selectedDeviceKeys"
			@clickClose="clickClose"
			@selectDevicesComfirm="selectDevicesComfirm"
		> 
		</SelectDevices>
	</div>
</template>

<script>
	import {
		getLifeSmartUrl,
		createConnectInstance,
		updateConnectInstance,
		webSocketClient,
	} from '../api'

	import ConnectInstancesDialog from './template/ConnectInstancesDialog.vue'
	import SelectDevices from './SelectDevices.vue'
	export default {
		name: 'SaltoSetting',
		props: {
			connect: Object,
			instance: Object,
			connectInstanceId: Number,
			isEdit: Boolean,
			isCreate: Boolean,
			currentEditedConnectInstanceName: String,
			currentEditedUpdateAssetDataIntervalSec: String
		},
		components: {
			ConnectInstancesDialog,
			SelectDevices,
		},
		emits: ['closeSetting'],
		data() {
			return {
				show: '',
				confirmButtonLoadingStatus: false,
				connectInstanceVoName: '',
				connectInstanceVo: {
					name: '',
					attr: {}
				},
				// lifeSmartShow: true,
				lifeSmartMessageId: '',
				wopen: null,
				loginStatus: {},
				stompClient: null,
				stopped: false,
				signLifeSmartLoading: false,
				update_asset_data_interval_sec: '0',
				disconnectedEmailNotify: 'false',

				showSelectDevice: false,
				integrationDeviceList: [],
				selectedDeviceKeys: [],
			}
		},
		computed: {
			isLifesmartLogin: function () {
				return this.lifeSmartMessageId && this.lifeSmartMessageId === this.loginStatus.messageId && this.loginStatus
					.status;
			},
		},
		methods: {
			createConectDetail() {
				this.show = 'popup-salto-setting';
				this.isCreate = true
				this.connectInstanceVoName = ''
			},
			editConectDetail() {
				this.show = 'popup-salto-setting';
				this.isEdit = true
				this.connectInstanceVoName = this.currentEditedConnectInstanceName
				this.update_asset_data_interval_sec = this.currentEditedUpdateAssetDataIntervalSec;
				this.disconnectedEmailNotify = this.instance.attr.disconnectedEmailNotify
			},
			clickClose() {
				this.$emit('closeSetting')
			},
			// Click Confirm Btn
			clickConfirm() {
				if (this.isLifesmartLogin && this.connectInstanceVoName && this.update_asset_data_interval_sec) {
					this.confirmButtonLoadingStatus = true;
					this.connectInstanceVo.name = this.connectInstanceVoName
					this.connectInstanceVo.connectId = this.connect.id
					this.connectInstanceVo.attr = {
						'messageId': this.lifeSmartMessageId + '',
						'update_asset_data_interval_sec': this.update_asset_data_interval_sec,
						'disconnectedEmailNotify': this.disconnectedEmailNotify,
					}
					// if isCreate
					if (this.isCreate) {
						createConnectInstance(this.connectInstanceVo).then(res => {
							this.showSelectDevice = true
							this.connectInstanceVo = res.connectInstanceVo
							this.integrationDeviceList = res.deviceList
						}).catch(error => {
							if (error.response.data === 'over data points') {
								this.$message.error('over data points limit');
							} else if (error.response.data.code === 103) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
								this.show = 'popup-salto-setting'
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						}).finally(() => this.confirmButtonLoadingStatus = false)
					}
					// if isEdit
					if (this.isEdit) {
						updateConnectInstance(this.connectInstanceId, this.connectInstanceVo, true)
							.then((res) => {
								this.showSelectDevice = true
								this.connectInstanceVo = res.connectInstanceVo
								this.integrationDeviceList = res.deviceList
								this.selectedDeviceKeys = res.selectedDeviceKeys
							})
							.catch((error) => {
								if (error.response.data.code === 103) {
									this.$message.error({
										center: true,
										message: 'Duplicate name, please re-enter!'
									});
									this.show = 'popup-salto-setting'
								} else {
									this.$message.error({
										center: true,
										message: 'Network Error!'
									});
								}
							}).finally(() => this.confirmButtonLoadingStatus = false)
					}
				} else {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
					this.confirmButtonLoadingStatus = false
				}
			},
			lifeSmartLogin() {
				// this.lifeSmartMessageId = null
				this.signLifeSmartLoading = true
				getLifeSmartUrl().then(res => {
					var iWidth = 390;
					var iHeight = 530;
					var iTop = (window.screen.availHeight - 30 - iHeight) / 2;
					var iLeft = (window.screen.availWidth - 10 - iWidth) / 2;
					this.wopen = window.open(res.url, 'LifeSmart login', 'height=' + iHeight + ',innerHeight=' + iHeight +
						',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft +
						',menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes');
					// this.setTimer(res.messageId)
					this.lifeSmartMessageId = res.messageId;
				})
			},
			lifemsartWSStart() {
				let websocketUrl = '/ws/lifesmart';
				// let websocketUrl = 'https://api.cereb.ai/v1/ws/lifesmart';
				let websocketDestination = '/user/topic/lifesmart_login_status';
				this.stompClient = webSocketClient(websocketUrl)
				let headers = {
					"access-token": localStorage.token
				}

				let that = this;
				this.stompClient.connect(headers, () => {
					this.stompClient.subscribe(websocketDestination, function (response) {
						let websocketDataVo = JSON.parse(response.body);
						console.log(websocketDataVo);
						// console.log(res)
						if (websocketDataVo.messageId >= that.lifeSmartMessageId) {
							that.signLifeSmartLoading = false
							that.loginStatus = websocketDataVo
						}
						that.wopen.close();
					})
				}, () => {
					if (!that.stopped && !this.stompClient.connected) {
						setTimeout(that.lifemsartWSStart, 1000)
					}
				});
			},

			selectDevicesComfirm() {
				this.$emit('closeSetting')
			}
		},

		mounted: function () {
			this.lifemsartWSStart()
			if (this.isCreate) {
				// console.log("this.createConectDetail()------------------------------>")
				this.createConectDetail()
			} else if (this.isEdit) {
				// console.log("this.editConectDetail()")
				this.editConectDetail()
			}
		},

		destroyed: function () {
			this.stopped = true;
			this.stompClient.disconnect();
			// this.socket.close();
		},
	};
</script>

<style scoped>
	.cpt-btn1 {
		background: #FCB900;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn1 span {
		font-weight: 500;
		color: #FFFFFF;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-input1 {
		box-sizing: content-box;
		height: 39px;
		width: 94%;
		border-radius: 4px;
		border: 1px solid #333B47;
		background: #182130;
		color: #FFFFFF;
		padding: 0px 10px;
		font-size: 14px;
	}


	/* Popup */
	.popup {
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 100;
		display: none;
	}

	.popup.show {
		display: block;
	}

	.popup .popup-bg {
		background: black;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 1;
		opacity: 0.4;
	}

	.popup .popup-content {
		background-color: #182130;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		padding: 36px 32px 35px 32px;
		color: #FFFFFF;
	}

	.popup .popup-close {
		position: absolute;
		top: 36px;
		right: 32px;
		width: 22px;
		height: 22px;
		background: url('../assets/icons/icon-close.png') no-repeat center center;
		cursor: pointer;
	}

	.popup .popup-button-panel {
		text-align: right;
	}


	.popup.salto-setting .popup-content {
		min-width: 354px;
		border-radius: 1px;
	}

	.popup.salto-setting .popup-title1 {
		margin-bottom: 19px;
	}

	.popup.salto-setting .popup-field1 {
		margin-bottom: 15px;
	}

	.popup.salto-setting .popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup.salto-setting .popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup.salto-setting .popup-field1 .popup-row {
		display: flex;
	}

	.popup.salto-setting .popup-field1 .popup-row .col-1 {
		width: 75%;
	}

	.popup.salto-setting .popup-field1 .popup-row .col-2 {
		width: 5%;
	}

	.popup.salto-setting .popup-field1 .confirm-test-button {
		padding: 13px 23px;
	}

	.popup.salto-setting .popup-button-panel {
		padding-top: 13px;
	}

	.popup-oauth {
		margin-top: 27px;
	}

	.popup-oauth>p {
		display: inline-block;
		margin-top: 12px;
	}

	.popup-oauth .popup-oauth-button {
		float: right;
		height: 38px;
		background: transparent;
		border: 1px solid #FFFFFF;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.popup-oauth .popup-oauth-button span {
		vertical-align: top;
	}

	.popup-oauth img {
		margin-right: 10px;
		margin-top: -2px;
		vertical-align: top;
	}

	.popup-oauth .popup-oauth-connected {
		float: right;
		margin-top: 12px;
		margin-bottom: 42px;
		cursor: pointer;
	}

	.popup-oauth .popup-oauth-connected img {
		vertical-align: middle;
	}

	/* Popup */
</style>