<template>
	<div>
		<!-- --------- -->
		<!--  Steps 1  -->
		<!-- --------- -->
		<ConnectInstancesDialog :show="(isEdit || isCreate) && showStep1" :btnLoadding="confirmButtonLoadingStatus"
			:confirmName="'Next'" :defaultIntervalSecValue.sync="update_asset_data_interval_sec"
			:disconnectedEmailNotify.sync="disconnectedEmailNotify" @clickClose="clickClose" @clickConfirm="popupNext">
			<template v-slot:content>
				<div class="popup-field1">
					<div class="label-block"><label>Name:</label></div>
					<input class="cpt-input1" v-model="connectInstanceVoName" type="text" />
				</div>
				<div class="popup-field1">
					<div class="label-block"><label>UserName:</label></div>
					<input class="cpt-input1" v-model="kuju_username" type="text" />
				</div>
				<div class="popup-field1">
					<div class="label-block"><label>Password:</label></div>
					<div class="popup-row">
						<div class="col-1">
							<input class="cpt-input1" v-model="kuju_password" type="password" />
						</div>
						<div class="col-2"></div>
						<div class="col-3">
							<el-button type="primary" :loading="testButtonLoadingStatus" @click="clickTest">Test</el-button>
						</div>
					</div>
				</div>
				<div v-if="testMsgSuccess != null" class="message-panel">
					<span class="setting_icon_error" v-show="!testMsgSuccess">
						<img class="setting_icon_error_img" src="../assets/icons/icon-invalid@2x.png">
						Denied
					</span>
					<span class="setting_icon_success" v-show="testMsgSuccess">
						<img class="setting_icon_success_img" src="../assets/icons/icon-success@2x.png">
						Pass
					</span>
				</div>
			</template>
		</ConnectInstancesDialog>
		<!-- --------- -->
		<!--  Steps 2  -->
		<!-- --------- -->
		<ConnectInstancesDialog :show="showStep2" :btnLoadding="confirmButtonLoadingStatus" :showIntervalSecOptions="false"
			:showEmailNotify="false" @clickClose="clickClose" :confirmName="'Next'" @clickConfirm="clickConfirm">
			<template v-slot:content>
				<div class="popup-field1">
					<div class="label-block"><label>Site:</label></div>
					<el-select placeholder="" :no-data-text="'No Data'" popper-class="custom-salto-select"
						style="width: 100%; overflow: hidden;" v-model="gateway_sn_list_selected" multiple filterable>
						<el-option v-for="item in gateway_sn_list" :key="item.gateway_sn"
							:label="item.gateway_sn + ' ( ver ' + item.gateway_ver+ ' )'" :value="item.gateway_sn" />
					</el-select>
				</div>

			</template>
		</ConnectInstancesDialog>

		<SelectDevices 
			v-if="showSelectDevice" 
			:connectInstanceVo="connectInstanceVo"
			:integrationDeviceList="integrationDeviceList"
			:selectedDeviceKeys="selectedDeviceKeys"
			@clickClose="clickClose"
			@selectDevicesComfirm="selectDevicesComfirm"
		> 
		</SelectDevices>
	</div>
</template>

<script>
	import {
		createConnectInstance,
		updateConnectInstance,
		check_kuju_credential,
		get_kuju_gateway_sn,
	} from '../api'

	import ConnectInstancesDialog from './template/ConnectInstancesDialog.vue'
	import SelectDevices from './SelectDevices.vue'
	export default {
		name: 'KujuSetting',
		props: {
			connect: Object,
			instance: Object,
			connectInstanceId: Number,
			isEdit: Boolean,
			isCreate: Boolean,
			currentEditedConnectInstanceName: String,
			currentEditedUpdateAssetDataIntervalSec: String
		},
		components: {
			ConnectInstancesDialog,
			SelectDevices,
		},
		data() {
			return {
				showStep1: true,
				showStep2: false,
				confirmButtonLoadingStatus: false,
				testButtonLoadingStatus: false,
				testMsgSuccess: null,
				connectInstanceVoName: null,
				update_asset_data_interval_sec: null,
				disconnectedEmailNotify: 'false',
				kuju_username: null,
				kuju_password: null,
				connectInstanceVo: {
					name: null,
					attr: {}
				},
				// gateway_sn: null,
				gateway_sn_list: [],
				gateway_sn_list_selected: [],

				showSelectDevice: false,
				integrationDeviceList: [],
				selectedDeviceKeys: [],
			}
		},
		computed: {},
		methods: {
			createConectDetail() {
				this.isCreate = true
			},
			editConectDetail() {
				this.isEdit = true
				this.connectInstanceVoName = this.currentEditedConnectInstanceName
				this.update_asset_data_interval_sec = this.currentEditedUpdateAssetDataIntervalSec;
				this.disconnectedEmailNotify = this.instance.attr.disconnectedEmailNotify
			},
			clickClose() {
				this.$emit('closeSetting')
			},
			// Click Test Btn
			clickTest() {
				if (this.connectInstanceVoName && this.kuju_username && this.kuju_password) {
					this.testButtonLoadingStatus = true
					check_kuju_credential(this.kuju_username, this.kuju_password).then(() => {
						this.testButtonLoadingStatus = false
						this.testMsgSuccess = true
					}).catch(() => {
						this.testMsgSuccess = false
					}).finally(() => this.testButtonLoadingStatus = false)
				} else {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
				}
			},
			// Click Confirm Btn
			clickConfirm() {
				if (this.testMsgSuccess && this.connectInstanceVoName && this.update_asset_data_interval_sec && this.gateway_sn_list_selected.length > 0) {
					this.confirmButtonLoadingStatus = true;
					this.connectInstanceVo.name = this.connectInstanceVoName
					this.connectInstanceVo.connectId = this.connect.id
					this.connectInstanceVo.attr = {
						'kuju_username': this.kuju_username,
						'kuju_password': this.kuju_password,
						'update_asset_data_interval_sec': this.update_asset_data_interval_sec,
						'disconnectedEmailNotify': this.disconnectedEmailNotify,
						'gateway_sn': JSON.stringify(this.gateway_sn_list_selected),
					}
					// if isCreate
					if (this.isCreate) {
						createConnectInstance(this.connectInstanceVo).then(res => {
							this.showStep2 = false
							this.showSelectDevice = true
							this.connectInstanceVo = res.connectInstanceVo
							this.integrationDeviceList = res.deviceList
						}).catch(error => {
							if (error.response.data === 'over data points') {
								this.$message.error('over data points limit');
							} else if (error.response.data.code === 103) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
								this.show = 'popup-salto-setting'
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						}).finally(() => this.confirmButtonLoadingStatus = false)
					}
					// if isEdit
					if (this.isEdit) {
						updateConnectInstance(this.connectInstanceId, this.connectInstanceVo, true)
							.then((res) => {
								this.showStep2 = false
								this.showSelectDevice = true
								this.connectInstanceVo = res.connectInstanceVo
								this.integrationDeviceList = res.deviceList
								this.selectedDeviceKeys = res.selectedDeviceKeys
							})
							.catch((error) => {
								if (error.response.data.code === 103) {
									this.$message.error({
										center: true,
										message: 'Duplicate name, please re-enter!'
									});
									this.show = 'popup-salto-setting'
								} else {
									this.$message.error({
										center: true,
										message: 'Network Error!'
									});
								}
							}).finally(() => this.confirmButtonLoadingStatus = false)
					}
				} else {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
					this.confirmButtonLoadingStatus = false
				}
			},
			popupNext() {
				if (this.testMsgSuccess && this.update_asset_data_interval_sec) {
					get_kuju_gateway_sn(this.kuju_username, this.kuju_password).then(res => {
						console.log(res);
						this.gateway_sn_list = res
					})
					this.showStep1 = false
					this.showStep2 = true
				} else {
					this.$message.error('Please enter the complete information!');
				}
			},

			selectDevicesComfirm() {
				this.$emit('closeSetting')
			}
		},

		mounted: function () {
			if (this.isCreate) {
				this.createConectDetail()
			} else if (this.isEdit) {
				this.editConectDetail()
			}
		},
	};
</script>

<style scoped>
	.cpt-btn1 {
		background: #FCB900;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn1 span {
		font-weight: 500;
		color: #FFFFFF;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-input1 {
		box-sizing: content-box;
		height: 39px;
		width: 94%;
		border-radius: 4px;
		border: 1px solid #333B47;
		background: #182130;
		color: #FFFFFF;
		padding: 0px 10px;
		font-size: 14px;
	}

	.setting_icon_success.show {
		display: block;
	}

	.setting_icon_success_img {
		vertical-align: middle;
		margin-right: 3px;
		width: 14px;
		height: 14px;
		margin-top: -1px
	}


	.setting_icon_error.show {
		display: block;
	}

	.setting_icon_error_img {
		vertical-align: middle;
		margin-right: 3px;
		width: 14px;
		height: 14px;
		margin-top: -1px
	}



	/* Popup */
	.popup {
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 100;
		display: none;
	}

	.popup.show {
		display: block;
	}

	.popup .popup-bg {
		background: black;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 1;
		opacity: 0.4;
	}

	.popup .popup-content {
		background-color: #182130;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		padding: 36px 32px 35px 32px;
		color: #FFFFFF;
	}

	.popup .popup-close {
		position: absolute;
		top: 36px;
		right: 32px;
		width: 22px;
		height: 22px;
		background: url('../assets/icons/icon-close.png') no-repeat center center;
		cursor: pointer;
	}

	.popup .popup-button-panel {
		text-align: right;
	}


	.popup-content {
		min-width: 354px;
		border-radius: 1px;
	}

	.popup-title1 {
		margin-bottom: 19px;
	}

	.popup-field1 {
		margin-bottom: 15px;
	}

	.popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup-field1 .popup-row {
		display: flex;
	}

	.popup-field1 .popup-row .col-1 {
		width: 75%;
	}

	.popup-field1 .popup-row .col-2 {
		width: 5%;
	}

	.popup-field1 .confirm-test-button {
		padding: 13px 23px;
	}

	.popup-button-panel {
		padding-top: 13px;
	}

	.popup-oauth {
		margin-top: 27px;
	}

	.popup-oauth>p {
		display: inline-block;
		margin-top: 12px;
	}

	.popup-oauth .popup-oauth-button {
		float: right;
		height: 38px;
		background: transparent;
		border: 1px solid #FFFFFF;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.popup-oauth .popup-oauth-button span {
		vertical-align: top;
	}

	.popup-oauth img {
		margin-right: 10px;
		margin-top: -2px;
		vertical-align: top;
	}

	.popup-oauth .popup-oauth-connected {
		float: right;
		margin-top: 12px;
		margin-bottom: 42px;
		cursor: pointer;
	}

	.popup-oauth .popup-oauth-connected img {
		vertical-align: middle;
	}


	::v-deep .col-3 .el-button--primary {
		display: flex;
		justify-content: center;
		color: #FFF;
		background-color: #212A3A;
		margin-left: 4px;
		width: 67px;
		height: 40px;
		border: none;
	}

	::v-deep .col-3 .el-button--primary span {
		margin-top: 2px;
	}

	::v-deep .popup-button-panel .el-button--primary {
		width: 100px;
		height: 32px;
		background: #FCB900;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* Popup */
</style>