<template>
  <transition name="dialog-fade">
    <div v-if="show" class="dialog-overlay">
      <div class="dialog-container">
        <videoPlayer v-if="url" :options="videoPlayerOptions"></videoPlayer>
        <img src="../../assets/Map-Tab/camera-close.png" alt="" @click="handleClose" />
      </div>
    </div>
  </transition>
</template>

<script>
// https://www.npmjs.com/package/vue-video-player
// https://github.com/surmon-china/videojs-player/issues/113
// npm install vue-video-player@4.x
// npm install video.js
// npm install videojs-contrib-hls.js -s
import { videoPlayer } from "vue-video-player";
import 'videojs-contrib-hls.js';
import "video.js/dist/video-js.css"; // require videojs style

// https://zhuanlan.zhihu.com/p/550407342
//注意：需要引入 videojs 并绑定到 window 上
import videojs from 'video.js';
window.videojs = videojs;
//引入hls.js
// import 'videojs-contrib-hls.js/src/videojs.hlsjs';
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    url: {
      type: String,
      default: "",
      required: true,
    },
  },
  components: {
    videoPlayer,
  },
  data() {
    return {
      // node_modules\vue-video-player\index.js
      // node_modules\vue-video-player\src\player.vue
      // videojs options
      videoPlayerOptions: {
        autoplay: true, // 自动播放
        controls: false,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],  // 播放速度
        sources: [
          {
            // type: "video/mp4",
            // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
            type: 'application/x-mpegURL',
            src: this.url,
            // src: '',
          },
        ],
      },
    };
  },
  methods: {
    handleClose(e) {
      // 阻止事件传播
      e.stopPropagation();
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 666666;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  position: absolute;
  top: 24px;
  right: 24px;
  content: " ";
  width: 36px;
  height: 36px;
  z-index: 333;
}

.dialog-container {
  width: 80%;
  height: 80%;
  position: relative;
  background-color: black;
}

/* 下面四个类是直接从 element-plus 搬过来的, 动画自己diy就行 */
.dialog-fade-enter-active {
  animation: dialog-fade-in 0.3s;
}

.dialog-fade-leave-active {
  animation: dialog-fade-out 0.3s;
}

@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}

div>>>.video-player,
div>>>.video-js {
  width: 100%;
  height: 100%;
}
</style>
