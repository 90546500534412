<template>

    <!-- 这里先不用做，里面就是一张图片 -->
    <div class="container-box">
        <iframe class="frame" :src="workflowUrlBase"></iframe>
        <!-- <iframe class="frame" src="http://localhost:5678"></iframe> -->
        <!-- <iframe class="frame" src="http://localhost:30678"></iframe> -->
    </div>

</template>

<script>
    const WORKFLOW_URL_BASE = process.env.VUE_APP_WORKFLOW_URL_BASE;

    export default {
        components: {

        },
        name: 'Connect',
        props: {
            msg: String
        },
        data() {
            return {
                workflowUrlBase: WORKFLOW_URL_BASE
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .container-box {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .frame {
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
    }

    /* **********************************************************  container  *************************************************************************** */


    /*    #container-first-box {
        display: flex;
        justify-content: space-between;
        z-index: 0;

        width: 1200px;
        height: 140px;
    }
*/
    /* #connected {
        display: inline-block;
        z-index: 1;
        width: 268px;
        height: 140px;
        background: #182130;
        border-radius: 2px;
    }

    #vertical_line_before_connected {
        display: inline-block;
        z-index: 2;
        margin-left: 20px;
        margin-top: 18px;
        width: 2px;
        height: 12px;
        background: #FFFFFF;
        border-radius: 1px;
    }
    #connected_text {
        display: inline-block;
        z-index: 2;
        margin-left: 8px;
        margin-top: 16px;
        width: 71px;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 16px;
    }

    #event-triggered {
        display: inline-block;
        z-index: 1;
        width: 268px;
        height: 140px;
        background: #182130;
        border-radius: 2px;
    }
    #hours-saved {
        display: inline-block;
        z-index: 1;
        width: 268px;
        height: 140px;
        background: #182130;
        border-radius: 2px;
    }
    #event-trend {
        display: inline-block;
        z-index: 1;
        width: 355px;
        height: 140px;
        background: #182130;
        border-radius: 2px;
    } */





    #evant_detail-search_box-box {
        display: inline-block;
        display: flex;
        align-items: center;
        z-index: 0;
        margin-top: 35px;
        width: 1200px;
        height: 48px;
    }

    #event-detail {
        display: inline-block;
        z-index: 1;
        margin-left: 13px;
        width: 103px;
        height: 22px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
    }

    #search-box {
        display: inline-block;
        z-index: 1;
        margin-left: 723px;
        width: 360px;
        height: 48px;
        /* background: url(../assets/dashboard-search-magnifier.png) 24px 24px no-repeat; 
        background-position: 12px 12px; */
        /* background: url('../assets/dashboard-search-magnifier.png') 24px 24px no-repeat center; */
        /* background-image: url('../assets/dashboard-search-magnifier.png') no-repeat center; */
        /* background: url('../assets/dashboard-search-magnifier.png') 24px 24px no-repeat scroll right center transparent; */
        /* background: #252F40 url('../assets/dashboard-search-magnifier.png') 24px 24px no-repeat scroll left center transparent; */
        border: none;
    }


    /* #magnifier {
        display: inline-block;
        z-index: 0;
        position: absolute;

        margin-left: 735px;
        margin-top: 47px;
    }


    #search {
        display: inline-block;
        z-index: 0;
        position: absolute;
        margin-left: 783px;
        margin-top: 49px;
        width: 45px;
        height: 17px;
        font-size: 14px;
        font-family: Helvetica;
        color: #FFFFFF;
        line-height: 17px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    } */

    #container-last-box {
        z-index: 0;
        margin-top: 30px;

        width: 1200px;
        height: 284px;
        background: #182130;
        border-radius: 2px;
    }


    #password-set-box {
        z-index: 1;

        margin-top: 48px;

        width: 1003px;
        height: 47px;
    }

    #password {
        z-index: 2;
        width: 64px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
    }

    #set {
        z-index: 2;
        margin-top: 11px;
        width: 22px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
    }

    #edit-button {
        z-index: 2;
        position: absolute;
        margin-top: -38px;
        margin-left: 1001px;

        width: 67px;
        height: 29px;
        background: #FCB900;
        border-radius: 2px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    #edit-text {
        width: 29px;
        height: 15px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 13px;
    }





























    #login-box-img-box {
        z-index: 0;
        position: absolute;
        width: 542px;
        height: 460px;
    }

    #login-box-img-box-img {
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -219px;
        margin-top: -219px;
        max-width: 438px;
        max-height: 438px;
    }



    #login-box-login {
        z-index: 0;
        position: absolute;
        right: 0%;
        bottom: 0%;
        width: 378px;
        height: 460px;
        background: #182130;
        border-radius: 2px;
    }



    #logo-app_name-box {
        z-index: 1;
        position: absolute;
        margin-left: 34px;
        margin-top: 40px;
        margin-right: 34px;
        margin-bottom: 382px;
        width: 310px;
        height: 38px;
    }

    #login-box-login-logo {
        z-index: 2;
        position: absolute;
        left: 61px;
        top: 0px;
        width: 38px;
        height: 38px;
    }

    #login-box-login-app-name {
        z-index: 2;
        position: absolute;
        margin-left: 105px;
        margin-top: 5px;
        margin-right: 19px;
        margin-bottom: 5px;
        width: 142px;
        height: 26px;
        font-size: 19px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 26px;
    }


    #login_text-to-signup_hyperlink-box {
        z-index: 1;
        position: absolute;
        margin-left: 34px;
        margin-top: 108px;
        margin-right: 78px;
        margin-bottom: 22px;
        width: 310px;
        height: 330px;
    }


    #login-text {
        z-index: 2;
        width: 266px;
        height: 28px;
        font-size: 19px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }

    #login-form-username_or_email-text {
        z-index: 2;
        margin-top: 29px;
        width: 144px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }

    #login-form-username_or_email-input {
        box-sizing: content-box;
        z-index: 2;
        margin-top: 12px;
        /* width: 310px;
        height: 40px; */
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #333B47;
    }

    #login-form-password-text {
        z-index: 2;
        margin-top: 13px;
        width: 104px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }

    input {
        background: #182130;
        /*设置输入框背景色*/
        padding: 0px 0px;
    }

    #login-form-password-input {
        box-sizing: content-box;
        z-index: 2;
        margin-top: 12px;
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #333B47;
    }

    a {
        text-decoration: none;
    }

    #forget-password-box {
        z-index: 2;
        margin-top: 14px;
        width: 310px;
        height: 16px;

        text-align: right;
    }

    #forget-password {
        z-index: 3;
        width: 141px;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    #sign-up-box {
        z-index: 2;

        text-align: center;
    }

    #sign-up {
        z-index: 3;
        width: 51px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    #confirm-button {
        z-index: 2;
        margin-top: 19px;
        width: 310px;
        height: 44px;
        background: #FCB900;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    #confirm-text {
        width: 108px;
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
    }

    body {
        display: block;
        margin: 0px;
    }
</style>