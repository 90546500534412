<template>
    <div class="page-background">

        <div class="resetpassword-outside-box">

            <div class="resetpassword-inside-box">

                    <div class="reset-password-text">Reset  Password</div>



                    <div class="code-text">Code：</div>
                    <input class="code-input" type="text" name="code" v-model="code"/>

                    <!--div id="old-password-text">Old Password：</div>
                    <input id="old-password-input" type="password" name="old-password" /-->

                    <div class="new-password-text"> New Password：</div>
                    <input class="new-password-input" type="password" name="new-password" v-model="newPassword"/>

                    <div class="confirm-password-text">Confirm Password：</div>
                    <input class="confirm-password-input" type="password" name="confirm-password" v-model="confirmPassword"/>



                    <button class="confirm-button" type="button" @click="submitClick">
                        <span class="confirm-text">
                            Confirm
                        </span>
                    </button>

            </div>

        </div>

    </div>
</template>

<script>
import {resetPassword} from '../api'
export default {
    name: 'ResetPassword',
    props: {
        msg: String
    },
    data() {
        return {
            // username: '', 
            email: this.$route.params.email, 
            // oldPassword: '', 
            newPassword: '', 
            confirmPassword: '',
            code: ''
        }
    },
    methods : {
            submitClick: function() {
                if(this.newPassword !== '' && this.confirmPassword !== '' && this.code !== ''){
                    // console.log(this.email)
                    // console.log(this.newPassword)
                    // console.log(this.confirmPassword)
                    // console.log(this.code)
                    if(this.newPassword === this.confirmPassword){
                        resetPassword(this.email, this.newPassword, this.confirmPassword, this.code).then(() => {
                            // console.log("login------------------------------------------->")
                            this.$router.push({ 'name': 'home'}).catch(err => (err.log))
                        }).catch(() => {
                            this.$message.error({center: true, message: 'The verification code is invalid!'})
                        })
                    }else{
                        this.$message.error({center: true, message: 'Inconsistent password input!'})
                    }
                }else{
                    this.$message.error({center: true, message: 'Please enter the complete information!'})
                }



            }
    },
    updated(){
        // console.log(this.email)
        // console.log(this.newPassword)
        // console.log(this.confirmPassword)
        // console.log(this.code)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
/* Reset Password Page */
    .resetpassword-outside-box {transform: translate(-50%, -50%);}
    .resetpassword-outside-box input {padding:0px 10px; color:#FFFFFF}
    .resetpassword-outside-box .code-input,
    .resetpassword-outside-box .new-password-input,
    .resetpassword-outside-box .confirm-password-input {width:289px;}
    /* Reset Password Page */




    .page-background {
        box-sizing: content-box;
        z-index: -2;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, #212A3A 0%, #182130 100%);
    }


    .resetpassword-outside-box {
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        /*margin-left: -210px;
        margin-top: -254.5px;*/

        width: 420px;
        height: 443px;
        background: #182130;
        border-radius: 2px;
    }

    .resetpassword-inside-box {
        z-index: 0;
        position: absolute;
        margin-left: 55px;
        margin-top: 41px;
        margin-right: 55px;
        margin-bottom: 42px;
        width: 310px;
        height: 437px;
    }

    .reset-password-text {
        z-index: 1;
        margin-left: 22px;

        text-align: center;

        width: 266px;
        height: 28px;
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }

    .code-text {
        z-index: 1;
        margin-top: 32px;
        width: 74px;
        height: 14px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cfd1d4;
        line-height: 13px;
    }

    .code-input {
        z-index: 1;
        margin-top: 10px;
    }
    
    .old-password-text {
        z-index: 1;
        margin-top: 15px;
        width: 114px;
        height: 14px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 13px;
    }

    .old-password-input {
        z-index: 1;
        margin-top: 10px;
    }
    
    .new-password-text {
        z-index: 1;
        margin-top: 15px;
        width: 114px;
        height: 14px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cfd1d4;
        line-height: 13px;
    }

    .new-password-input {
        z-index: 1;
        margin-top: 10px;
    }
    
    .confirm-password-text {
        z-index: 1;
        margin-top: 15px;
        width: 200px;
        height: 14px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cfd1d4;
        line-height: 13px;
    }

    .confirm-password-input {
        z-index: 1;
        margin-top: 10px;
    }
    input {
        background: #182130;  /*设置输入框背景色*/
        padding: 0px 0px;
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #2C3443;
        font-size: 14px;
    }

    .confirm-button {
        z-index: 2;
        margin-top: 32px;
        margin-bottom: 42px;

        width: 310px;
        height: 44px;
        background: #FCB900;
        border-radius: 2px;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0;
        border-width: 0px;
    }

    .confirm-text {
        width: 108px;
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
    }

    
    input {
        background: #182130;  /*设置输入框背景色*/
        padding: 0px 0px;
    }


    body {
    display: block;
    margin: 0px;
    }
</style>
