<template>
  <div class="humidity-content">
    <div class="title">Humidity</div>
    <div class="value">80%</div>
  </div>
</template>

<script>
export default {
  //   components: {},
};
</script>

<style scoped>
.humidity-content {
  width: 301px;
  height: 195px;
  box-sizing: border-box;
  background: #3c4556;
  border-radius: 0px 0px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.humidity-content div {
  color: #ffffff;
}
.title {
  font-size: 16px;
  font-weight: 400;
}
.value {
  font-size: 31px;
  font-weight: 500;
  margin-top: 7px;
}
</style>
