<template>
    <div class="top-bar">
        <div class="top-bar-inside-box">
            <img class="logo" src="../assets/logo.png" @click="homeClick">
            <b class="app-name" @click="homeClick">Cereb Platform</b>
            <a class="setting-hyperlink" href="#" @click="settingClick">Setting</a>
            <a class="log-out-hyperlink" href="#" @click="logoutClick">Log Out</a>
        </div>
    </div>
</template>

<script>
    import {
        EventBus
    } from "../event-bus.js";

    export default {
        name: 'HomeTopBar',
        props: {
            msg: String
        },
        methods: {
            logoutClick() {
                EventBus.$emit('logout');
            },
            settingClick() {
                this.$router.push({
                    'name': 'setting'
                })
            },
            homeClick() {
                this.$router.push({
                    'name': 'home'
                })
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* *********************************************************  top-bar  **************************************************************************** */
    .top-bar {
        /*z-index: -1;*/
        position: absolute;

        /*display: flex;*/
        /*justify-content: center;*/

        width: 100%;
        height: 66px;
        background: #171D20;
        box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.35);
    }

    .top-bar-inside-box {
        /*z-index: 0;*/
        position: relative;
        margin-left: 120px;
        margin-right: 120px;

        /*width: 1200px;*/
        height: 66px;
    }

    .logo {
        display: inline-block;
        position: absolute;
        top: 14px;
        left: 0;
        z-index: 1;
        /*margin-top: 14px;*/
        width: 38px;
        height: 38px;
        /*cursor:pointer;*/
    }

    .app-name {
        display: inline-block;
        z-index: 1;
        position: absolute;
        left: 46px;
        /*        margin-left: 12px;
        margin-top: 19px;
		cursor:pointer;
*/
        width: 138px;
        height: 66px;
        font-size: 19px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 66px;
    }

    .setting-hyperlink {
        text-align: right;
        display: inline-block;
        z-index: 1;
        position: absolute;
        right: 108px;
        /*        margin-left: 1013px;
        margin-top: 21px;
*/
        width: 47px;
        height: 66px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 66px;
    }

    .log-out-hyperlink {
        text-align: right;
        display: inline-block;
        z-index: 1;
        position: absolute;
        right: 0;
        /*        margin-left: 1116px;
        margin-top: 21px;
*/
        width: 80px;
        height: 66px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 66px;
    }

    a {
        text-decoration: none;
    }
</style>