<template>
    <transition name="fade">
        <div class="radio-select-asset-name-root">
            <div v-for="(item, index) in allAssetsWithNoGeo" :key="index" class="item"
                @click="updateSelectedIndex(index)">
                <div>{{ item.name }}</div>
                <img v-if="currentSelectedIndex === index" src="../../assets/Map-Tab/radio-selected.png" alt="">
                <img v-else src="../../assets/Map-Tab/radio-unselected.png" alt="">
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        allAssetsWithNoGeo: {
            type: Array,
            // default: () => ['Lamp1 - Polarity', 'Lamp2 - Polarity', 'Lamp3 - Polarity'],
            default: () => [],
        },
    },
    data() {
        return {
            currentSelectedIndex: -1, // 当前选中下标, 默认不选中任何设备名
        };
    },
    methods: {
        updateSelectedIndex(index) {
            this.currentSelectedIndex = index;
            this.$emit('select', this.allAssetsWithNoGeo[this.currentSelectedIndex].assetId);
        },
    },
};
</script>

<style scoped>
/* 添加过渡动画效果 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.radio-select-asset-name-root {
    max-height: 395px;
    z-index: 555;
    background-color: #101517;
    /* border: 1px solid #333B47; */
    overflow-y: auto;
    --triangle-size: 10px;
    --triangle-background-color: #101517;
}

.radio-select-asset-name-root::before {
    /* 由于父元素的 overflow-y auto 的影响 */
    /* 这里不能使用绝对定位而只能改用固定定位 */
    /* position: absolute; */
    position: fixed;
    transform: translate(-100%, 100%);
    content: " ";
    border-top: var(--triangle-size) solid transparent;
    border-left: var(--triangle-size) solid transparent;
    border-right: var(--triangle-size) solid var(--triangle-background-color);
    border-bottom: var(--triangle-size) solid transparent;
}

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    width: 288px;
    box-sizing: border-box;
    padding-left: 31px;
    padding-right: 23px;
}

.item:first-child {
    padding-top: 22px;
    padding-bottom: 20px;
}

.item:not(:first-child, :last-child) {
    padding-top: 20px;
    padding-bottom: 20px;
}

.item:last-child {
    padding-top: 20px;
    padding-bottom: 21px;
}

img {
    width: 16px;
    height: 16px;
}

.item div {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    /* 单行文本超出显示 ... */
    /* https://segmentfault.com/a/1190000039399159 */
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>