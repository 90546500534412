<template>
  <div class="this-box-property" :style="{width:componentWidth}">
    <!-- <div class="this-box-property"> -->

    <!-- <div class="vertical-line"></div> -->

    <div class="first_line">
      <div class="vertical-line-before-box-title">

        <div class="box-title">
          <slot name="box-title"></slot>
        </div>
      </div>
      <!-- <div class="vertical-line"></div> -->

      <div class="box-util">
        <slot name="box-util"></slot>
      </div>
    </div>
    <!-- <div id="vertical-line-before-box-title"></div> -->
    <!-- <span class="vertical-line-before-box-title">|</span>

    <div class="box-title">
      <slot name="box-title"></slot>
    </div> -->

    <div v-if="boxName !== 'event-trend'" class="user_data-and-icon-box">
      <span class="user-data">
        <slot name="top-blue-line"></slot>
      </span>
      <span class="user-data-unit" v-if="boxName === 'connected'">
        <slot name="top-blue-line-text"></slot>
      </span>
      <span class="user-data-blue_unit" v-if="boxName == 'workflow-execution-failure'">
        <slot name="top-blue-line-blue_unit"></slot>
      </span>
      <span class="icon-after-user-data" v-if="boxName === 'event-triggered'">
        <slot name="top-blue-line-icon"></slot>
      </span>
    </div>

    <slot name="line-chart"></slot>

    <div class="top-blue-line"></div>
  </div>
</template>

<script>
  export default {
    name: 'one-of-container-first-box',
    props: ['componentWidth', 'boxName']
  }
</script>

<style scoped>
  .this-box-property {
    border-bottom: 3px solid #274170;
  }

  .user_data-and-icon-box {
    z-index: 2;
    margin-top: 38px;
    margin-left: 27px;
  }

  .user-data {
    z-index: 2;
    color: #0E8CE3;
    font-size: 26px;
  }

  .user-data-unit {
    z-index: 2;
    color: #999DA4;
    font-size: 13px;
    margin-left: 6px;
  }

  .user-data-blue_unit {
    z-index: 2;
    color: #0E8CE3;
    font-size: 13px;
    margin-left: 6px;
  }

  .icon-after-user-data {
    z-index: 2;
    margin-left: 6px;
  }

  .this-box-property {
    display: inline-block;
    z-index: 1;
    width: 100%;
    height: 140px;
    background: #182130;
    border-radius: 2px;
    margin-top: 25px;
  }



  .first_line {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
    height: 16px;
    justify-content: space-between;
  }

  /* .vertical-line {
    width: 2px;
    height: 12px;
    background: #FFFFFF;
  } */

  .vertical-line-before-box-title {
    /* display: inline-block; */
    /* position: relative; */
    z-index: 2;
    margin-left: 20px;
    /* margin-top: -2px; */
    background: #FFFFFF;
    width: 2.2px;
    height: 16px;
    /* line-height: 12px; */
    /* font-size: 12px; */
    /* font-weight: bolder; */
  }

  .box-title {
    white-space: nowrap;
    display: inline-block;
    position: relative;
    z-index: 2;
    margin-left: 8px;
    /* margin-top: 2px; */
    height: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
  }

  #top-blue-line {
    display: block;
    border: none;
    z-index: 2;
    margin-left: 0px;
    margin-top: 105px;
    margin-bottom: 0px;
    width: 268px;
    height: 3px;
    background: #274170;
    border-radius: 6px;
  }
</style>