<template>
  <div class="camera-content" @click="onVideoPlayerTap" ref="root">
    <!-- <img src="../../assets/Map-Tab/camerImage.png" alt="" @click="showCameraDialog = !showCameraDialog"/> -->

    <videoPlayer v-if="isPlaying && !showCameraDialog && url" :options="videoPlayerOptions" ref="player"></videoPlayer>

    <CameraDialog :show="showCameraDialog" @handleClose="updateDialogStatus" :url="url"></CameraDialog>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import 'videojs-contrib-hls.js';
import "video.js/dist/video-js.css";
// https://zhuanlan.zhihu.com/p/550407342
//注意：需要引入 videojs 并绑定到 window 上
import videojs from 'video.js';
window.videojs = videojs;
//引入hls.js
// import 'videojs-contrib-hls.js/src/videojs.hlsjs';

import CameraDialog from "./CameraDialog.vue";
export default {
  props: {
    isPlaying: {
      type: Boolean,
      default: false,
      required: true,
    },
    url: {
      type: String,
      default: "",
      required: true,
    },
  },
  components: {
    CameraDialog,
    videoPlayer,
  },
  data() {
    return {
      showCameraDialog:false,
      videoPlayerOptions: {
        autoplay: this.isPlaying ? true : false, // 自动播放
        controls: false,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],  // 播放速度
        sources: [
          {
            // type: "video/mp4",
            // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
            type: 'application/x-mpegURL',
            src: this.url,
          },
        ],
      },
    };
  },
  methods: {
    updateDialogStatus() {
      this.showCameraDialog = false;
      // this.videoPlayerOptions.muted = false;
      // this.videoPlayerOptions.sources[0].src = this.url;
      // this.$refs.player.$el.style.display = "block";
      // 恢复播放
      // this.$refs.player.player.play();
    },
    onVideoPlayerTap() {
      // this.videoPlayerOptions.muted = true;
      // this.videoPlayerOptions.sources[0].src = "";
      // 暂停
      // this.$refs.player.player.pause();
      // this.$refs.player.player.pause();
      // console.log(this.$refs.root,this.$refs.player.player,this.$refs.player.$el);
      // const cloneVideoPlayer = this.$refs.player.$el.cloneNode(true);
      // this.$refs.player.$el.remove();
      // this.$refs.root.append(cloneVideoPlayer);
      // console.log(cloneVideoPlayer,999);
      // this.$refs.player.$el.style.display = "none";
      // this.$refs.player
      this.showCameraDialog = true;
    },
  },
};
</script>

<style scoped>
.camera-content {
  width: 301px;
  height: 195px;
  padding: 8px;
  box-sizing: border-box;
  background: #3c4556;
  border-radius: 0px 0px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
img {
  width: 100%;
  height: 100%;
}
div>>>.video-player,
div>>>.video-js {
  width: 100%;
  height: 100%;
}
/* div >>> .video-js {
  width: 80% !important;
  height: 80% !important;
} */
</style>
