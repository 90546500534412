import Vue from 'vue';
import CustomMessage from './CustomMessage.vue';

const CustomMessageBox = Vue.extend(CustomMessage);
let instance;
let timer = null;

const handle = function (options = {}) {
    console.log(options)

    if (timer) return;

    instance = new CustomMessageBox({
        data: options
    });

    instance.vm = instance.$mount(); // 调用$mount方法，将当前实例渲染为真实DOM，生成$el，如果不执行这一步，将拿不到 $el 的值
    document.body.appendChild(instance.vm.$el); // 使用原生js的API将当前实例的真实DOM追加到body中
    instance.vm.visible = true;

    timer = setTimeout(() => {
        instance.vm.visible = false;
        timer = null;
    }, instance.vm.duration);

    return instance.vm;
};

export default {
    install(Vue) {
        Vue.prototype.$customMessage = handle;
    }
};