<template>
	<div>
		<div class="popup-panel">
			<div class="popup cochl-setting" v-bind:class="{show : show == 'popup-cochl-setting'}">
				<div class="popup-bg"></div>
				<div class="popup-content">
					<div class="popup-close" @click="$emit('closeSetting')"></div>
					<div class="popup-title1">Cochl Setting</div>
					<div class="popup-field1">
						<div class="label-block"><label>Name:</label></div>
						<input class="cpt-input1" v-model="connectInstanceVo.name" type="text" name="cochl-name" />
					</div>
					<div class="popup-field1">
						<div class="label-block"><label>Cochl key:</label></div>
						<input class="cpt-input1" v-model="connectInstanceVo.attr.api_key" type="password" name="cochl-password" />
					</div>
					<div class="popup-button-panel">
						<div class="confirm_box">
							<el-button type="primary" :loading="confirmButtonLoadingStatus" @click="popupConfirm">Confirm</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		createConnectInstance,
		updateConnectInstance,
		getConnectInstanceVo,
	} from '../api'


	export default {

		components: {

		},
		name: 'CochlSetting',
		props: {
			connect: Object,

			connectInstanceId: Number,
			currentEditedConnectInstanceName: String,

			isEdit: Boolean,
			isCreate: Boolean,
		},
		emits: ['closeSetting'],
		data() {
			return {
				show: '',

				show_message: '',

				connectInstanceVo: {
					instanceId: -1,
					connectId: -1,
					name: '',
					stopped: true,
					attr: {
						api_key: '',
					}
				},

				confirm: false,
				testButtonLoadingStatus: false,
				confirmButtonLoadingStatus: false,
			}
		},
		methods: {
			createConectDetail() {
				this.show = 'popup-cochl-setting';
				this.show_message = '';
				this.connectInstanceVo.connectId = this.connect.id
			},
			editConectDetail() {
				console.log('edit');
				console.log(this.connectInstanceId);

				this.show = 'popup-cochl-setting';
				this.show_message = '';

				this.connectInstanceVoAttrcochl_password = '',

					getConnectInstanceVo(this.connect.id, this.connectInstanceId).then(vo => {
						this.connectInstanceVo.connectId = this.connect.id
						this.connectInstanceVo.name = vo.name
						this.connectInstanceVo.stopped = true
						this.connectInstanceVo.attr.api_key = vo.attr['api_key']
					})
			},

			popupConfirm() {
				this.confirmButtonLoadingStatus = true;

				if (this.isCreate) {
					// console.log(this.connectInstanceVo)
					createConnectInstance(this.connectInstanceVo).then(() => {
							this.$emit('closeSetting')
						})
						.catch((error) => {
							if (error.response.data === 'over data points') {
								this.$message.error('over data points limit');
							} else if (error.response.data.message.includes('Duplicate entry')) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						})
						.finally(() => this.confirmButtonLoadingStatus = false)
				}
				if (this.isEdit) {
					console.log(this.connectInstanceVo)
					console.log(this.connectInstanceId)
					updateConnectInstance(this.connectInstanceId, this.connectInstanceVo, true).then(() => {
							this.$emit('closeSetting')
						})
						.catch((error) => {
							if (error.response.data.message.includes('Duplicate entry')) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						})
						.finally(() => this.confirmButtonLoadingStatus = false)
				}
			},






		},

		mounted: function () {
			if (this.isCreate) {
				// 不能在子组件中给父组件传过来的数据赋值，会红色警告，且不好维护
				// console.log("this.createConectDetail()------------------------------>")
				this.createConectDetail()
			} else if (this.isEdit) {
				// console.log("this.editConectDetail()")
				this.editConectDetail()
			}
		},
		updated: function () {
			// console.log(this.connectInstanceId)
			// console.log(this.connect)
		}
	};
</script>

<style scoped>
	.cpt-btn1 {
		background: #FCB900;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn1 span {
		font-weight: 500;
		color: #FFFFFF;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-btn2 {
		background: #212A3A;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn2 span {
		font-weight: 500;
		color: #999DA5;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-btn3 {
		background: #182130;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn3 span {
		font-weight: 500;
		color: #999DA5;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-connect-link2 {
		text-decoration: none;
	}

	.cpt-connect-link2 span {
		color: #FCB900;
	}

	.cpt-input1 {
		box-sizing: content-box;
		height: 39px;
		width: 94%;
		border-radius: 4px;
		border: 1px solid #333B47;
		background: #182130;
		color: #FFFFFF;
		padding: 0px 10px;
		font-size: 14px;
	}

	.setting_icon_success {
		display: none;
	}

	.setting_icon_success.show {
		display: block;
	}

	.setting_icon_success_img {
		vertical-align: middle;
		margin-right: 3px;
		width: 14px;
		height: 14px;
		margin-top: -1px
	}

	.setting_icon_error {
		display: none;
	}

	.setting_icon_error.show {
		display: block;
	}

	.setting_icon_error_img {
		vertical-align: middle;
		margin-right: 3px;
		width: 14px;
		height: 14px;
		margin-top: -1px
	}


	/* Popup */
	.popup {
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 100;
		display: none;
	}

	.popup.show {
		display: block;
	}

	.popup .popup-bg {
		background: black;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 1;
		opacity: 0.4;
	}

	.popup .popup-content {
		background-color: #182130;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		padding: 36px 32px 35px 32px;
		color: #FFFFFF;
	}

	.popup .popup-close {
		position: absolute;
		top: 36px;
		right: 32px;
		width: 22px;
		height: 22px;
		background: url('../assets/icons/icon-close.png') no-repeat center center;
		cursor: pointer;
	}

	.popup .popup-button-panel {
		text-align: right;
		display: flex;
		justify-content: right;
	}

	/* justify-content: right;要生效，子元素必须为设置margin-left: auto; */
	.popup .popup-button-panel .confirm_box {
		margin-left: auto;
	}


	.popup.cochl-setting .popup-content {
		min-width: 354px;
	}

	.popup.cochl-setting .popup-title1 {
		margin-bottom: 19px;
	}

	.popup.cochl-setting .popup-field1 {
		margin-bottom: 15px;
	}

	.popup.cochl-setting .popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup.cochl-setting .popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup.cochl-setting .popup-field1 .popup-row {
		display: flex;
	}

	.popup.cochl-setting .popup-field1 .popup-row .col-1 {
		width: 75%;
	}

	.popup.cochl-setting .popup-field1 .popup-row .col-2 {
		width: 5%;
	}

	.popup.cochl-setting .popup-field1 .popup-row .col-3 {
		width: 20%;
	}

	.popup.cochl-setting .popup-field1 .confirm-test-button {
		padding: 13px 23px;
	}

	.popup.cochl-setting .popup-button-panel {
		padding-top: 13px;
	}

	.popup.cochl-setting2 .popup-content {
		min-width: 354px;
	}

	.popup.cochl-setting2 .popup-title1 {
		margin-bottom: 19px;
	}

	.popup.cochl-setting2 .popup-field1 {
		margin-bottom: 15px;
	}

	.popup.cochl-setting2 .popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
	}

	.popup.cochl-setting2 .popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup.cochl-setting2 .popup-field1 .confirm-test-button {
		padding: 13px 23px;
	}

	.popup.cochl-setting2 .popup-button-panel {
		padding-top: 13px;
	}

	.popup.cochl-setting2 .item-row {
		display: flex;
		margin-bottom: 7px;
	}

	.popup.cochl-setting2 .item-row .col-1 {
		width: 60%;
		padding-right: 7px;
	}

	.popup.cochl-setting2 .item-row .col-2 {
		width: 20%;
		padding-right: 7px;
	}

	.popup.cochl-setting2 .item-row .col-3 {
		width: 15%;
	}

	.popup.cochl-setting2 .item-row .col-2 .cpt-btn2,
	.popup.cochl-setting2 .item-row .col-2 .cpt-btn3 {
		line-height: 25px;
	}

	/* Popup */

	/* The container must be positioned relative: */
	.connect-brand-image1 img {
		width: 100%;
		height: 100%;
	}

	/* .icon-error {
	width: 7px;
	height: 7px;
	margin-right: 3px;
} */


	.custom-select {
		position: relative;
		font-family: Arial;
	}

	.custom-select select {
		display: none;
		/*hide original SELECT element: */
	}

	.select-selected {
		background-color: #182130;
		border-radius: 4px;
		border: 1px solid #333B47;
	}

	/* Style the arrow inside the select element: */
	.select-selected:after {
		position: absolute;
		content: "";
		top: 17px;
		right: 10px;
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-color: #D1D3D6 transparent transparent transparent;
	}

	/* Point the arrow upwards when the select box is open (active): */
	.select-selected.select-arrow-active:after {
		border-color: transparent transparent #fff transparent;
		top: 7px;
	}

	/* style the items (options), including the selected item: */
	.select-items div,
	.select-selected {
		color: #D1D3D6;
		/*color: #ffffff;*/
		padding: 8px 16px;
		/*border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;*/
		cursor: pointer;
	}

	/* Style items (options): */
	.select-items {
		position: absolute;
		/*background-color: DodgerBlue;*/
		background-color: #1B2536;
		border: 1px solid #2C3443;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 99;
		margin-top: 7px;
	}

	/* Hide the items when the select box is closed: */
	.select-hide {
		display: none;
	}

	.select-items div:hover,
	.same-as-selected {
		background-color: rgba(0, 0, 0, 0.1);
	}



	/* #pop_step_2_dropdown {
	display: flex;
	justify-content: center;
	align-items: center;
} */







	::v-deep .popup-field1 .el-input__inner {
		-webkit-appearance: none;
		background-color: #333B47;
		background-image: none;

		width: 400px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333B47;

		box-sizing: border-box;
		color: #FFFFFF;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	::v-deep .popup-field1 .el-select-dropdown__item {
		font-size: 14px;
		padding: 0 20px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #FFFFFF;
		height: 34px;
		line-height: 34px;
		box-sizing: border-box;
		cursor: pointer;
	}

	::v-deep .col-1 .el-input__inner {
		-webkit-appearance: none;
		background-color: #333B47;
		background-image: none;

		width: 174px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333B47;

		box-sizing: border-box;
		color: #FFFFFF;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	::v-deep .col-1 .el-select-dropdown__item {
		font-size: 14px;
		padding: 0 20px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #FFFFFF;
		height: 34px;
		line-height: 34px;
		box-sizing: border-box;
		cursor: pointer;
	}

	::v-deep .col-3 .el-button--primary {
		display: flex;
		justify-content: center;
		color: #FFF;
		background-color: #212A3A;
		margin-left: 4px;
		width: 67px;
		height: 40px;
		border: none;
	}

	::v-deep .col-3 .el-button--primary span {
		margin-top: 2px;
	}

	::v-deep .popup-button-panel .el-button--primary {
		width: 100px;
		height: 32px;
		background: #FCB900;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>