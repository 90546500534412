<template>
	<div id="box">
		<!-- --------- -->
		<!--  Steps 1  -->
		<!-- --------- -->
		<ConnectInstancesDialog :confirmName="'Next'" :show="(isEdit || isCreate) && showStep1" :btnLoadding="loadingNext"
			:defaultIntervalSecValue.sync="update_asset_data_interval_sec" :disconnectedEmailNotify.sync="disconnectedEmailNotify" @clickClose="clickClose" @clickConfirm="clickNext">
			<template v-slot:content>
				<div class="popup-field1">
					<div class="label-block"><label>Name:</label></div>
					<input class="cpt-input1" v-model="connectInstanceVoName" type="text" name="sonos-name" />
				</div>
				<div class="popup-oauth">
					<p>OAuth</p>
					<el-button :loading="loadingSignSonos" v-if="!isSonosLogin" class="popup-oauth-button" @click="sonosLogin">
						<span>Sign in with Sonos</span>
					</el-button>
					<div v-else class="popup-oauth-connected" @click="sonosLogin">
						<img src="../assets/icons/lifesmart-re.png">
						<span>Connected</span>
					</div>
				</div>
			</template>
		</ConnectInstancesDialog>

		<!-- --------- -->
		<!--  Steps 2  -->
		<!-- --------- -->
		<ConnectInstancesDialog :show="showStep2" :btnLoadding="loadingConfirm" :showIntervalSecOptions="false"
			:showEmailNotify="false"
			@clickClose="clickClose" :confirmName="'Next'" @clickConfirm="clickConfirm">
			<template v-slot:content>
				<div class="popup-field1">
					<div class="label-block"><label>Households:</label></div><br>
					<el-select :no-data-text="'No Data'" popper-class="custom-sonos-select" style="width: 100%; overflow: hidden;"
						v-model="connectInstanceVoAttrHouseholdId" filterable placeholder="Households">
						<el-option v-for="item in HouseholdsOptions" :key="item.id" :label="item.id" :value="item.id" />
					</el-select>
				</div>
			</template>
		</ConnectInstancesDialog>

		<SelectDevices 
			v-if="showSelectDevice" 
			:connectInstanceVo="connectInstanceVo"
			:integrationDeviceList="integrationDeviceList"
			:selectedDeviceKeys="selectedDeviceKeys"
			@clickClose="clickClose"
			@selectDevicesComfirm="selectDevicesComfirm"
		> 
		</SelectDevices>
	</div>
</template>

<script>
	import {
		getSonosUrl,
		createConnectInstance,
		updateConnectInstance,
		webSocketClient,
		getSonosHouseholds,
	} from '../api'

	import ConnectInstancesDialog from './template/ConnectInstancesDialog.vue'
	import SelectDevices from './SelectDevices.vue'

	export default {
		name: 'SonosSetting',
		components: {
			ConnectInstancesDialog,
			SelectDevices,
		},
		props: {
			connect: Object,
			instance: Object,
			connectInstanceId: Number,
			isEdit: Boolean,
			isCreate: Boolean,
			currentEditedConnectInstanceName: String,
			currentEditedUpdateAssetDataIntervalSec: String
		},
		emits: ['closeSetting'],
		data() {
			return {
				showStep1: true,
				showStep2: false,
				confirmButtonLoadingStatus: false,
				connectInstanceVoName: '',
				connectInstanceVo: {
					name: '',
					attr: {}
				},
				// sonosShow: true,
				sonosState: '',
				wopen: null,
				loginStatus: {},
				stompClient: null,
				stopped: false,
				loadingConfirm: false,
				loadingNext: false,
				loadingSignSonos: false,
				connectInstanceVoAttrHouseholdId: null,
				HouseholdsOptions: null,
				update_asset_data_interval_sec: null,
				disconnectedEmailNotify: 'false',

				showSelectDevice: false,
				integrationDeviceList: [],
				selectedDeviceKeys: [],
			}
		},
		computed: {
			isSonosLogin: function () {
				return this.sonosState && this.loginStatus.status && this.sonosState === this.loginStatus.state;
			},
		},
		methods: {
			createConectDetail() {
				this.show = 'popup-sonos-setting';
				this.isCreate = true
				this.connectInstanceVoName = ''
			},
			editConectDetail() {
				this.show = 'popup-sonos-setting';
				this.isEdit = true
				this.connectInstanceVoName = this.currentEditedConnectInstanceName
				this.update_asset_data_interval_sec = this.currentEditedUpdateAssetDataIntervalSec;
				this.disconnectedEmailNotify = this.instance.attr.disconnectedEmailNotify
			},
			clickClose() {
				this.$emit('closeSetting')
			},
			clickNext() {
				if (this.sonosState && this.loginStatus.status) {
					this.loadingNext = true
					getSonosHouseholds(this.sonosState).then(res => {
						this.showStep1 = false
						this.showStep2 = true
						this.HouseholdsOptions = res.households
					}).finally(() => {
						this.loadingNext = false
					})
				} else {
					this.$message.error('Please enter the complete information!');
				}
			},
			// Click Confirm Btn
			clickConfirm() {
				if (this.isSonosLogin && this.connectInstanceVoName && this.connectInstanceVoAttrHouseholdId && this
					.update_asset_data_interval_sec) {
					this.confirmButtonLoadingStatus = true;
					this.connectInstanceVo.name = this.connectInstanceVoName
					this.connectInstanceVo.connectId = this.connect.id
					this.connectInstanceVo.attr = {
						'householdId': this.connectInstanceVoAttrHouseholdId,
						'state': this.sonosState,
						'update_asset_data_interval_sec': this.update_asset_data_interval_sec,
						'disconnectedEmailNotify': this.disconnectedEmailNotify,
					}
					// if isCreate
					if (this.isCreate) {
						createConnectInstance(this.connectInstanceVo).then(res => {
							this.showStep2 = false
							this.showSelectDevice = true
							this.connectInstanceVo = res.connectInstanceVo
							this.integrationDeviceList = res.deviceList
						}).catch(error => {
							if (error.response.data === 'over data points') {
								this.$message.error('over data points limit');
							} else if (error.response.data.code === 103) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
								this.show = 'popup-sonos-setting'
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						}).finally(() => this.confirmButtonLoadingStatus = false)
					}
					// if isEdit
					if (this.isEdit) {
						updateConnectInstance(this.connectInstanceId, this.connectInstanceVo, true)
							.then((res) => {
								this.showStep2 = false
								this.showSelectDevice = true
								this.connectInstanceVo = res.connectInstanceVo
								this.integrationDeviceList = res.deviceList
								this.selectedDeviceKeys = res.selectedDeviceKeys
							})
							.catch((error) => {
								if (error.response.data.code === 103) {
									this.$message.error({
										center: true,
										message: 'Duplicate name, please re-enter!'
									});
									this.show = 'popup-sonos-setting'
								} else {
									this.$message.error({
										center: true,
										message: 'Network Error!'
									});
								}
							}).finally(() => this.confirmButtonLoadingStatus = false)
					}
				} else {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
					this.confirmButtonLoadingStatus = false
				}
			},

			selectDevicesComfirm() {
				this.$emit('closeSetting')
			},

			sonosLogin() {
				// this.sonosState = null
				this.loadingSignSonos = true
				getSonosUrl().then(res => {
					var iWidth = 390;
					var iHeight = 530;
					var iTop = (window.screen.availHeight - 30 - iHeight) / 2;
					var iLeft = (window.screen.availWidth - 10 - iWidth) / 2;
					this.wopen = window.open(res.url, 'Sonos login', 'height=' + iHeight + ',innerHeight=' + iHeight +
						',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft +
						',menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes');
					// this.setTimer(res.messageId)
					this.sonosState = res.state + '';
				})
			},
			sonosWSStart() {
				let websocketUrl = '/ws/sonos';
				// let websocketUrl = 'https://api.cereb.ai/v1/ws/sonos';
				let websocketDestination = '/user/topic/sonos_login_status';
				this.stompClient = webSocketClient(websocketUrl)
				let headers = {
					"access-token": localStorage.token
				}

				let that = this;
				this.stompClient.connect(headers, () => {
					this.stompClient.subscribe(websocketDestination, function (response) {
						let websocketDataVo = JSON.parse(response.body);
						console.log(websocketDataVo);
						// console.log(res)
						if (websocketDataVo.state >= that.sonosState) {
							that.loadingSignSonos = false
							that.loginStatus = websocketDataVo
						}
						that.stopped = true;
						that.stompClient.disconnect();
						that.wopen.close();
					})
				}, () => {
					if (!that.stopped && !this.stompClient.connected) {
						setTimeout(that.sonosWSStart, 1000)
					}
				});
			}
		},

		mounted: function () {
			this.sonosWSStart()
			if (this.isCreate) {
				// console.log("this.createConectDetail()------------------------------>")
				this.createConectDetail()
			} else if (this.isEdit) {
				// console.log("this.editConectDetail()")
				this.editConectDetail()
			}
		},

		destroyed: function () {
			this.stopped = true;
			this.stompClient.disconnect();
			// this.socket.close();
		},
	};
</script>

<style scoped>
	.cpt-btn1 {
		background: #FCB900;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn1 span {
		font-weight: 500;
		color: #FFFFFF;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-input1 {
		box-sizing: content-box;
		height: 39px;
		width: 94%;
		border-radius: 4px;
		border: 1px solid #333B47;
		background: #182130;
		color: #FFFFFF;
		padding: 0px 10px;
		font-size: 14px;
	}


	/* Popup */
	.popup {
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 100;
		display: none;
	}

	.popup.show {
		display: block;
	}

	.popup .popup-bg {
		background: black;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 1;
		opacity: 0.4;
	}

	.popup .popup-content {
		background-color: #182130;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		padding: 36px 32px 35px 32px;
		color: #FFFFFF;
	}

	.popup .popup-close {
		position: absolute;
		top: 36px;
		right: 32px;
		width: 22px;
		height: 22px;
		background: url('../assets/icons/icon-close.png') no-repeat center center;
		cursor: pointer;
	}

	.popup .popup-button-panel {
		text-align: right;
	}


	.popup.sonos-setting .popup-content {
		min-width: 354px;
		border-radius: 1px;
	}

	.popup.sonos-setting .popup-title1 {
		margin-bottom: 19px;
	}

	.popup.sonos-setting .popup-field1 {
		margin-bottom: 15px;
	}

	.popup.sonos-setting .popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup.sonos-setting .popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup.sonos-setting .popup-field1 .popup-row {
		display: flex;
	}

	.popup.sonos-setting .popup-field1 .popup-row .col-1 {
		width: 75%;
	}

	.popup.sonos-setting .popup-field1 .popup-row .col-2 {
		width: 5%;
	}

	.popup.sonos-setting .popup-field1 .confirm-test-button {
		padding: 13px 23px;
	}

	.popup.sonos-setting .popup-button-panel {
		padding-top: 13px;
	}

	.popup-oauth {
		margin-top: 27px;
	}

	.popup-oauth>p {
		display: inline-block;
		margin-top: 12px;
	}

	.popup-oauth .popup-oauth-button {
		float: right;
		height: 38px;
		background: transparent;
		border: 1px solid #FFFFFF;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.popup-oauth .popup-oauth-button span {
		vertical-align: top;
	}

	.popup-oauth img {
		margin-right: 10px;
		margin-top: -2px;
		vertical-align: top;
	}

	.popup-oauth .popup-oauth-connected {
		float: right;
		margin-top: 12px;
		margin-bottom: 42px;
		cursor: pointer;
	}

	.popup-oauth .popup-oauth-connected img {
		vertical-align: middle;
	}

	/* Popup */
</style>