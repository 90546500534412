<template>
	<div class="connect-temp">
		<div class="popup setting">
			<div class="popup-bg" @click="clickClose" />
			<div class="popup-content">
				<div class="popup-close" @click="clickClose" />
				<div class="popup-title1">View</div>

				<div class="popup-field1">
					<div class="label-block"><label>Host:</label></div>
					<input class="cpt-input1" readonly="readonly" v-model="host" type="text"/>
				</div>

        <div class="popup-field1">
					<div class="label-block"><label>Port:</label></div>
					<input class="cpt-input1" readonly="readonly" v-model="port" type="text"/>
				</div>

				<div class="popup-field1">
					<div class="label-block"><label>Username:</label></div>
					<input class="cpt-input1" readonly="readonly" v-model="viewUsername" type="text"/>
				</div>

				<div class="popup-field1">
					<div class="label-block"><label>Password:</label></div>
					<input class="cpt-input1" readonly="readonly" v-model="password" type="text" />
				</div>

        <div class="popup-field1">
					<div class="label-block"><label>Topic permission:</label></div>
					<input class="cpt-input1" readonly="readonly" v-model="topicPermission" type="text"/>
				</div>

				<div class="popup-field1">
					<div class="label-block"><label>Topic example:</label></div>
					<input class="cpt-input1" readonly="readonly" v-model="topicPermissionExample" type="text" />
				</div>

			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: "ShadowMQTTView",
  props: {
    vhost: String,
    username: String,
    password: String,
  },
  data() {
    return {
      host: process.env.VUE_APP_SHADOW_MQTT_HOST,
      port: process.env.VUE_APP_SHADOW_MQTT_PORT,
      viewUsername: '',
      topicPermission: '',
      topicPermissionExample: '',
    }
  },
  methods: {
    clickClose() {
      this.$emit("clickClose");
    },
  },

  mounted: function() {
      this.viewUsername = this.vhost + ':' + this.username
      this.topicPermission = this.username + '/#'
      this.topicPermissionExample = this.username + '/abc'
  }
};
</script>

<style scoped>
/* Popup */
.connect-temp .popup {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.connect-temp .popup .popup-bg {
  background: black;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.4;
}

.connect-temp .popup .popup-content {
  background-color: #182130;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 36px 32px 35px 32px;
  color: #ffffff;
  min-width: 450px;
  border-radius: 1px;
}

.connect-temp .popup .popup-close {
  position: absolute;
  top: 36px;
  right: 32px;
  width: 22px;
  height: 22px;
  background: url("../../assets/icons/icon-close.png") no-repeat center center;
  cursor: pointer;
}

.connect-temp .popup.setting .popup-title1 {
  margin-bottom: 19px;
}

.popup-field1 .label-block {
	margin-bottom: 12px;
}

.popup-field1 label {
	opacity: 0.8;
	font-size: 14px;
	color: #cfd1d4;
}

.cpt-input1 {
	box-sizing: content-box;
	height: 39px;
	width: 94%;
	border-radius: 4px;
	border: 1px solid #333B47;
	background: #182130;
	color: #FFFFFF;
	padding: 0px 10px;
	font-size: 14px;
}
/* Popup */
</style>
