<!--
slot: 
      content       ===> 内容

回调:
      clickClose    ===> 关闭组件
      clickConfirm  ===> 点击确定按钮
-->
<template>
  <div v-if="show" class="connect-temp">
    <div class="popup setting">
      <div class="popup-bg" @click="clickClose" />
      <div class="popup-content">
        <div class="popup-close" @click="clickClose" />
        <div class="popup-title1">{{ title }}</div>
        <!-- slot: content -->
        <slot name="content"></slot>
        <!-- slot: content -->
        <div v-if="showIntervalSecOptions" class="update-asset-data-interval-sec-radio">
          <div class="label-block"><label>Data Pull:</label></div>
          <el-select :no-data-text="'No Data'" popper-class="custom-select" :style="{width: width}"
            v-model="update_asset_data_interval_sec" filterable placeholder="">
            <el-option v-for="item in intervalSecOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div v-if="showEmailNotify" class="email-notify">
          <div class="label-block"><label>Email me when disconnected:</label></div>
          <el-switch class="verify_login_switch" v-model="currentEmailNotifyValue" active-color="rgb(19, 206, 102)"
            inactive-color="rgb(136, 153, 170)"> </el-switch>
        </div>

        <div v-if="showOldProtocols" class="email-notify">
          <div class="label-block"><label>Compatible with old protocols:</label></div>
          <el-switch class="verify_login_switch" v-model="old_protocols_value" active-color="rgb(19, 206, 102)"
            inactive-color="rgb(136, 153, 170)"> </el-switch>
          <div v-show="old_protocols_value" class="popup-field1">
            <div class="label-block"><label>Gateway address:</label></div>
            <input class="cpt-input1" v-model="gateway_addr" type="text" name="inHealth-name" />
          </div>
        </div>

        <div class="popup-button-panel">
          <div class="confirm_box">
            <el-button type="primary" :loading="btnLoadding" @click="clickConfirm">{{confirmName}}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Setting',
    props: {
      // 弹出组件展示标题
      title: {
        type: String,
        default: 'Setting'
      },
      // 按钮显示文字
      confirmName: {
        type: String,
        default: 'Confirm'
      },
      // 是否显示组件
      show: {
        type: Boolean,
        default: false
      },
      // 按钮 loading 状态
      btnLoadding: {
        type: Boolean,
        default: false
      },
      // 下拉列表: 是否显示
      showIntervalSecOptions: {
        type: Boolean,
        default: true
      },
      // 下拉列表: 宽度
      width: {
        type: String,
        default: '170px'
      },
      // 下拉列表: 可选值
      intervalSecOptions: {
        type: Array,
        default: () => [{
          value: '0',
          label: 'No'
        }, {
          value: '30',
          label: 'Every 30s'
        }, {
          value: '60',
          label: 'Every 60s'
        }, {
          value: '300',
          label: 'Every 300s'
        }]
      },
      // 下拉列表: 默认值
      defaultIntervalSecValue: {
        type: String,
        default: null
      },

      // 连接断开时发邮件通知: 是否显示
      showEmailNotify: {
        type: Boolean,
        default: true
      },

      // 展示 兼容旧协议
      showOldProtocols: {
        type: Boolean,
        default: false
      },

      // 兼容旧协议 默认值
      oldProtocolsValue: {
        type: String,
        default: 'false'
      },

      // 兼容旧协议 Gateway Address
      gatewayAddr: {
        type: String,
        default: null
      },

      // 下拉列表: 默认值
      disconnectedEmailNotify: {
        type: String,
        default: 'false'
      },
    },
    data() {
      return {
        update_asset_data_interval_sec: this.defaultIntervalSecValue,
        gateway_addr: null,
        old_protocols_value: false,
        currentEmailNotifyValue: false,
      }
    },
    watch: {
      defaultIntervalSecValue(newVal) {
        // 默认值
        this.update_asset_data_interval_sec = newVal
      },
      update_asset_data_interval_sec(newVal) {
        // 使用 .sync 实现双向数据绑定
        this.$emit('update:defaultIntervalSecValue', newVal)
      },
      oldProtocolsValue(newVal) {
        // 默认值
        this.old_protocols_value = newVal === 'true' ? true : false
      },
      old_protocols_value(newVal) {
        // 使用 .sync 实现双向数据绑定
        this.$emit('update:oldProtocolsValue', newVal ? 'true' : 'false')
      },
      gatewayAddr(newVal) {
        // 默认值
        this.gateway_addr = newVal
      },
      gateway_addr(newVal) {
        // 使用 .sync 实现双向数据绑定
        this.$emit('update:gatewayAddr', newVal)
      },

      disconnectedEmailNotify(newVal) {
        if (newVal === 'true') {
          this.currentEmailNotifyValue = true
        } else if (newVal === 'false') {
          this.currentEmailNotifyValue = false
        }
      },
      currentEmailNotifyValue(newVal) {
        let value
        if (newVal) {
          value = 'true'
        } else {
          value = 'false'
        }
        // 使用 .sync 实现双向数据绑定
        this.$emit('update:disconnectedEmailNotify', value)
      }
    },
    methods: {
      clickConfirm() {
        this.$emit('clickConfirm')
      },
      clickClose() {
        this.$emit('clickClose')
      }
    },
  };
</script>

<style scoped>
  /* Popup */
  .connect-temp .popup {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    z-index: 100;
  }


  .connect-temp .popup .popup-bg {
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.4;
  }

  .popup-field1 .label-block {
    margin: 12px 0;
    color: #cfd1d4;
    opacity: 0.8;
  }

  .cpt-input1 {
    box-sizing: content-box;
    height: 39px;
    width: 94%;
    border-radius: 4px;
    border: 1px solid #333b47;
    background: #182130;
    color: #ffffff;
    padding: 0px 10px;
    font-size: 14px;
    outline: none;
  }


  .connect-temp .popup .popup-content {
    background-color: #182130;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    padding: 36px 32px 35px 32px;
    color: #FFFFFF;
    min-width: 354px;
    border-radius: 1px;
  }

  .connect-temp .popup .popup-close {
    position: absolute;
    top: 36px;
    right: 32px;
    width: 22px;
    height: 22px;
    background: url('../../assets/icons/icon-close.png') no-repeat center center;
    cursor: pointer;
  }

  .connect-temp .popup .popup-button-panel {
    text-align: right;
    padding-top: 13px;
  }

  .connect-temp .popup.setting .popup-title1 {
    margin-bottom: 19px;
  }

  .connect-temp .label-block label {
    opacity: 0.8;
    font-size: 14px;
    color: #cfd1d4;
  }

  .connect-temp .label-block {
    margin-bottom: 12px;
  }


  .connect-temp .popup-button-panel {
    text-align: right;
    display: flex;
    justify-content: right;
  }

  .connect-temp .update-asset-data-interval-sec-radio {
    margin-top: 12px;
  }

  .connect-temp .email-notify {
    margin-top: 12px;
  }

  /* justify-content: right;要生效，子元素必须为设置margin-left: auto; */
  .connect-temp .popup-button-panel .confirm_box {
    margin-left: auto;
  }


  ::v-deep.connect-temp .popup-button-panel .el-button--primary {
    width: 100px;
    height: 32px;
    background: #FCB900;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .update-asset-data-interval-sec-radio .el-input__inner {
    -webkit-appearance: none;
    background-color: #333B47;
    background-image: none;

    width: 174px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #333B47;

    box-sizing: border-box;
    color: #FFFFFF;
    display: inline-block;
    font-size: inherit;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  /* Popup */
</style>

<style>
  .custom-select {
    border: 1px solid #2C3443;
    border-radius: 0px;
    background: #1B2536;
    /* background: #1b3634; */
  }

  .custom-select .popper__arrow,
  .custom-select .popper__arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: none;
  }
</style>