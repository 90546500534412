<template>
  <div class="light-content" ref="lightContent">
    <div class="control">
      <span>Control</span>
      <el-switch v-if="!switchLoading" v-model="lightStatus" @change="controlLightSwitch" active-color="#08E708" inactive-color="#B8B8B8">
      </el-switch>
      <el-switch v-else v-model="lightLoadingStatus" class="loading" active-color="#08E708" inactive-color="#B8B8B8">
      </el-switch>
    </div>
    <div class="horizontal-divider"></div>
    <div class="brightness" :class="{ off: switchLoading ? lightStatus : !lightStatus }">
      <span>Brightness</span>
      <div @click="showSlider">
        {{ currentBrightness + "%" }}
        <img src="../../assets/Map-Tab/down.svg" alt="" />
      </div>
    </div>
    <div class="intensity" :class="{ off: switchLoading ? lightStatus : !lightStatus }">
      <span>Intensity</span>
      <div @click="showSlider">
        {{ currentIntensity + "K" }}
        <img src="../../assets/Map-Tab/down.svg" alt="" />
      </div>
    </div>
    <SliderDialog v-show="showSliderDialog" :shouldRerender="shouldRerender" :receivedBrightness="currentBrightness"
      :receivedIntensity="currentIntensity" :assetId="assetId" @notify="updateSelected"></SliderDialog>
  </div>
</template>

<script>
import { controlDevice } from "../../api.js";

import SliderDialog from "./SliderDialog.vue";
export default {
  props: {
    assetId: Number,
    deviceData: Object,
  },
  components: {
    SliderDialog,
  },
  data() {
    return {
      lightStatus: false, // 当前 switch 的开关状态
      lightLoadingStatus: true, // 当前 loading switch 的开关状态
      switchLoading: false, // switch 的 loading 状态
      showSliderDialog: false, // 是否显示亮度和湿度选择器对话框组件
      shouldRerender: false, // SliderDialog 组件是否需要重新渲染
      currentBrightness: 80, // 当前亮度
      currentIntensity: 2700, // 当前湿度
      isFirstAppend: true, // 亮度和湿度选择器对话框组件是否第一次被插入至指定位置, 默认 true
    };
  },
  methods: {
    showSlider() {
      if (this.lightStatus) {
        this.showSliderDialog = true;
        // 亦可尝试 teleport
        if (this.isFirstAppend) {
          const slider = document.querySelector(".slider-dialog-root");
          document.querySelector(".map-configure-root").append(slider);
        }
        this.isFirstAppend = false;
      }
    },
    updateSelected(e) {
      console.log("当前亮度和湿度", e);
      // 还原 showSliderDialog
      if (e.close) {
        this.showSliderDialog = false;
      }
      // 更新当前亮度和湿度
      this.currentBrightness = e.brightness;
      this.currentIntensity = e.intensity;
    },

    controlLightSwitch(newValue) {
      if (!newValue) {
        this.showSliderDialog = false;
      }
      else {
        this.shouldRerender = !this.shouldRerender;
      }
      this.switchLoading = true;

      // setTimeout(() => {
      //   this.lightLoadingStatus = newValue;
      //   this.switchLoading = false;
      //   this.$message.success({
      //     center: true,
      //     message: 'Success'
      //   });
      // }, 1000);
      
      controlDevice(this.assetId, {'value': newValue ? 100 : 0})
      .then((result) => {
        if (result.message === 'success') {
          // 两个开关状态同步为新状态
          this.lightStatus = newValue;
          this.lightLoadingStatus = newValue;
          this.$message.success({center: true, message: 'Success'});
        } else {
          // 两个开关状态同步为旧状态
          this.lightStatus = !newValue;
          this.lightLoadingStatus = !newValue;
          this.$message.error({center: true, message: "Failure"});
        }
      }).catch(() => {
        // 两个开关状态同步为旧状态
        this.lightStatus = !newValue;
        this.lightLoadingStatus = !newValue;
        this.$message.error({center: true, message: "Error"});
      }).finally(() => {
        this.switchLoading = false;
        // this.lightLoadingStatus = newValue;
        // this.switchLoading = false;
      })
    }
  },
  mounted: function () {
    console.log("light content deviceData", this.deviceData)
    if (this.deviceData && 'payload' in this.deviceData) {
      // TODO
      for (let payloadItem of this.deviceData.payload) {
        if (payloadItem.name == 'Status') {
          this.currentBrightness = payloadItem.value
          if (payloadItem.value > 0) {
            this.controlLightSwitch = true
          } else if (payloadItem.value === 0) {
            this.controlLightSwitch = false
          }
        }
      }
    }
  }
};
</script>

<style scoped>
/* switch loading 动画 */
/* Element UI: https://element.eleme.cn/#/en-US/component/button#loading-button */
/* Element Plus: https://element-plus.org/en-US/component/switch.html#loading */
/* rotating: https://github.com/ElemeFE/element/search?q=rotating */
::v-deep .el-switch.loading {
  opacity: 0.6;
}

::v-deep .el-switch.loading .el-switch__core:after {
  background-image: url(../../assets/Map-Tab/switch-loading.svg);
  animation: rotating 2s linear infinite;
}

.light-content {
  width: 301px;
  height: 195px;
  box-sizing: border-box;
  background: #3c4556;
  border-radius: 0px 0px 2px 2px;
  padding: 24px 20px 34px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.control span {
  color: #ffffff;
}

.horizontal-divider {
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: 24px;
  margin-bottom: 25px;
}

.brightness {
  margin-bottom: 22px;
}

.brightness,
.intensity {
  color: rgba(191, 195, 203, 1);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brightness.off,
.intensity.off {
  opacity: 0.35;
}

/* .brightness.off div,
.intensity.off div{
  cursor: not-allowed;
} */

.brightness div,
.intensity div {
  cursor: pointer;
}
</style>
