<template>
    <transition name="fade">
        <div class="filtered-asset-name-root">
            <div v-for="(item, index) in filteredAssetList" :key="index" @click="updateSelectedAssetName(item)"> {{
                    item.name
            }}
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        filteredAssetList: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        updateSelectedAssetName(asset) {
            this.$emit('notify', asset);
        },
    },
};
</script>

<style scoped>
/* 添加过渡动画效果 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.filtered-asset-name-root {
    position: absolute;
    top: calc(20px + 48px);
    left: 55px;
    padding-left: 26px;
    width: 302px;
    max-height: 448px;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: #101517;
    border: 1px solid #333B47;
}

.filtered-asset-name-root div {
    color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    font-family: PingFangSC-Regular, PingFang SC;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>