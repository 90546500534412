<template>
	<div class="popup-panel">
		<div class="popup cochl-setting popup-cochl-setting">
			<div class="popup-content">
				<div class="popup-field1">
					<div class="label-block"><label>Name:</label></div>
					<input class="cpt-input1" v-model="inputByValue.title" type="text" name="cochl-name" />
				</div>
				<div class="popup-field1">
					<div class="label-block"><label>Cochl key:</label></div>
					<input class="cpt-input1" v-model="inputByValue.api_key" type="password" name="cochl-password" />
				</div>
				<div class="popup-field2">
					<div class="label-block"><label>Cochl Services:</label></div>
					<el-select :no-data-text="'No Data'" popper-class="custom-select" v-model="inputByValue.service" filterable placeholder="">
						<el-option v-for="item in servicesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
					<!-- <input class="cpt-input1" v-model="inputByValue.services" name="cochl-services" /> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			inputByValueDefault: Object,
		},
		data() {
			return {
				inputByValue: {
					title: null,
					api_key: null,
					service: null,
				},
				servicesOptions: [{
					value: 'Emergency',
					label: 'Emergency Detection'
				}, {
					value: 'Human_Interaction',
					label: 'Human Interaction'
				}, {
					value: 'Human_Status',
					label: 'Human Status'
				}, {
					value: 'Home_Context',
					label: 'Home Context'
				}]
			}
		},
		watch: {
			// 单个监听
			'inputByValue.title': function () {
				this.$emit('inputByValue', 'inputByValueCochl', this.inputByValue)
			},
			'inputByValue.api_key': function () {
				this.$emit('inputByValue', 'inputByValueCochl', this.inputByValue)
			},
		},
		created: function () {
			// 输入框的值与父组件传进来的值关联
			// console.log('this.inputByValueDefault =D1> ', this.inputByValueDefault);
			this.inputByValue = this.inputByValueDefault
		},
	}
</script>

<style scoped>
	/* Popup */
	.popup {
		width: 207px;
		height: 350px;
		position: fixed;
	}

	.popup.cochl-setting .popup-field1 {
		margin-bottom: 15px;
	}

	.popup.cochl-setting .popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup.cochl-setting .popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup.cochl-setting .popup-field2 {
		margin-bottom: 15px;
	}

	.popup.cochl-setting .popup-field2 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup.cochl-setting .popup-field2 .label-block {
		margin-bottom: 12px;
	}


	/* Popup */
	::v-deep .popup-field1 .cpt-input1 {
		-webkit-appearance: none;
		background-color: #333B47;
		background-image: none;
		width: 100%;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333B47;
		box-sizing: border-box;
		color: #FFFFFF;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}
  ::v-deep .popup-field2 .el-input__inner {
    background-color: #333B47;
    background-image: none;
    border: 1px solid #333B47;
    color: #FFFFFF;
  }

</style>