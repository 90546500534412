<template>
  <!-- <div id="app"> -->
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <!-- </div> -->
  <router-view></router-view>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Dashboard from './pages/Dashboard.vue'

export default {
  name: 'App',
  components: {
    // Dashboard
  }
}
</script>

<style>
	@import './assets/styles/style.css';
</style>
