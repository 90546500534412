<template>
	<div>
		<!-- --------- -->
		<!--  Steps 1  -->
		<!-- --------- -->
		<ConnectInstancesDialog v-if="step == 'step1'" :show="step == 'step1'" :showIntervalSecOptions="false"  :confirmName="'Next'"
			:defaultIntervalSecValue.sync="update_asset_data_interval_sec" :disconnectedEmailNotify.sync="disconnectedEmailNotify" @clickClose="clickClose" @clickConfirm="popupNext">
			<template v-slot:content>
				<div class="popup-field1">
					<div class="label-block"><label>Name:</label></div>
					<input class="cpt-input1" v-model="connectInstanceVoName" type="text" name="salto-name" />
				</div>
				<div class="popup-field1">
					<div class="label-block"><label>Salto Username:</label></div>
					<input class="cpt-input1" v-model="connectInstanceVoAttrSalto_username" type="text" name="salto-username" />
				</div>
				<div class="popup-field1">
					<div class="label-block"><label>Salto Password:</label></div>
					<div class="popup-row">
						<div class="col-1">
							<input class="cpt-input1" v-model="connectInstanceVoAttrSalto_password" type="password"
								name="salto-password" />
						</div>
						<div class="col-2"></div>
						<div class="col-3">
							<el-button type="primary" :loading="testButtonLoadingStatus" @click="popupTest">Test</el-button>
						</div>
					</div>
				</div>
				<div class="message-panel">
					<!-- <span class="icon-error" v-bind:class="{show : show_message== 'popup-error'}">
								Denied
							</span> -->
					<span class="setting_icon_error" v-if="show_message== 'popup-error'">
						<img class="setting_icon_error_img" src="../assets/icons/icon-invalid@2x.png">
						Denied
					</span>
					<span class="setting_icon_success" v-if="show_message== 'popup-success'">
						<img class="setting_icon_success_img" src="../assets/icons/icon-success@2x.png">
						Pass
					</span>
				</div>
			</template>
		</ConnectInstancesDialog>
		<!-- --------- -->
		<!--  Steps 2  -->
		<!-- --------- -->
		<ConnectInstancesDialog v-if="step == 'step2'" :show="step == 'step2'" :btnLoadding="confirmButtonLoadingStatus" :showIntervalSecOptions="false"
			:showEmailNotify="false"
			@clickClose="clickClose" :confirmName="'Next'" @clickConfirm="popupConfirm2">
			<template v-slot:content>
				<div class="popup-field1">
					<div class="label-block"><label>Site:</label></div>
					<el-select :clearable="true" popper-class="custom-salto-select" style="width: 100%; overflow: hidden;"
						v-model="connectInstanceVoAttrSite" @change="onSiteSelected" filterable placeholder="Site">
						<div slot="empty">
							<p v-if="SiteOptions.length === 0" class="salto-el-select-no-data"> No Data </p>
						</div>
						<el-option v-for="item in SiteOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="popup-field1" :style="IQ_CREDENTIAL_list.length > 4 ? 'height:280px':''">
					<el-scrollbar ref="myScrollbar" style="height:100%">
						<div class="label-block"><label>IQ:</label></div>
						<div class="item-row" v-for="(iq, index) in IQ_CREDENTIAL_list" :key="index">
							<div class="col-1 pop_step_2_dropdown">
								<el-select :clearable="true" popper-class="custom-salto-select"
									v-model="iq.IQ" filterable placeholder="IQ">
									<div slot="empty">
										<p v-if="IQOptions.length === 0" class="salto-el-select-no-data"> No Data </p>
									</div>
									<el-option v-for="item in IQOptions" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="col-4">
								<input class="cpt-input1" style="width: 92px" v-model="iq.OTP" type="text"
									name="salto-otp" placeholder="OTP" />
							</div>
							<div class="col-2">
								<input style="width: 72px" class="cpt-input1" v-model="iq.PIN" type="text"
									name="salto-pin" placeholder="PIN" />
							</div>
							<div class="col-5">
								<el-button v-if="!iq.secret" type="primary" :loading="iq.loading" @click="testIQ(iq)">Get Secret</el-button>
								<div class="verified" v-if="iq.secret">{{iq.secret}}</div>
							</div>
							<div class="col-3" @click="removeIQ(index)">
								<button class="pin-button cpt-btn3" type="button">
									<span class="pin-text"></span>
								</button>
							</div>
						</div>
					</el-scrollbar>
				</div>

				<div class="popup-button-panel2">
					<button class="add-button cpt-btn2" type="button" @click="popupAdd">
						<span class="add-text">
							Add
						</span>
					</button>
				</div>

			</template>
		</ConnectInstancesDialog>

		<SelectDevices 
			v-if="step == 'step3'"
			:show="step == 'step3'"
			:connectInstanceVo="connectInstanceVo"
			:integrationDeviceList="integrationDeviceList"
			:selectedDeviceKeys="selectedDeviceKeys"
			@clickClose="clickClose"
			@selectDevicesComfirm="selectDevicesComfirm"
		> 
		</SelectDevices>
	</div>
</template>

<script>
	import {
		createConnectInstance,
		updateConnectInstance,
		getConnectInstanceVo,

		check_salto_credential,
		list_sites,
		list_IQs,
		get_salto_iq_secret
	} from '../api'

	import ConnectInstancesDialog from './template/ConnectInstancesDialog.vue'
	import SelectDevices from './SelectDevices.vue'

	export default {
		components: {
			ConnectInstancesDialog,
			SelectDevices,
		},
		name: 'SaltoSetting',
		props: {
			// connectId: Number,
			// connectDescription: String,
			// connectIconUrl: String,
			connect: Object,
			instance: Object,
			connectInstanceId: Number,
			isEdit: Boolean,
			isCreate: Boolean,
			// msg: String,
			// items: {
			// 	type: Array,
			// 	default: () => [{
			// 		id: 1,
			// 		message: ''
			// 	}, ],
			// },
		},
		emits: ['closeSetting'],
		data() {
			return {
				step: 'step1',
				// showStep1: true,
				// showStep2: false,
				show_message: '',
				// site_id: '905611e6-945a-476e-bce3-94899a069727',
				// IQOptions: [{value: 'iq1', label: 'IQ1'}, {value: 'iq2', label: 'IQ2'}, {value: 'iq3', label: 'IQ3'}],
				// IQValues: ['iq1','iq1'],
				// 选择框的选项
				IQOptions: [],
				IQValues: ['', ''],
				// SiteOptions: [{value: 's1', label: 's1'}, {value: 's2', label: 's2'}, {value: 's3', label: 's3'}],
				// SiteValue: 's1',
				// 选择框的选项
				SiteOptions: [],
				SiteValue: '',
				connectInstanceVoName: '',
				connectInstanceVoAttrSalto_username: '',
				connectInstanceVoAttrSalto_password: '',
				connectInstanceVoAttrSite: '',
				IQ_CREDENTIAL_list: [], // 用来，绑定选中的值
				connectInstanceVo: {
					instanceId: -1,
					connectId: -1,
					name: '',
					attr: {}
				},
				toNext: false,
				testButtonLoadingStatus: false,
				confirmButtonLoadingStatus: false,
				update_asset_data_interval_sec: '0',
				disconnectedEmailNotify: 'false',

				// showSelectDevice: false,
				integrationDeviceList: [],
				selectedDeviceKeys: [],
			}
		},
		methods: {
			createConectDetail() {
				this.show_message = '';
				this.isCreate = true
				// this.items.splice(1, this.items.length - 1)
				this.connectInstanceVoName = '',
					this.connectInstanceVoAttrSalto_username = '',
					this.connectInstanceVoAttrSalto_password = '',
					this.connectInstanceVoAttrSite = '',
					this.IQ_CREDENTIAL_list = [{
						IQ: '',
						PIN: '',
						OTP: ''
					}]
			},
			editConectDetail() {
				console.log('edit');
				console.log(this.connectInstanceId);
				this.show = 'popup-salto-setting';
				this.show_message = '';
				this.isEdit = true
				this.connectInstanceVoAttrSalto_password = ''
				// this.items.splice(1, this.items.length - 1)
				this.IQ_CREDENTIAL_list = []

				getConnectInstanceVo(this.connect.id, this.connectInstanceId).then(vo => {
					this.connectInstanceVoName = vo.name
					this.connectInstanceVoAttrSalto_username = vo.attr['salto_username']
					this.connectInstanceVoAttrSite = vo.attr['site_id']
					this.IQ_CREDENTIAL_list = JSON.parse(vo.attr['IQ_CREDENTIAL_list']).map(iq => {
						return {'IQ': iq.IQ, 'PIN': iq.PIN, 'OTP': '', 'secret': iq.secret}
					});
					this.update_asset_data_interval_sec = vo.attr['update_asset_data_interval_sec'] ?? this.update_asset_data_interval_sec;
					// if (this.IQ_CREDENTIAL_list.length > 1) {
					// 	for (let i = 1; i < this.IQ_CREDENTIAL_list.length; i++) {
					// 		this.items.push({
					// 			id: this.items.length + 1,
					// 			message: '',
					// 		});
					// 	}
					// }
				})

				this.disconnectedEmailNotify = this.instance.attr.disconnectedEmailNotify
			},
			clickClose() {
				this.$emit('closeSetting')
			},
			popupConfirmCancel() {
				this.show_message = '';
			},

			popupTest() {
				if (this.connectInstanceVoName !== '' && this.connectInstanceVoAttrSalto_username !== '' && this
					.connectInstanceVoAttrSalto_password !== '') {
					this.testButtonLoadingStatus = true
					check_salto_credential(this.connectInstanceVoAttrSalto_username, this.connectInstanceVoAttrSalto_password)
						.then(() => {
							this.show_message = 'popup-success'

							this.testButtonLoadingStatus = false
							console.log(this.show_message)

							this.toNext = true

						}).catch(() => {
							this.show_message = 'popup-error'
							this.toNext = false
							this.testButtonLoadingStatus = false
						})
				} else {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
				}

			},
			popupNext() {
				// 可以调用salto api打开
				if (this.toNext && this.update_asset_data_interval_sec) {
					this.show_message = '';
					this.getList_sites(this.connectInstanceVoAttrSalto_username, this.connectInstanceVoAttrSalto_password)
					if (this.connectInstanceVoAttrSite) {
						this.getList_IQs(this.connectInstanceVoAttrSalto_username, this.connectInstanceVoAttrSalto_password, this.connectInstanceVoAttrSite);
					}
					// this.getList_IQs(this.connectInstanceVoAttrSalto_username, this.connectInstanceVoAttrSalto_password)
					// this.showStep1 = false
					// this.showStep2 = true
					this.step = 'step2'
				} else {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
				}
				// this.show_message = '';
				// this.toNext = false
			},
			popupAdd() {
				// this.items.push({
				// 	id: this.items.length + 1,
				// 	message: '',
				// });
				this.IQ_CREDENTIAL_list.push({
					IQ: '',
					PIN: '',
					OTP: ''
				})
				// console.log(this.IQ_CREDENTIAL_list)
				// setTimeout(function(){ createCustomSelect(); }, 100);
			},
			removeIQ(index) {
				// this.items.splice(index, 1)
				// if (index != (this.items.length)) {
				// 	for (let i = index; i < this.items.length; i++) {
				// 		this.items[i].id = i + 1
				// 	}
				// }
				this.IQ_CREDENTIAL_list.splice(index, 1)
				// console.log(this.IQ_CREDENTIAL_list)
			},
			popupConfirm2() {
				this.confirmButtonLoadingStatus = true;
				let IQ_CREDENTIAL_list_is_complete = this.IQ_CREDENTIAL_list.every(iq => iq.IQ && iq.PIN  && iq.secret)
				// for (let i = 0; i < this.IQ_CREDENTIAL_list.length; i++) {
				// 	if (!(this.IQ_CREDENTIAL_list[i].IQ && this.IQ_CREDENTIAL_list[i].PIN  && this.IQ_CREDENTIAL_list[i].secret)) {
				// 		IQ_CREDENTIAL_list_is_complete = false
				// 		break
				// 	}
				// }
				if (this.connectInstanceVoAttrSite !== '' && IQ_CREDENTIAL_list_is_complete) {
					this.show_message = '';
					this.connectInstanceVo.connectId = this.connect.id
					this.connectInstanceVo.name = this.connectInstanceVoName
					this.connectInstanceVo.attr = {}
					this.connectInstanceVo.attr['salto_username'] = this.connectInstanceVoAttrSalto_username
					this.connectInstanceVo.attr['salto_password'] = this.connectInstanceVoAttrSalto_password
					this.connectInstanceVo.attr['site_id'] = this.connectInstanceVoAttrSite
					this.connectInstanceVo.attr['IQ_CREDENTIAL_list'] = JSON.stringify(this.IQ_CREDENTIAL_list.map(iq => {return {'IQ': iq.IQ, 'PIN': iq.PIN, 'secret': iq.secret}}));
					this.connectInstanceVo.attr['update_asset_data_interval_sec'] = this.update_asset_data_interval_sec;
					this.connectInstanceVo.attr['disconnectedEmailNotify'] = this.disconnectedEmailNotify
					console.log(this.connectInstanceVo.attr['IQ_CREDENTIAL_list'])
					if (this.isCreate) {
						createConnectInstance(this.connectInstanceVo)
							.then((res) => {
								this.step = 'step3'
								// this.showStep2 = false
								// this.showSelectDevice = true
								this.connectInstanceVo = res.connectInstanceVo
								this.integrationDeviceList = res.deviceList
							})
							.catch((error) => {
								if (error.response.data === 'over data points') {
									this.$message.error('over data points limit');
								} else if (error.response.data.code === 103) {
									this.$message.error({
										center: true,
										message: 'Duplicate name, please re-enter!'
									});
								} else {
									this.$message.error({
										center: true,
										message: 'Network Error!'
									});
								}
							})
							.finally(() => this.confirmButtonLoadingStatus = false)
					}
					if (this.isEdit) {
						updateConnectInstance(this.connectInstanceId, this.connectInstanceVo, true)
							.then((res) => {
								this.step = 'step3'
								// this.showStep2 = false
								// this.showSelectDevice = true
								this.connectInstanceVo = res.connectInstanceVo
								this.integrationDeviceList = res.deviceList
								this.selectedDeviceKeys = res.selectedDeviceKeys
							})
							.catch((error) => {
								if (error.response.data.code === 103) {
									this.$message.error({
										center: true,
										message: 'Duplicate name, please re-enter!'
									});
								} else {
									this.$message.error({
										center: true,
										message: 'Network Error!'
									});
								}
							})
							.finally(() => this.confirmButtonLoadingStatus = false)
					}
					// this.$emit('closeSetting')
				} else {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
					this.confirmButtonLoadingStatus = false
				}
			},

			selectDevicesComfirm() {
				this.$emit('closeSetting')
			},

			onSiteSelected(value) {
				this.getList_IQs(this.connectInstanceVoAttrSalto_username, this.connectInstanceVoAttrSalto_password, value);
			},
			getList_IQs(username, password, site) {
				if (site) {
					list_IQs(username, password, site).then(iqs => {
						this.IQOptions = iqs.map(iq => {
							return {
								'value': iq.id,
								'label': iq.customer_reference
							}
						})
					})
				}
			},
			getList_sites(username, password) {
				list_sites(username, password).then(list_sites => {
					// array.map: 实现对此操作一次赋值，如过对vue data{xxx} 进行多次赋值，vue会进行多次渲染
					this.SiteOptions = list_sites.map(site => {
						return {
							'value': site.id,
							'label': site.customer_reference
						}
					})
				})
			},
			testIQ(item) {
				console.log(item)
				this.$set(item, 'loading', true)
				get_salto_iq_secret(this.connectInstanceVoAttrSalto_username, this.connectInstanceVoAttrSalto_password, this.connectInstanceVoAttrSite, item.IQ, item.OTP).then(secret => {
						console.log('iq secret:', secret)
						this.$set(item, 'verified', true)
						this.$set(item, 'secret', secret)
				}).finally(() => {
					this.$set(item, 'loading', false)
				})
				// this.IQ_CREDENTIAL_list[index].verified = true
			}
		},
		mounted: function () {
			if (this.isCreate) {
				// console.log("this.createConectDetail()------------------------------>")
				this.createConectDetail()
			} else if (this.isEdit) {
				// console.log("this.editConectDetail()")
				this.editConectDetail()
			}
		},
		updated: function () {
			// console.log(this.IQs)
			// console.log(this.PINs)
			// console.log(this.IQValue)
			// console.log(this.connectInstanceVo)
			// console.log(this.items.length)
			// console.log(this.items)
			// console.log(this.items)
			// console.log(this.isCreate)
			// console.log(this.isEdit)
			// console.log(this.IQ_CREDENTIAL_list)
			// console.log('this.connect.id: ')
			// console.log(this.connect.id)
			// this.getConnectInstanceList()
			// console.log('this.isCreate')
			// console.log('this.isEdit')
			// console.log('this.connectInstanceId')
		}
	};
</script>

<style>
	.custom-salto-select {
		border: 1px solid #2C3443;
		border-radius: 0px;
		background: #1B2536;
		/* background: #1b3634; */
	}
</style>

<style scoped>
	.cpt-btn1 {
		background: #FCB900;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn1 span {
		font-weight: 500;
		color: #FFFFFF;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-btn2 {
		background: #212A3A;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn2 span {
		font-weight: 500;
		color: #999DA5;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-btn3 {
		background: #182130;
		border-radius: 2px;
		display: flex;
		padding: 0% 50% 35% 5%;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		display: inline;
		cursor: pointer;
	}

	/* .cpt-btn3 span {font-weight: 500; color: #999DA5; line-height: 15px; font-size: 15px;} */
	.cpt-btn3 span {
		width: 12px;
		height: 1.5px;
		background: #D8D8D8;
		display: inline-block;
		margin-top: 20px;
		text-align: center;
	}

	.cpt-connect-link2 {
		text-decoration: none;
	}

	.cpt-connect-link2 span {
		color: #FCB900;
	}

	.cpt-input1 {
		box-sizing: content-box;
		height: 39px;
		width: 94%;
		border-radius: 4px;
		border: 1px solid #333B47;
		background: #182130;
		color: #FFFFFF;
		padding: 0px 10px;
		font-size: 14px;
	}

	.setting_icon_success {
/*		display: none;*/
	}

	.setting_icon_success.show {
		display: block;
	}

	.setting_icon_success_img {
		vertical-align: middle;
		margin-right: 3px;
		width: 14px;
		height: 14px;
		margin-top: -1px
	}

	.setting_icon_error {
/*		display: none;*/
	}

	.setting_icon_error.show {
		display: block;
	}

	.setting_icon_error_img {
		vertical-align: middle;
		margin-right: 3px;
		width: 14px;
		height: 14px;
		margin-top: -1px
	}


	/* Popup */
	.popup {
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 100;
		display: none;
	}

	.popup.show {
		display: block;
	}

	.popup .popup-bg {
		background: black;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 1;
		opacity: 0.4;
	}

	.popup .popup-content {
		background-color: #182130;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		padding: 36px 32px 35px 32px;
		color: #FFFFFF;
	}

	.popup .popup-close {
		position: absolute;
		top: 36px;
		right: 32px;
		width: 22px;
		height: 22px;
		background: url('../assets/icons/icon-close.png') no-repeat center center;
		cursor: pointer;
	}

	.popup .popup-button-panel {
		text-align: right;
	}


	.popup-content {
		min-width: 354px;
		border-radius: 1px;
	}

	.popup-title1 {
		margin-bottom: 19px;
	}

	.popup-field1 {
		margin-bottom: 15px;
	}

	.popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
		color: #cfd1d4;
	}

	.popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup-field1 .popup-row {
		display: flex;
	}

	.popup-field1 .popup-row .col-1 {
		width: 75%;
	}

	.popup-field1 .popup-row .col-2 {
		width: 5%;
	}

	.popup-field1 .confirm-test-button {
		padding: 13px 23px;
	}

	.popup-button-panel {
		padding-top: 13px;
	}

	.popup.salto-setting2 .popup-content {
		width: 426px;
		height: auto;
		border-radius: 2px;
	}

	.popup.salto-setting2 .popup-title1 {
		margin-bottom: 19px;
	}

	.popup.salto-setting2 .popup-field1 {
		margin-bottom: 15px;
	}

	.popup.salto-setting2 .popup-field1 label {
		opacity: 0.8;
		font-size: 14px;
	}

	.popup.salto-setting2 .popup-field1 .label-block {
		margin-bottom: 12px;
	}

	.popup.salto-setting2 .popup-field1 .confirm-test-button {
		padding: 13px 23px;
	}

	.popup.salto-setting2 .popup-button-panel {
		padding-top: 13px;
		display: flex;
		justify-content: right;
	}

	.popup.salto-setting2 .popup-button-panel .confirm_box {
		margin-left: auto;
	}

	.popup-field1 .item-row {
		display: flex;
		margin-bottom: 7px;
	}

	.popup-field1 .item-row .col-1 {
		width: 154px;
	}

	.popup-field1 .item-row .col-2 {
		padding-right: 10px;
	}

	.popup-field1 .item-row .col-3 {
		width: 15%;
		margin-right: 30px;
	}

	.popup-field1 .item-row .col-4 {
		padding: 0 10px;
	}

	.popup-field1 .item-row .col-5 {
		margin: auto;
		margin-right: 10px;
	}

	.popup-field1 .item-row .col-5 .verified {
		width: 160px;
/*		text-align: center;*/
/*		color: green;*/
	}

	.popup-field1 .item-row .col-2 .cpt-btn2,
	.popup-field1 .item-row .col-2 .cpt-btn3 {
		line-height: 25px;
	}

	/* Popup */

	/* The container must be positioned relative: */
	.connect-brand-image1 img {
		width: 100%;
		height: 100%;
	}

	/* .icon-error {
	width: 7px;
	height: 7px;
	margin-right: 3px;
} */


	.custom-select {
		position: relative;
		font-family: Arial;
	}

	.custom-select select {
		display: none;
		/*hide original SELECT element: */
	}

	.select-selected {
		background-color: #182130;
		border-radius: 4px;
		border: 1px solid #333B47;
	}

	/* Style the arrow inside the select element: */
	.select-selected:after {
		position: absolute;
		content: "";
		top: 17px;
		right: 10px;
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-color: #D1D3D6 transparent transparent transparent;
	}

	/* Point the arrow upwards when the select box is open (active): */
	.select-selected.select-arrow-active:after {
		border-color: transparent transparent #fff transparent;
		top: 7px;
	}

	/* style the items (options), including the selected item: */
	.select-items div,
	.select-selected {
		color: #D1D3D6;
		/*color: #ffffff;*/
		padding: 8px 16px;
		/*border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;*/
		cursor: pointer;
	}

	/* Style items (options): */
	.select-items {
		position: absolute;
		/*background-color: DodgerBlue;*/
		background-color: #1B2536;
		border: 1px solid #2C3443;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 99;
		margin-top: 7px;
	}

	/* Hide the items when the select box is closed: */
	.select-hide {
		display: none;
	}

	.select-items div:hover,
	.same-as-selected {
		background-color: rgba(0, 0, 0, 0.1);
	}

	::v-deep .el-scrollbar__wrap {
		overflow: scroll;
		width: 110%;
		height: 110%;
	}

	::v-deep .popup-field1 .el-input__inner {
		-webkit-appearance: none;
		background-color: #333B47;
		background-image: none;

		width: 400px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333B47;

		box-sizing: border-box;
		color: #FFFFFF;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	::v-deep .popup-field1 .el-select-dropdown__item {
		font-size: 14px;
		padding: 0 20px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #FFFFFF;
		height: 34px;
		line-height: 34px;
		box-sizing: border-box;
		cursor: pointer;
	}

	::v-deep .col-1 .el-input__inner {
		-webkit-appearance: none;
		background-color: #333B47;
		background-image: none;

		width: 174px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333B47;

		box-sizing: border-box;
		color: #FFFFFF;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	::v-deep .col-1 .el-select-dropdown__item {
		font-size: 14px;
		padding: 0 20px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #FFFFFF;
		height: 34px;
		line-height: 34px;
		box-sizing: border-box;
		cursor: pointer;
	}

	::v-deep .col-3 .el-button--primary {
		display: flex;
		justify-content: center;
		color: #FFF;
		background-color: #212A3A;
		margin-left: 4px;
		width: 67px;
		height: 40px;
		border: none;
	}

	::v-deep .col-3 .el-button--primary span {
		margin-top: 2px;
	}

	::v-deep .popup-button-panel .el-button--primary {
		width: 100px;
		height: 32px;
		background: #FCB900;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.salto-el-select-no-data {
		padding: 10px 0;
		margin: 0;
		text-align: center;
		color: #999;
		font-size: 14px;
	}
</style>

<style>
	.custom-salto-select .el-select-dropdown__list {
		list-style: none;
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		background: #1B2536;
	}

	.custom-salto-select.el-popper .popper__arrow,
	.custom-salto-select.el-popper .popper__arrow::after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: none;
	}
</style>