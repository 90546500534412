<template>
	<div>
		<div class="container page-shadow-detail">
			<!-- <div class="breadcrumb">
				Shadow / Detail
			</div> -->
			<div class="breadcrumb">
				<button class="go-shadow-button" type="button" @click="$emit('goShadow')">
					<span class="go-shadow-button-text"> Shadow </span>
				</button>
				<span> / Detail</span>
			</div>

			<div class="shadow-brand-box">
				<div class="col-1" v-if="['mqtt'].indexOf(shadow.type) === -1">
					<div class="shadow-brand-image1"><img :src="imageMap[shadow.type]"></div>
				</div>
				<div class="col-2">
					<div class="shadow-brand-title1">{{ shadow.title }}</div>
					<div class="shadow-brand-title2">{{ shadow.description }}</div>
				</div>
			</div>

			<div class="shadow-button-panel">
				<button class="add-button cpt-btn1" type="button" @click="createShadowDetail">
					<span class="add-text">
						Create
					</span>
				</button>
			</div>

			<!-- 里面的内容做成组件的形式 -->
			<div v-loading="loading" element-loading-background="rgba(24, 33, 48, 0.8)" class="shadow-detail-list-box">
				<div class="shadow-detail-list-header">
					<div class="header-col col-1 padding1">
						<span>ID</span>
					</div>
					<div class="header-col col-2 padding1"><span>Name</span></div>
					<!-- <div class="header-col col-3 padding1"><span>Status</span></div> -->
					<div class="header-col col-4 padding1"><span>Operation</span></div>
				</div>
				<div class="shadow-detail-list-header-line"></div>

				<div class="shadow-detail-list-body">
					<!-- loop shadow-detail for row -->
					<div class="shadow-detail-row" v-for="item in dataList" :key="item.id">
						<div class="body-col col-1 padding1">
							<div>
								{{ item.id }}
							</div>
						</div>
						<div class="body-col col-2 padding1">
							<div>
								{{ item.title }}
							</div>
						</div>
						<!-- <div class="body-col col-3 padding1">
							<div>
								{{ item.status }}
							</div>
						</div> -->
						<div class="body-col col-4 padding1" v-if="['mqtt', 'websocket'].indexOf(shadow.type) === -1">
							<div>
								<div class="shadow-detail-button-panel">
									<a href="#" class="cpt-shadow-link2 qrcode-button" @click="getShadowQrcode(item)">
										<span>
											QR Code
										</span>
									</a>
									|
									<a href="#" class="cpt-shadow-link2 edit-button" @click="editShadowDetail(item)">
										<span>
											Edit
										</span>
									</a>
									|
									<a href="#" class="cpt-shadow-link2 delete-button" @click="deleteShadowDetail(item)">
										<span>
											Delete
										</span>
									</a>
								</div>
							</div>
						</div>

						<div class="body-col col-4 padding1" v-else>
							<div>
								<div class="shadow-detail-button-panel">
									<a href="#" class="cpt-shadow-link2 qrcode-button" @click="showView(item)">
										<span>
											View
										</span>
									</a>
									|
									<a href="#" class="cpt-shadow-link2 delete-button" @click="deleteShadowDetail(item)">
										<span>
											Delete
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>

					<!-- loop shadow-detail for row -->
				</div>

			</div>

		</div>

		<div class="popup-panel">
			<!-- Delete -->
			<div class="popup delete-shadow-detail" :class="{show : show == 'popup-delete'}">
				<div class="popup-bg"></div>
				<div class="popup-content">
					<div class="popup-delete-text">Delete?</div>
					<div class="popup-button-panel">
						<button class="cancel-delete-button cpt-btn2" type="button" @click="popupConfirmCancel">
							<span class="cancel-delete-text">
								Cancel
							</span>
						</button>

						<div class="confirm_delete_box">
							<el-button type="primary" :loading="confirmloading" @click="popupConfirmDelete">Confirm
							</el-button>
						</div>
					</div>
				</div>
			</div>
			<!-- Delete -->

			<shadow-setting v-if="show_shadow_setting" :shadow="shadow" :editShadowData="editShadowData" :isCreate="isCreate"
				:isEdit="isEdit" @closeSetting="closeSetting($event)"></shadow-setting>

			<ShadowMQTTView 
			@clickClose="closeView"
			v-if="showDialog && shadow.type === 'mqtt'"
			:vhost="currentShadowInstance.data.vhost"
			:username="currentShadowInstance.data.mqtt_username"
			:password="currentShadowInstance.data.mqtt_password"
			>
			</ShadowMQTTView>

			<ShadowWebsocketView 
			@clickClose="closeView" 
			v-if="showDialog && shadow.type === 'websocket'" 
			:instance="currentShadowInstance"
			>
			</ShadowWebsocketView>
		</div>

	</div>

</template>

<script>
	import {
		getVirtualControllerList,
		deleteVirtualController,
		getShadowQrcode,
		getMqttShadowInstances,
		deleteMqttShadowInstance,
		getWebsocketShadowInstances,
		deleteWebsocketShadowInstance,
	} from '../api'

	import fileDownload from 'js-file-download'

	import ShadowSetting from './ShadowSetting.vue';
	import ShadowMQTTView from '../components/ShadowControl/ShadowMQTTView.vue'
	import ShadowWebsocketView from '../components/ShadowControl/websocket/ShadowWebsocketView.vue'

	import shadow_show_btn1 from '../assets/icons/vcontroller-show-btn1.png';
	import shadow_show_btn2 from '../assets/icons/vcontroller-show-btn2.png';
	import shadow_show_btn3 from '../assets/icons/vcontroller-show-btn3.png';
	import shadow_show_btn4 from '../assets/icons/vcontroller-show-btn4.png';
	import shadow_show_dimming1 from '../assets/icons/vcontroller-show-dimming1.png';
	import shadow_show_chchl from '../assets/icons/vcontroller-show-cochl.png';
	import shadow_websocket from '../assets/icons/shadow-websocket-200X144.png';
	import shadow_show_ac_control from '../assets/icons/vcontroller-show-ac-control.png';

	const imageMap = {
		'button1': shadow_show_btn1,
		'button2': shadow_show_btn2,
		'button3': shadow_show_btn3,
		'button4': shadow_show_btn4,
		'dimming1': shadow_show_dimming1,
		'cochl': shadow_show_chchl,
		'websocket': shadow_websocket,
		'accontrol': shadow_show_ac_control,
	}

	export default {

		components: {
			ShadowSetting,
			ShadowMQTTView,
			ShadowWebsocketView,
		},
		name: 'ShadowDetail',
		props: {
			shadow: Object,
		},
		emits: ['goShadow'],
		data() {
			return {
				loading: false,
				isEdit: false,
				isCreate: false,
				// isDelete: false,
				show: '',
				show_shadow_setting: false,
				imageMap: imageMap,
				dataList: null,
				currentShadowInstance: null,
				editShadowData: null,
				confirmloading: false,

				showDialog: false,
			}
		},
		methods: {
			closeSetting(onlyClose) {
				this.isCreate = false
				// this.isDelete = false
				this.isEdit = false
				this.show_shadow_setting = false
				if (!onlyClose) {
					this.getShadowInstances()
				}
			},

			showView(item) {
				this.currentShadowInstance = item
				this.showDialog = true
			},
			closeView() {
				this.currentShadowInstance = null
				this.showDialog = false
			},

			editShadowDetail(item) {
				this.editShadowData = item
				this.isEdit = true
				this.isCreate = false
				this.show_shadow_setting = true
			},
			createShadowDetail() {
				this.isCreate = true
				this.isEdit = false
				this.show_shadow_setting = true
			},
			getShadowQrcode(item) {
				getShadowQrcode(item.id,item.title).then((res) => {
					fileDownload(res, item.title + '-' + item.id + '.png')
				}).catch(err => {
					console.log(err);
					this.$message.error({
						center: true,
						message: 'Network Error!'
					});
				}).finally(() => {})
			},
			deleteShadowDetail(item) {
				this.show = 'popup-delete';
				this.currentShadowInstance = item
			},
			popupConfirmDelete() {
				this.confirmloading = true

				let promise
				if (this.shadow.type === 'mqtt') {
					promise = deleteMqttShadowInstance(this.currentShadowInstance.id, this.currentShadowInstance.data.mqtt_username)
				} else if (this.shadow.type === 'websocket') {
					promise = deleteWebsocketShadowInstance(this.currentShadowInstance.id)
				} else {
					promise = deleteVirtualController(this.currentShadowInstance.id)
				}

				promise.then(res => {
					console.log(res);
				}).catch(err => {
					console.log(err);
					this.$message.error({
						center: true,
						message: 'Network Error!'
					});
				}).finally(() => {
					this.confirmloading = false
					this.getShadowInstances()
					this.show = '';
				})
			},
			popupConfirmCancel() {
				this.show = '';
			},

			getShadowInstances() {
				this.loading = true

				let promise
				if (this.shadow.type === 'mqtt') {
					promise = getMqttShadowInstances()
				} else if (this.shadow.type === 'websocket') {
					promise = getWebsocketShadowInstances()
				} else {
					promise = getVirtualControllerList(this.shadow.type)
				}

				promise.then(res => {
					// console.log(res);
					this.dataList = res
				}).catch(err => {
					console.log(err);
					this.$message.error({
						center: true,
						message: 'Network Error!'
					});
				}).finally(() => {
					this.loading = false
				})
			},
		},

		mounted: function () {
			this.getShadowInstances()
		},
		updated: function () {}
	};
</script>

<style scoped>
	.breadcrumb {
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		line-height: 22px;
		margin-top: 12px;
		margin-bottom: 23px;
	}

	.cpt-btn1 {
		background: #fcb900;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn1 span {
		font-weight: 500;
		color: #ffffff;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-btn2 {
		background: #212a3a;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn2 span {
		font-weight: 500;
		color: #999da5;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-btn3 {
		background: #182130;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
	}

	.cpt-btn3 span {
		font-weight: 500;
		color: #999da5;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-shadow-link2 {
		text-decoration: none;
	}

	.cpt-shadow-link2 span {
		color: #fcb900;
	}

	.cpt-input1 {
		box-sizing: content-box;
		height: 39px;
		width: 94%;
		border-radius: 4px;
		border: 1px solid #333b47;
		background: #182130;
		color: #ffffff;
		padding: 0px 10px;
	}

	/* Shadow Detail */

	.page-shadow-detail .shadow-brand-box {
		background: #182130;
		border-radius: 2px;
		padding: 22px;
		display: flex;
	}

	.page-shadow-detail .shadow-brand-image1 {
		width: 84px;
		height: 144px;
		background-color: #FFFFFF;
		margin-right: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.page-shadow-detail .shadow-brand-title1 {
		font-size: 18px;
		font-weight: 500;
		color: #ffffff;
		line-height: 22px;
		margin-top: 10px;
		margin-bottom: 11px;
	}

	.page-shadow-detail .shadow-brand-title2 {
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		line-height: 27px;
		opacity: 0.4;
	}

	.page-shadow-detail .shadow-button-panel {
		margin-top: 34px;
		margin-bottom: 26px;
	}

	.page-shadow-detail .shadow-detail-list-box {
		background: #182130;
		border-radius: 2px;
		font-size: 13px;
	}

	/* .page-shadow-detail #shadow-detail-list-header {overflow: hidden; color:#DDDDDD; padding:10px 30px;} */
	.page-shadow-detail .shadow-detail-list-header {
		overflow: hidden;
		color: #dddddd;
		padding: 10px 0px;
	}

	.page-shadow-detail .shadow-detail-list-header-line {
		border-bottom: 1px solid #ffffff;
		opacity: 0.04;
	}

	.page-shadow-detail .shadow-detail-list-header .header-col {
		float: left;
	}

	.page-shadow-detail .shadow-detail-list-box .col-1 {
		margin-left: 35px;
		width: 280px;
	}

	.page-shadow-detail .shadow-detail-list-box .col-2 {
		margin-left: 13.73px;
		width: 160px;
	}

	.page-shadow-detail .shadow-detail-list-box .col-3 {
		margin-left: 37.31px;
		width: 120px;
	}

	.page-shadow-detail .shadow-detail-list-box .col-4 {
		margin-left: 38.42px;
		width: 200;
	}

	/* .page-shadow-detail #shadow-detail-list-box .padding1 {padding: 10px 5px;} */
	.page-shadow-detail .shadow-detail-list-box .padding1 {
		padding: 10px 0px;
	}

	/* .page-shadow-detail #shadow-detail-list-body {overflow: hidden; color:#FFFFFF; padding:0px 30px 10px;} */
	.page-shadow-detail .shadow-detail-list-body {
		overflow: hidden;
		color: #ffffff;
		padding: 0px 0px 10px;
	}

	.page-shadow-detail .shadow-detail-list-body .body-col {
		float: left;
		display: flex;
	}

	.page-shadow-detail .shadow-detail-list-body .col-1,
	.page-shadow-detail .shadow-detail-list-body .col-2,
	.page-shadow-detail .shadow-detail-list-body .col-3 {
		opacity: 0.49;
	}

	.page-shadow-detail .shadow-detail-list-body .shadow-detail-row {
		padding: 3px 0px;
		overflow: hidden;
	}

	.page-shadow-detail .shadow-detail-list-body .shadow-detail-button-panel {
		color: #fcb900;
	}

	.page-shadow-detail .cpt-shadow-link2.qrcode-button {
		margin-right: 20px;
	}

	.page-shadow-detail .cpt-shadow-link2.edit-button {
		margin: 0 20px;
	}

	.page-shadow-detail .cpt-shadow-link2.delete-button {
		margin-left: 20px;
	}

	/* Shadow Detail */

	.popup {
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 100;
		display: none;
	}

	.popup.show {
		display: block;
	}

	.popup .popup-bg {
		background: black;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;
		z-index: 1;
		opacity: 0.4;
	}

	.popup .popup-content {
		background-color: #182130;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		padding: 36px 32px 35px 32px;
		color: #ffffff;
	}

	.popup .popup-close {
		position: absolute;
		top: 36px;
		right: 32px;
		width: 22px;
		height: 22px;
		background: url("../assets/icons/icon-close.png") no-repeat center center;
		cursor: pointer;
	}

	.popup .popup-button-panel {
		text-align: right;
	}

	.popup.delete-shadow-detail .popup-content {
		min-width: 354px;
	}

	.popup.delete-shadow-detail .popup-delete-text {
		margin-bottom: 37px;
	}

	.popup.delete-shadow-detail .cancel-delete-button {
		margin-right: 18px;
	}

	/* The container must be positioned relative: */
	.shadow-brand-image1 img {
		width: 100%;
		max-width: 100%;
		max-height: 100%;
	}

	.show {
		display: block;
	}

	.go-shadow-button {
		background: #212a3a;
		padding: 0px 0px;
		border-width: 0px;
		cursor: pointer;
	}

	.go-shadow-button-text {
		font-size: 14px;
		color: #fcb900;
	}

	.custom-select {
		position: relative;
		font-family: Arial;
	}

	.custom-select select {
		display: none;
		/*hide original SELECT element: */
	}

	.select-selected {
		background-color: #182130;
		border-radius: 4px;
		border: 1px solid #333b47;
	}

	/* Style the arrow inside the select element: */
	.select-selected:after {
		position: absolute;
		content: "";
		top: 17px;
		right: 10px;
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-color: #d1d3d6 transparent transparent transparent;
	}

	/* Point the arrow upwards when the select box is open (active): */
	.select-selected.select-arrow-active:after {
		border-color: transparent transparent #fff transparent;
		top: 7px;
	}

	/* style the items (options), including the selected item: */
	.select-items div,
	.select-selected {
		color: #d1d3d6;
		/*color: #ffffff;*/
		padding: 8px 16px;
		/*border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;*/
		cursor: pointer;
	}

	/* Style items (options): */
	.select-items {
		position: absolute;
		/*background-color: DodgerBlue;*/
		background-color: #1b2536;
		border: 1px solid #2c3443;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 99;
		margin-top: 7px;
	}

	/* Hide the items when the select box is closed: */
	.select-hide {
		display: none;
	}

	.select-items div:hover,
	.same-as-selected {
		background-color: rgba(0, 0, 0, 0.1);
	}

	/* #pop_step_2_dropdown {
	display: flex;
	justify-content: center;
	align-items: center;
} */

	::v-deep .popup-field1 .el-input__inner {
		-webkit-appearance: none;
		background-color: #333b47;
		background-image: none;

		width: 425px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333b47;

		box-sizing: border-box;
		color: #ffffff;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	::v-deep .popup-field1 .el-select-dropdown__item {
		font-size: 14px;
		padding: 0 20px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #ffffff;
		height: 34px;
		line-height: 34px;
		box-sizing: border-box;
		cursor: pointer;
	}

	::v-deep .col-1 .el-input__inner {
		-webkit-appearance: none;
		background-color: #333b47;
		background-image: none;

		width: 154px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333b47;

		box-sizing: border-box;
		color: #ffffff;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	::v-deep .col-1 .el-select-dropdown__item {
		font-size: 14px;
		padding: 0 20px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #ffffff;
		height: 34px;
		line-height: 34px;
		box-sizing: border-box;
		cursor: pointer;
	}

	::v-deep .col-3 .el-button--primary {
		color: #fff;
		background-color: #212a3a;
		/* margin-left: 3px; */
		border: none;

		width: 67px;
	}

	::v-deep .col-3 .el-button--primary {
		display: flex;
		justify-content: center;
		color: #fff;
		background-color: #212a3a;
		margin-left: 4px;
	}

	::v-deep .el-button--primary {
		width: 80px;
		height: 30px;
		background: #fcb900;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.confirm_delete_box {
		display: inline-block;
	}
</style>