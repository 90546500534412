<template>
    <transition name="transition">
        <div class="container" v-if="visible">
            <div class="icon_box">
                <!-- <img class="warning_icon" v-if="type === 'info'" src="../../assets/icons/warning-Icon@2x.png"> -->
                <img class="warning_icon" v-if="type === 'warning'" src="../../assets/icons/warning-Icon@2x.png">
                <!-- <img class="warning_icon" v-if="type === 'error'" src="../../assets/icons/warning-Icon@2x.png"> -->
            </div>
            <div class="text_box"> {{message}} </div>
        </div>
    </transition>
</template>

<script>


export default {
    name: 'CustomMessage',

    data(){ 
        return {
            visible: false,
            type: "info",
            message: "",
            duration: 2000,
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >

    .container {
        width: 460px;
        min-height: 64px;
        background: #394457;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
        border-radius: 4px;

        position: fixed;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        /* align-items: center; */
        margin: 0 auto;
        color: #fff;
    }

    /* 动画进入开始时状态 */
    .transition-enter {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
    .transition-enter-to {
        transform: translate(-50%, 0%);
    }
    /* 动画进入过程动画效果 */
    .transition-enter-active {
        transition: all 0.4s ease;
    }
    /* 动画离开开始时状态 */
    .transition-leave {
        transform: translateY(0%);
    }
    /* 动画结束时动画 */
    .transition-leave-to {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
    /* 动画离开过程动画效果 */
    .transition-leave-active {
        transition: all 0.4s ease;
    }

    .icon_box {
        position: relative;
        width: 43px;
        min-height: 64px;
    }
    .text_box {
        position: relative;
        margin-top: 9px;
        margin-right: 15px;
        width: 402px;
        min-height: 46px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
    }
    .warning_icon {
        position: relative;
        width: 14px;
        height: 14px;
        margin-left: 16px;
        margin-top: 13px;
    }

</style>
