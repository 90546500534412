<template>
    <div class="page-background">

        <div class="request-resetpassword-outside-box">

            <div class="request-resetpassword-inside-box">

                    <div class="request-password-reset-text">Request Password Reset</div>

                    <div class="email-text">Email：</div>
                    <input class="email-input" type="text" name="eamil" v-model="email"/>

                    <button class="submit-button" type="button" @click="submitClick">
                        <span class="submit-text">
                            Submit
                        </span>
                    </button>

            </div>

        </div>

    </div>
</template>

<script>
import {resetPasswordRequest} from '../api'
export default {
	name: 'RequestResetPassword',
	props: {
		msg: String
	},
    data(){
        return {
            email: this.$route.params.defaultEmail
        }
    },
    methods : {
		submitClick: function() {
            if(this.email !== ''){
                resetPasswordRequest(this.email).finally(() => {
                    // console.log("resetPasswordCode------------------------------------------->")
                    this.$router.push({ 'name': 'resetPassword', params: {email: this.email}}).catch(err => (err.log))
                })
            }else{
                this.$message.error({center: true, message: 'Please enter the complete information!'})
            }
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
    /* Request Reset Password Page */
    .request-resetpassword-outside-box {transform: translate(-50%, -50%);}
    .request-resetpassword-outside-box input {padding:0px 10px;}
    .request-resetpassword-outside-box .email-input {width:289px; font-size: 14px;}
    /* Request Reset Password Page */



    .page-background {
        box-sizing: content-box;
        z-index: -2;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, #212A3A 0%, #182130 100%);
    }


    .request-resetpassword-outside-box {
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        /*margin-left: -210px;
        margin-top: -191px;*/
        width: 420px;
        height: 282px;
        background: #182130;
        box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
    }

    .request-resetpassword-inside-box {
        z-index: 0;
        position: absolute;
        margin-left: 55px;
        margin-top: 41px;
        margin-right: 55px;
        margin-bottom: 42px;
        width: 310px;
        height: 199px;
    }

    .request-password-reset-text {
        z-index: 1;
        margin-left: 22px;

        text-align: center;

        width: 266px;
        height: 28px;
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        text-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
    }

    .email-text {
        z-index: 1;
        margin-top: 31px;
        width: 144px;
        height: 14px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cfd1d4;
        line-height: 13px;
        text-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
    }

    .email-input {
        z-index: 1;
        margin-top: 10px;
        box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    input {
        background: #182130;  /*设置输入框背景色*/
        padding: 0px 0px;
        width: 309px;
        height: 39px;
        border: 1px solid #333B47;
    }

    .submit-button {
        z-index: 1;
        margin-top: 32px;

        width: 310px;
        height: 44px;
        background: #FCB900;
        box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    .submit-text {
        width: 108px;
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
        text-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
    }

    

    .login-box-img-box {
        z-index: 0;
        position: absolute;
        width: 542px;
        height: 460px;
    }
    .login-box-img-box-img {
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -219px;
        margin-top: -219px;
        max-width: 438px;
        max-height: 438px;
    }



    .login-box-login {
        z-index: 0;
        position: absolute;
        right: 0%;
        bottom: 0%;
        width: 378px;
        height: 460px;
        background: #182130;
        border-radius: 2px;
    }

    

    .logo-app_name-box {
        z-index: 1;
        position: absolute;
        margin-left: 34px;
        margin-top: 40px;
        margin-right: 34px;
        margin-bottom: 382px;
        width: 310px;
        height: 38px;
    }

    .login-box-login-logo {
        z-index: 2;
        position: absolute;
        left: 61px;
        top: 0px;
        width: 38px;
        height: 38px;
    }

    .login-box-login-app-name {
        z-index: 2;
        position: absolute;
        margin-left: 105px;
        margin-top: 5px;
        margin-right: 19px;
        margin-bottom: 5px;
        width: 142px;
        height: 26px;
        font-size: 19px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 26px;
    }


    .login_text-to-signup_hyperlink-box {
        z-index: 1;
        position: absolute;
        margin-left: 34px;
        margin-top: 108px;
        margin-right: 78px;
        margin-bottom: 22px;
        width: 310px;
        height: 330px;
    }


    .login-text {
        z-index: 2;
        width: 266px;
        height: 28px;
        font-size: 19px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }

    .login-form-username_or_email-text {
        z-index: 2;
        margin-top: 29px;
        width: 144px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }

    .login-form-username_or_email-input {
        box-sizing: content-box;
        z-index: 2;
        margin-top: 12px;
        /* width: 310px;
        height: 40px; */
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #333B47;
    }

    .login-form-password-text {
        z-index: 2;
        margin-top: 13px;
        width: 104px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }
    input {
        background: #182130;  /*设置输入框背景色*/
        padding: 0px 0px;
        color: #FFFFFF;
    }

    .login-form-password-input {
        box-sizing: content-box;
        z-index: 2;
        margin-top: 12px;
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #333B47;
    }

    a {
        text-decoration: none;
    }

    .forget-password-box {
        z-index: 2;
        margin-top: 14px;
        width: 310px;
        height: 16px;

        text-align: right;
    }
    .forget-password {
        z-index: 3;
        width: 141px;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    .sign-up-box {
        z-index: 2;

        text-align: center;
    }
    .sign-up {
        z-index: 3;
        width: 51px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    .confirm-button {
        z-index: 2;
        margin-top: 19px;
        width: 310px;
        height: 44px;
        background: #FCB900;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    .confirm-text {
        width: 108px;
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
    }

    body {
    display: block;
    margin: 0px;
    }
</style>
