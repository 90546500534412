import { render, staticRenderFns } from "./ShadowDetail.vue?vue&type=template&id=123677ff&scoped=true"
import script from "./ShadowDetail.vue?vue&type=script&lang=js"
export * from "./ShadowDetail.vue?vue&type=script&lang=js"
import style0 from "./ShadowDetail.vue?vue&type=style&index=0&id=123677ff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123677ff",
  null
  
)

export default component.exports