<template>
    <div class="menu-dashboard">

        <home-top-bar></home-top-bar>

        <home-second-bar @showMe="switchMenu"></home-second-bar>

        <div class="content-container">

            <dashboard v-if="currentTabComponent === 'dashboard'"></dashboard>

            <assets v-if="currentTabComponent === 'assets'"></assets>

            <Map v-if="currentTabComponent === 'map'"></Map>

            <connect v-if="currentTabComponent === 'connect'"></connect>

            <a-i v-if="currentTabComponent === 'AI'"></a-i>

            <shadow-page v-if="currentTabComponent === 'shadow'"></shadow-page>

            <flow v-show="currentTabComponent === 'flow'"></flow>

        </div>

        <chatgpt :show.sync="showChatGPT" v-if="showChatGPT"></chatgpt>
        <img src="../assets/ChatGPT/bottom_right_entry.png" class="bottom_right_entry" alt="" srcset="" @click="showChatGPT = true">

        <!-- <div v-if="currentTabComponent !== 'connect'">
            <component @crmClick="switchMenu" v-bind:is="currentTabComponent"></component>
        </div> -->


        <!-- <connect-detail v-if="currentTabComponent === 'connect-detail'"></connect-detail> -->
    </div>
</template>

<script>
    import HomeTopBar from '../components/HomeTopBar.vue';
    import HomeSecondBar from '../components/HomeSecondBar.vue';
    import Dashboard from '../components/Dashboard.vue';
    import Assets from '../components/Assets.vue';
    import Map from '../components/Map.vue';
    import Connect from '../components/Connect.vue';
    import AI from '../components/AI.vue';
    import ShadowPage from '../components/Shadow.vue';
    import Flow from '../components/Flow.vue';

    import chatgpt from '../components/chatgpt/chatgpt.vue';

    export default {
        components: {
            HomeTopBar,
            HomeSecondBar,
            Dashboard,
            Assets,
            Connect,
            AI,
            ShadowPage,
            Flow,
            Map,
            chatgpt,
        },
        name: 'Home',
        props: {
            msg: String
        },

        data() {
            return {
                currentTabComponent: "dashboard",

                userDetail: {},

                // 显示和隐藏 chatgpt
                showChatGPT: false,
            }
        },

        methods: {
            switchMenu(targetMenu) {
                // console.log(targetMenu)
                this.currentTabComponent = targetMenu
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .menu-dashboard {
        box-sizing: content-box;
        z-index: -2;
        position: fixed;
        width: 100%;
        height: 100%;
        min-width: 1050px;
        min-height: 600px;
        background: #212A3A;
        overflow-y: scroll;
    }

    .content-container {
        position: absolute;
        top: 122px;
        width: 100%;
        height: calc(100% - 122px);
        /* z-index: -100; */
        /*overflow: ;*/
    }

    .bottom_right_entry {
        position: fixed;
        z-index: 10;
        width: 78px;
        height: 78px;
        bottom: 151px;
        right: 22px;
    }
</style>