<template>
  <transition name="fade">
    <div class="map-configure-root">
      <div class="left">
        <div class="title">
          <div>{{ MapConfigureStaticData.name }}</div>
          <!-- <span :style="{ display: 'inline-block', width: status ? '31px' : '32.5px'}"></span> -->
          <span :style="{ display: 'inline-block', width: '32px' }"></span>
          <span class="right-span" :class="{ connected: status }" @click="status = !status">
            {{ status ? "Connected" : "Disconnected" }}</span>
        </div>

        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane name="first">
            <img v-if="currentTabIndex === 0" slot="label" src="../../assets/Map-Tab/camera-active.png" alt="" /><img
              v-else slot="label" src="../../assets/Map-Tab/camera.png" alt="" />
            <CameraContent :url="MapConfigureStaticData.url" :isPlaying="isPlaying"></CameraContent>
          </el-tab-pane>

          <el-tab-pane name="second"><img v-if="currentTabIndex === 1" slot="label"
              src="../../assets/Map-Tab/humidity-active.png" alt="" /><img v-else slot="label"
              src="../../assets/Map-Tab/humidity.png" alt="" />
            <HumidityContent></HumidityContent>
          </el-tab-pane>

          <el-tab-pane name="third"><img v-if="currentTabIndex === 2" slot="label"
              src="../../assets/Map-Tab/temperature-active.png" alt="" /><img v-else slot="label"
              src="../../assets/Map-Tab/temperature.png" alt="" />
            <TemperatureContent></TemperatureContent>
          </el-tab-pane>

          <el-tab-pane name="fourth"><img v-if="currentTabIndex === 3" slot="label"
              src="../../assets/Map-Tab/light-active.png" alt="" /><img v-else slot="label"
              src="../../assets/Map-Tab/light.png" alt="" />
            <LightContent :assetId="MapConfigureStaticData.assetId" :deviceData="MapConfigureStaticData.deviceData"></LightContent>
          </el-tab-pane>
        </el-tabs>

        <div class="bottom-div">
          <span @click="$emit('delete', MapConfigureStaticData.assetId)">Delete</span>
          <span>|</span>
          <span>IP Address: {{MapConfigureStaticData.nodeIp}}</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CameraContent from "./CameraContent.vue"; // 第一个 tab 内容: 视频
import HumidityContent from "./HumidityContent.vue"; // 第二个 tab 内容: 湿度
import TemperatureContent from "./TemperatureContent.vue"; // 第三个 tab 内容: 温度
import LightContent from "./LightContent.vue"; // 第四个 tab 内容: 灯光

// import TemperatureCon
export default {
  props: {
    MapConfigureStaticData: {
      type: Object,
      default: () => { },
      required: true,
    },
    isPlaying: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: {
    CameraContent,
    HumidityContent,
    TemperatureContent,
    LightContent,
  },
  data() {
    return {
      status: true, // 当前状态: 在线 (connected) 或不在线 (disconnected)
      activeName: "first", // actived tab 名称
      currentTabIndex: 0, // 当前 tab 索引
    };
  },
  methods: {
    handleTabClick(tab) {
      this.currentTabIndex = Number(tab.index);
    },
  },
};
</script>

<style scoped>
.map-configure-root {
  display: flex;
  z-index: 555;
  --triangle-size: 10px;
  --triangle-background-color: #101517;
}

.map-configure-root::before {
  position: absolute;
  transform: translate(-100%, 100%);
  content: " ";
  border-top: var(--triangle-size) solid transparent;
  border-left: var(--triangle-size) solid transparent;
  border-right: var(--triangle-size) solid var(--triangle-background-color);
  border-bottom: var(--triangle-size) solid transparent;
}

/* 添加过渡动画效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.left {
  width: calc(348px + 2px);
  height: 346px;
  box-sizing: border-box;
  background: #171d20;
  /* border: 1px solid #333b47; */
  padding: 18px 20px 16px 27px;
}

.title {
  color: white;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
}

.title div {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title .right-span.connected {
  color: #00fb00;
}

.title .right-span.connected::before {
  left: calc(-5px - 6px);
  top: calc(50% - 5px);
  width: 5px;
  height: 5px;
  background-color: #00fb00;
  border-radius: 50%;
  background-image: none;
}

.title .right-span {
  color: #FF8C40;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  user-select: none;
}

.title .right-span::before {
  content: " ";
  position: absolute;
  left: -19px;
  top: calc(50% - 7px);
  width: 11px;
  height: 10px;
  background-image: url(../../assets/Map-Tab/warning.png);
}

.bottom-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
}

.bottom-div span {
  color: rgba(255, 255, 255, 0.4);
}

.bottom-div span:nth-child(1) {
  cursor: pointer;
}

.bottom-div span:nth-child(2) {
  margin: 0 14px;
}

div.el-tabs.el-tabs--top>>>div.el-tabs__nav.is-top .el-tabs__active-bar.is-top {
  background-color: #fcb900;
  width: 22px !important;
  /* transform: translateX(27px); */
}

.el-tabs>>>.el-tabs__item.is-top:nth-child(2) {
  padding-left: 27px;
  padding-right: 27px;
}

.el-tabs>>>.el-tabs__item {
  padding-left: 27px;
  padding-right: 27px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-tabs>>>.el-tabs__nav {
  display: flex;
}

.el-tabs>>>.el-tabs__item.is-top:last-child {
  padding-right: 0;
}

.el-tabs>>>.el-tabs__header {
  width: 301px;
  height: 44px;
  box-sizing: border-box;
  /* padding-left: 27px; */
  /* padding-right: 26px; */
  margin: 0;
  background-color: #262d3a;
  user-select: none;
}

.el-tabs>>>.el-tabs__nav-wrap::after {
  background-color: #262d3a;
}
</style>
