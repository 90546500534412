<template>
	<div>
		<div class="popup-panel">
			<div class="popup shadow-setting show" v-if="popupShadowSetting">
				<div class="popup-bg"></div>
				<div class="popup-content">
					<!-- title信息 -->
					<div class="popup-close" @click="$emit('closeSetting', true)"></div>
					<div class="popup-title1" v-if="isCreate">Create Shadow</div>
					<div class="popup-title1" v-else>Edit Shadow</div>
					<!-- 嵌套组件 -->
					<!-- <div class="popup-components"> -->
					<!-- <div :class="{'popup-components': shadow.type !== 'mqtt', 'popup-components-mqtt': shadow.type === 'mqtt'}"> -->
					<div :class="settingStyle">
						<!-- button1 -->
						<shadow-btn-1 v-if="shadow.type === 'button1'" @inputByValue="inputByValue" :inputByValueDefault="inputByValueDefault.btn1Default" />
						<!-- button2 -->
						<shadow-btn-2 v-if="shadow.type === 'button2'" @inputByValue="inputByValue" :inputByValueDefault="inputByValueDefault.btn2Default" />
						<!-- button3 -->
						<shadow-btn-3 v-if="shadow.type === 'button3'" @inputByValue="inputByValue" :inputByValueDefault="inputByValueDefault.btn3Default" />
						<!-- button4 -->
						<shadow-btn-4 v-if="shadow.type === 'button4'" @inputByValue="inputByValue" :inputByValueDefault="inputByValueDefault.btn4Default" />
						<!-- dimming1 -->
						<shadow-dimming-1 v-if="shadow.type === 'dimming1'" @inputByValue="inputByValue" :inputByValueDefault="inputByValueDefault.dimming1Default" />
						<!-- dimming1 -->
						<shadow-cochl v-if="shadow.type === 'cochl'" @inputByValue="inputByValue" :inputByValueDefault="inputByValueDefault.cochlDefault" />
						<!-- MQTT -->
						<ShadowMQTT v-if="shadow.type === 'mqtt'" @inputByValue="inputByValue" :inputByValueDefault="inputByValueDefault.mqttDefault" />
						<!-- Websocket -->
						<ShadowWebsocket v-if="shadow.type === 'websocket'" @inputByValue="inputByValue" />
						<!-- AC -->
						<ShadowACControl v-if="shadow.type === 'accontrol'" @inputByValue="inputByValue" :inputByValueDefault="inputByValueDefault.acControlDefault" />
					</div>
					<!-- 确认按钮 -->
					<div class="confirm_box">
						<el-button type="primary" :loading="popupConfirmLoading" @click="popupConfirm(shadow.type)">
							Confirm
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- shadow Setting -->
	</div>
</template>

<script>
import {
	createVirtualController,
	editVirtualController,
	createMqttShadowInstance,
	createWebsocketShadowInstance,
} from '../api'

import ShadowBtn1 from '../components/ShadowControl/ShadowBtn1'
import ShadowBtn2 from '../components/ShadowControl/ShadowBtn2'
import ShadowBtn3 from '../components/ShadowControl/ShadowBtn3'
import ShadowBtn4 from '../components/ShadowControl/ShadowBtn4'
import ShadowDimming1 from '../components/ShadowControl/ShadowDimming1'
import ShadowCochl from '../components/ShadowControl/ShadowCochl'
import ShadowMQTT from '../components/ShadowControl/ShadowMQTT'
import ShadowWebsocket from '../components/ShadowControl/websocket/ShadowWebsocket.vue'
import ShadowACControl from '../components/ShadowControl/ShadowACControl.vue'

export default {

	components: {
		ShadowBtn1,
		ShadowBtn2,
		ShadowBtn3,
		ShadowBtn4,
		ShadowDimming1,
		ShadowCochl,
		ShadowMQTT,
		ShadowWebsocket,
		ShadowACControl,
	},
	name: 'ShadowSetting',
	props: {
		shadow: Object,
		isEdit: Boolean,
		isCreate: Boolean,
		editShadowData: Object,
	},
	emits: ['closeSetting'],
	data() {
		return {
			popupShadowSetting: false,
			// confirm: false,
			popupConfirmLoading: false,
			logo: null,
			// 默认值
			inputByValueDefault: {
				btn1Default: {
					title: 'Main Door',
					btn1: 'Unlock',
					logo: false,
				},
				btn2Default: {
					title: 'Main Door',
					btn1: 'Up',
					btn2: 'Down',
					logo: false,
				},
				btn3Default: {
					title: 'Main Door',
					btn1: 'High',
					btn2: 'Mid',
					btn3: 'Low',
					logo: false,
				},
				btn4Default: {
					title: 'Main Door',
					btn1: 'Model 1',
					btn2: 'Model 2',
					btn3: 'Model 3',
					btn4: 'Model 4',
					logo: false,
				},
				dimming1Default: {
					title: 'Main Door',
					btn1: 'Off',
					logo: false,
				},
				cochlDefault: {
					title: '',
					api_key: '',
					service: '',
				},
				mqttDefault: {
					title: '',
				},
				acControlDefault: {
					title: '',
					target_temperature: 24,
					mode: 'fan',
					fan_speed: 'auto',
					power: false,
					logo: false,
				},
			},

			settingStyle: "popup-components"
		}
	},
	methods: {
		createConectDetail() {
			this.popupShadowSetting = true;
		},
		editConectDetail() {
			// 点击编辑 将原数据放入组件内展示
			if (this.editShadowData.type === 'dimming1') {
				this.inputByValueDefault.dimming1Default = this.editShadowData
			} else if (this.editShadowData.type === 'button1') {
				this.inputByValueDefault.btn1Default = this.editShadowData
			} else if (this.editShadowData.type === 'button2') {
				this.inputByValueDefault.btn2Default = this.editShadowData
			} else if (this.editShadowData.type === 'button3') {
				this.inputByValueDefault.btn3Default = this.editShadowData
			} else if (this.editShadowData.type === 'button4') {
				this.inputByValueDefault.btn4Default = this.editShadowData
			} else if (this.editShadowData.type === 'cochl') {
				this.inputByValueDefault.cochlDefault = this.editShadowData
			} else if (this.editShadowData.type === 'accontrol') {
				this.inputByValueDefault.acControlDefault = this.editShadowData
			} else {
				this.$message.error('Invalid type!')
			}
			this.popupShadowSetting = true;
		},
		popupConfirm(type) {
			// console.log(this.inputByValueDefault);
			// 根据不同组件所修改的数据进行请求API修改
			this.popupConfirmLoading = true
			let reqData = {};
			if (type === 'button1') {
				reqData = this.inputByValueDefault.btn1Default
			} else if (type === 'button2') {
				reqData = this.inputByValueDefault.btn2Default
			} else if (type === 'button3') {
				reqData = this.inputByValueDefault.btn3Default
			} else if (type === 'button4') {
				reqData = this.inputByValueDefault.btn4Default
			} else if (type === 'dimming1') {
				reqData = this.inputByValueDefault.dimming1Default
			} else if (type === 'cochl') {
				reqData = this.inputByValueDefault.cochlDefault
			} else if (type === 'mqtt' || type === 'websocket') {
				// Do nothing
			} else if (type === 'accontrol') {
				reqData = this.inputByValueDefault.acControlDefault
			} else {
				this.$message.error('Invalid type!')
			}
			reqData.type = type
			// Create
			if (this.isCreate) {
				let promise
				if (type === 'mqtt') {
					promise = createMqttShadowInstance(this.inputByValueDefault.mqttDefault.title)
				} else if (type === 'websocket') {
					promise = createWebsocketShadowInstance(this.inputByValueDefault.instanceName)
				} else {
					promise = createVirtualController(reqData, this.logo)
				}

				promise.then(() => {
					this.$emit('closeSetting')
				}).catch(err => {
					console.log(err.response);
					if (err.response.status === 403) {
						this.$message.error({
							center: true,
							message: err.response.data
						});
					} else {
						this.$message.error({
							center: true,
							message: 'Network Error!'
						});
					}
				}).finally(() => {
					this.popupConfirmLoading = false
				})
			} else if (this.isEdit) {
				// Edit
				editVirtualController(this.editShadowData.id, reqData, this.logo).then(() => {
					this.$emit('closeSetting')
				}).catch(err => {
					console.log(err);
					if (err.response.status === 403) {
						this.$message.error({
							center: true,
							message: err.response.data
						});
					} else {
						this.$message.error({
							center: true,
							message: 'Network Error!'
						});
					}
				}).finally(() => {
					this.popupConfirmLoading = false
				})
			}
		},
		inputByValue(type, val) {
			// 组件内的值发生变化时，父组件同步变化
			if (type === 'inputByValueBtn1') {
				this.inputByValueDefault.btn1Default = val
			}
			if (type === 'inputByValueBtn2') {
				this.inputByValueDefault.btn2Default = val
			}
			if (type === 'inputByValueBtn3') {
				this.inputByValueDefault.btn3Default = val
			}
			if (type === 'inputByValueBtn4') {
				this.inputByValueDefault.btn4Default = val
			}
			if (type === 'inputByValueDimming1') {
				this.inputByValueDefault.dimming1Default = val
			}
			if (type === 'inputByValueCochl') {
				this.inputByValueDefault.inputByValueDefault = val
			}
			if (type === 'mqtt') {
				this.inputByValueDefault.mqttDefault = val
			}
			if (type === 'websocket') {
				this.inputByValueDefault.instanceName = val
			}
			if (type === 'logo') {
				this.logo = val
			}
		}
	},
	mounted: function () {
		if (['mqtt', 'websocket'].indexOf(this.shadow.type) !== -1) {
			this.settingStyle = 'popup-components-only-show-one-input'
		}

		if (this.isCreate) {
			this.createConectDetail()
		} else if (this.isEdit) {
			this.editConectDetail()
		}
	},
	updated: function () { }
};
</script>

<style scoped>
/* Popup */
.popup {
	position: absolute;
	top: 0px;
	right: 0px;
	height: 100%;
	width: 100%;
	z-index: 100;
	display: none;
}

.popup.show {
	display: block;
}

.popup .popup-bg {
	background: black;
	position: absolute;
	top: 0px;
	right: 0px;
	height: 100%;
	width: 100%;
	z-index: 1;
	opacity: 0.4;
}

.popup .popup-content {
	background-color: #182130;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	padding: 36px 32px 35px 32px;
	color: #FFFFFF;
}

.popup .popup-close {
	position: absolute;
	top: 36px;
	right: 32px;
	width: 22px;
	height: 22px;
	background: url('../assets/icons/icon-close.png') no-repeat center center;
	cursor: pointer;
}


.popup.shadow-setting .popup-content {
	min-width: 354px;
}

.popup.shadow-setting .popup-title1 {
	margin-bottom: 19px;
}

.popup.shadow-setting .popup-content .confirm_box button {
	margin: 0 auto;
	width: 94px;
	height: 29px;
	background: #0E8CE3;
	border-radius: 2px;
}

.popup.shadow-setting .popup-content .popup-components {
	margin: 30px auto;
	width: 207px;
	height: 350px;
	border-radius: 2px;
}

.popup.shadow-setting .popup-content .popup-components-only-show-one-input {
	margin: 30px auto;
	width: 207px;
	height: 70px;
	border-radius: 2px;
}



/* Popup */
</style>