<template>
	<div id="box">
		<ConnectInstancesDialog :show="(isEdit || isCreate) && !showSelectDevice" :btnLoadding="confirmButtonLoadingStatus"
			:defaultIntervalSecValue.sync="connectInstanceVo.attr.update_asset_data_interval_sec" 
			:disconnectedEmailNotify.sync="connectInstanceVo.attr.disconnectedEmailNotify" @clickClose="clickClose"
			:confirmName="'Next'" @clickConfirm="popupConfirm">
			<template v-slot:content>
				<div class="popup-field1">
					<div class="label-block"><label>Name:</label></div>
					<input class="cpt-input1" v-model="connectInstanceVo.name" type="text" name="uhoo-name" />
				</div>
				<div class="popup-field1">
					<div class="label-block"><label>UHoo Client Id:</label></div>
					<div class="popup-row">
						<div class="col-1">
							<input class="cpt-input1" v-model="connectInstanceVo.attr.client_id" type="password"
								name="uhoo-client-id" />
						</div>
						<div class="col-2"></div>
						<div class="col-3">
							<el-button type="primary" :loading="testButtonLoadingStatus" @click="popupTest">Test</el-button>
						</div>
					</div>
				</div>
				<div class="message-panel">
					<span class="setting_icon_error" v-bind:class="{show : show_message== 'popup-error'}">
						<img class="setting_icon_error_img" src="../assets/icons/icon-invalid@2x.png">
						Denied
					</span>
					<span class="setting_icon_success" v-bind:class="{show : show_message == 'popup-success'}">
						<img class="setting_icon_success_img" src="../assets/icons/icon-success@2x.png">
						Pass
					</span>
				</div>
			</template>
		</ConnectInstancesDialog>

		<SelectDevices 
			v-if="showSelectDevice" 
			:connectInstanceVo="connectInstanceVo"
			:integrationDeviceList="integrationDeviceList"
			:selectedDeviceKeys="selectedDeviceKeys"
			@clickClose="clickClose"
			@selectDevicesComfirm="selectDevicesComfirm"
		> 
		</SelectDevices>
	</div>

</template>

<script>
	import {
		createConnectInstance,
		updateConnectInstance,
		getConnectInstanceVo,
		check_uhoo_credential
	} from '../api'

	import ConnectInstancesDialog from './template/ConnectInstancesDialog.vue'
	import SelectDevices from './SelectDevices.vue'

	export default {

		components: {
			ConnectInstancesDialog,
			SelectDevices,
		},
		name: 'UHooSetting',
		props: {
			// connectId: Number,
			// connectDescription: String,
			// connectIconUrl: String,
			connect: Object,
			instance: Object,

			connectInstanceId: Number,
			currentEditedConnectInstanceName: String,

			isEdit: Boolean,
			isCreate: Boolean,
		},
		emits: ['closeSetting'],
		data() {
			return {
				show: '',
				show_message: '',
				connectInstanceVo: {
					instanceId: -1,
					connectId: -1,
					name: '',
					attr: {
						// uhoo_username: '',
						client_id: '',
						update_asset_data_interval_sec: null,
						disconnectedEmailNotify: 'false',
					}
				},
				testPassed: false,
				confirm: false,
				testButtonLoadingStatus: false,
				confirmButtonLoadingStatus: false,

				showSelectDevice: false,
				integrationDeviceList: [],
				selectedDeviceKeys: [],
			}
		},
		methods: {
			createConectDetail() {
				this.show = 'popup-uhoo-setting';
				this.show_message = '';
				this.connectInstanceVo.connectId = this.connect.id
			},
			editConectDetail() {
				console.log('edit');
				console.log(this.connectInstanceId);

				this.show = 'popup-uhoo-setting';
				this.show_message = '';
				// this.connectInstanceVoAttruhoo_password = '',
				getConnectInstanceVo(this.connect.id, this.connectInstanceId).then(vo => {
					this.connectInstanceVo.connectId = this.connect.id
					this.connectInstanceVo.name = vo.name
					this.connectInstanceVo.attr.update_asset_data_interval_sec = vo.attr['update_asset_data_interval_sec'];
					this.connectInstanceVo.attr.disconnectedEmailNotify = vo.attr['disconnectedEmailNotify'];
					// this.connectInstanceVo.attr.uhoo_username = vo.attr['uhoo_username']
				})
			},
			clickClose() {
				this.$emit('closeSetting')
			},
			popupTest() {
				if (this.connectInstanceVo.name !== '' && this.connectInstanceVo.attr.client_id !== '') {
					this.testButtonLoadingStatus = true
					check_uhoo_credential(this.connectInstanceVo.attr.client_id).then(() => {
						this.show_message = 'popup-success'
						this.testButtonLoadingStatus = false
						console.log(this.show_message)
						this.testPassed = true
					}).catch(() => {
						this.show_message = 'popup-error'
						this.testButtonLoadingStatus = false
						this.testPassed = false
					})
				} else {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
				}
			},
			popupConfirm() {
				if (this.testPassed === false) {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
					this.confirmButtonLoadingStatus = false
				}
				if (this.testPassed && this.isCreate && this.connectInstanceVo.attr.update_asset_data_interval_sec) {
					createConnectInstance(this.connectInstanceVo).then((res) => {
							this.showSelectDevice = true
							this.connectInstanceVo = res.connectInstanceVo
							this.integrationDeviceList = res.deviceList
						})
						.catch((error) => {
							if (error.response.data === 'over data points') {
								this.$message.error('over data points limit');
							} else if (error.response.data.code === 103) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						})
						.finally(() => this.confirmButtonLoadingStatus = false)
				}
				if (this.testPassed && this.isEdit) {
					console.log(this.connectInstanceVo)
					console.log(this.connectInstanceId)
					updateConnectInstance(this.connectInstanceId, this.connectInstanceVo, true).then((res) => {
							this.showSelectDevice = true
							this.connectInstanceVo = res.connectInstanceVo
							this.integrationDeviceList = res.deviceList
							this.selectedDeviceKeys = res.selectedDeviceKeys
						})
						.catch((error) => {
							if (error.response.data.code === 103) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						})
						.finally(() => this.confirmButtonLoadingStatus = false)
				}
			},

			selectDevicesComfirm() {
				this.$emit('closeSetting')
			}
		},

		mounted: function () {
			if (this.isCreate) {
				// 不能在子组件中给父组件传过来的数据赋值，会红色警告，且不好维护
				// console.log("this.createConectDetail()------------------------------>")
				this.createConectDetail()
			} else if (this.isEdit) {
				// console.log("this.editConectDetail()")
				this.editConectDetail()
			}
		},
		updated: function () {
			// console.log(this.connectInstanceId)
			// console.log(this.connect)
		}
	};
</script>

<style scoped>
	.cpt-input1 {
		box-sizing: content-box;
		height: 39px;
		width: 94%;
		border-radius: 4px;
		border: 1px solid #333B47;
		background: #182130;
		color: #FFFFFF;
		padding: 0px 10px;
		font-size: 14px;
	}

	.setting_icon_success {
		display: none;
	}

	.setting_icon_success.show {
		display: block;
	}

	.setting_icon_success_img {
		vertical-align: middle;
		margin-right: 3px;
		width: 14px;
		height: 14px;
		margin-top: -1px
	}

	.setting_icon_error {
		display: none;
	}

	.setting_icon_error.show {
		display: block;
	}

	.setting_icon_error_img {
		vertical-align: middle;
		margin-right: 3px;
		width: 14px;
		height: 14px;
		margin-top: -1px
	}

	.popup-field1 {
		margin-bottom: 15px;
	}

	.popup-field1 .popup-row {
		display: flex;
	}

	.popup-field1 .popup-row .col-1 {
		width: 75%;
	}

	.popup-field1 .popup-row .col-2 {
		width: 5%;
	}

	.popup-field1 .popup-row .col-3 {
		width: 15%;
	}

	::v-deep .col-3 .el-button--primary {
		display: flex;
		justify-content: center;
		color: #FFF;
		background-color: #212A3A;
		margin-left: 4px;
		width: 67px;
		height: 40px;
		border: none;
	}

	::v-deep .col-3 .el-button--primary span {
		margin-top: 2px;
	}
</style>