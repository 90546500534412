<template>

    <div>
        <div class="container page-connect" v-if="connectPage === true">
            <div id="connect_filter-and-search-box">
                <!-- 之后会用到，直接打开即可 -->
                <!-- <a id="all" href="#" class="cpt-connect-link1 active">
                    <span>
                        All
                    </span>
                </a>
                <a id="catalog" href="#" class="cpt-connect-link1">
                    <span>
                        Catalog
                    </span>
                </a>
                <a id="community" href="#" class="cpt-connect-link1">
                    <span>
                        Community
                    </span>
                </a>

                <div id="search-box">
                    <input id="search-input-form" type="text" name="search-text" placeholder="Search">
                </div>  -->
            </div>

            <!-- connect the display section -->
            <div class="connect-list-box">
                <div class="connect-item" v-for="connect in connectList" :key="connect.id">
                    <!-- <div> -->
                    <div class="connect-name1">{{ connect.name }}</div>
                    <div class="connect-name2">ID:{{ connect.id }}</div>
                    <!-- <div class="connect-image1" v-if="connect.name === 'Salto'"><img src="../assets/icons/icon_connect.png"></div> -->
                    <div class="connect-image1"><img :src="connect.logoUrl"></div>
                    <!-- <div class="connect-image1" v-if="connect.name === 'uHoo'"><img src="../assets/icons/connect_uhoo_@1.png"></div> -->
                    <!-- <div class="connect-image1" v-if="connect.name === 'uHoo'"><img src="../assets/icons/connect_instance_uhoo_@2.png"></div> -->
                    <!-- <div class="connect-button1" @click="$emit('crmClick','connectDetail',r.id)"> -->
                    <div class="connect-button1" @click="entryConnectDetail(connect)">
                        {{ connect.type }}
                    </div>
                    <!-- </div> -->

                </div>
            </div>
            <!-- connect the display section -->

        </div>

        <!-- <AciistSetting :show="true" :closeOnClickModal="false"></AciistSetting> -->

        <!-- <connect-detail v-show="connectDetailPage === true" :connectId="connectId"></connect-detail> -->
        <connect-detail v-if="connectDetailPage === true" :connect="connect" @goConnect="goConnect"></connect-detail>
    </div>

</template>

<script>
import {
    getAllConnect
} from '../api'
import ConnectDetail from './ConnectDetail.vue';

// import AciistSetting from './connect/AciistSetting.vue';

export default {
    components: {
        ConnectDetail,
        // AciistSetting,
    },
    name: 'Connect',
    props: {
        msg: String,
        records: {
            type: Array,
            default: () => [{
                id: 1160,
                name: 'Act! 365',
                image: ''
            },
            {
                id: 1161,
                name: 'Act! 365 - 1',
                image: ''
            },
            {
                id: 1162,
                name: 'Act! 365 - 2',
                image: ''
            },
            {
                id: 1163,
                name: 'Act! 365 - 3',
                image: ''
            },
            {
                id: 1164,
                name: 'Act! 365 - 4',
                image: ''
            },
            {
                id: 1165,
                name: 'Act! 365 - 5',
                image: ''
            },
            {
                id: 1166,
                name: 'Act! 365 - 6',
                image: ''
            },
            {
                id: 1167,
                name: 'Act! 365 - 7',
                image: ''
            },
            {
                id: 1168,
                name: 'Act! 365 - 8',
                image: ''
            },
            {
                id: 1169,
                name: 'Act! 365 - 9',
                image: ''
            },
            {
                id: 1170,
                name: 'Act! 365 - 10',
                image: ''
            },
            {
                id: 1171,
                name: 'Act! 365 - 11',
                image: ''
            },
            ],
        },
    },
    data() {
        return {
            connectList: [],
            currentConnect: null,

            connectPage: true,

            // connectId: -1,
            // connectDescription: '',
            // connectIconUrl: '',

            connect: Object,

            connectDetailPage: false
        }
    },
    methods: {
        // 需要留意这个id是否以后会用到
        // crmClick(id) {
        //     this.data.currentConnect = id;
        // 	console.log(id);
        // 	this.$router.push({'name': 'connectDetail'})
        // },
        // updateInstanceConnectId(connectId){
        //     this.connectId = connectId
        // },
        entryConnectDetail(connect) {
            this.connectPage = false
            this.connectDetailPage = true

            // this.connectId = connect.connectId
            // this.connectDescription = connect.description
            // this.connectIconUrl = connect.iconUrl

            this.connect = connect
        },
        goConnect() {
            this.connectDetailPage = false
            this.connectPage = true
        }
    },
    mounted: function () {
        getAllConnect().then(connects => {
            this.connectList = connects.filter(connect => connect.type !== 'A.I.')
            // this.connectList = connects
            // console.log(this.connectList)
        })
    },
    updated: function () {
        // console.log(this.IQs)
        // console.log(this.PINs)
        // console.log(this.IQValue)
        // console.log(this.connectInstanceVo)
        // console.log(this.items.length)
        // console.log(this.items)
        // console.log(this.items)
        // console.log(this.isCreate)
        // console.log(this.isEdit)
        // console.log(this.connectPage)
        // console.log(this.connectDetailPage)
        // console.log(this.connectId)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* **********************************************************  container  *************************************************************************** */
.container {
    z-index: 0;
    position: relative;
    margin-left: 121px;
    margin-top: 25px;
    margin-right: 121px;
    margin-bottom: 121px;

    min-height: 537px;
}

.page-connect .cpt-connect-link1 {
    padding: 4px 33px;
    border: 1px solid #FCB900;
    border-radius: 4px;
    border: 1px solid transparent;
    margin-right: 33px;
}

.page-connect .cpt-connect-link1.active,
.page-connect .cpt-connect-link1:hover {
    border-color: #FCB900;
}

.page-connect .cpt-connect-link1 span {
    font-size: 14px;
    color: #FFFFFF;
    line-height: 22px;
    font-weight: 500;
}

.page-connect .cpt-connect-link1.active span,
.page-connect .cpt-connect-link1:hover span {
    color: #FCB900;
}

/* .page-connect #connect_filter-and-search-box {line-height:50px; margin-bottom:30px; } */
/* 需要显示 add、search 将注释打开*/
.page-connect .connect_filter-and-search-box {
    height: 15px;
    width: 1200px;
}

/* 起到隐藏未写的 add、search作用  上面打开，这里就注释掉*/
/* display: flex; justify-content: center;  */
/* .page-connect .connect-item {background: #182130; border-radius: 2px; width: 138px; margin-right: 16.5px; margin-bottom:17px; padding:24px; float:left; text-align:center; } */





/* connect the display section */
.connect-list-box {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.page-connect .connect-item {
    background: #182130;
    border-radius: 2px;
    width: 138px;
    margin-right: 16.5px;
    margin-bottom: 17px;
    padding: 24px;
    float: left;
    text-align: center;
    /* flex-shrink: 0.9;  */
    /* justify-content:center; */
    /* align-items: center; */
    /* justify-content: ; */
}

/* .page-connect .connect-item:nth-child(6n+0) {margin-right:0px;} */
.page-connect .connect-item .connect-name1 {
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    margin-bottom: 9px;
}

.page-connect .connect-item .connect-name2 {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 14px;
    margin-bottom: 15px;
    opacity: 0.5;
}

.page-connect .connect-item .connect-image1 {
    margin: auto;
    width: 76px;
    height: 76px;
    background: #2C374A;
    border-radius: 50%;
    margin-bottom: 15px;
}

.page-connect .connect-item .connect-button1 {
    margin: auto;
    text-align: center;
    background: #0E8CE3;
    color: #FFFFFF;
    border-radius: 2px;
    font-size: 15px;
    line-height: 14px;
    display: inline-block;
    padding: 8px 24px;
    cursor: pointer;
}

/* connect the display section */
</style>