<template>
	<div>
		<!-- --------- -->
		<!--  Steps 1  -->
		<!-- --------- -->
		<ConnectInstancesDialog :showOldProtocols="true" :oldProtocolsValue.sync="connectInstanceVo.attr.oldProtocolsValue"
			:gatewayAddr.sync="connectInstanceVo.attr.gatewayAddr" :btnLoadding="confirmButtonLoadingStatus"
			:confirmName="'Confirm'" :show="isEdit || isCreate"
			:defaultIntervalSecValue.sync="connectInstanceVo.attr.update_asset_data_interval_sec"
			:disconnectedEmailNotify.sync="connectInstanceVo.attr.disconnectedEmailNotify" @clickClose="clickClose"
			@clickConfirm="popupConfirm2">
			<template v-slot:content>
				<div class="popup-field1-inheath">
					<div class="label-block"><label>Name:</label></div>
					<input class="cpt-input1" v-model="connectInstanceVoName" type="text" name="inHealth-name" />
				</div>
				<div class="popup-field1-inheath" :style="inhealth_list.length > 4 ? 'height:280px':''">
					<el-scrollbar ref="myScrollbar" style="height:100%;">
						<div class="label-block"><label>Info:</label></div>
						<div class="item-row" v-for="(item, index) in inhealth_list" :key="item.index">
							<div class="col-1">
								<input style="width: 80px" class="cpt-input1" v-model="item.name" type="text" placeholder="Name" />
							</div>
							<div class="col-2 pop_step_2_dropdown">
								<input style="width: 100px" class="cpt-input1" v-model="item.addr" type="text" placeholder="Addr" />
							</div>
							<el-select :no-data-text="'No Data'" popper-class="custom-select" v-model="item.sensor" placeholder="">
								<el-option label="Purifier" value="purifier" />
								<el-option label="Eco Sensor" value="eco_sensor" />
								<el-option label="HCL" value="hcl" />
							</el-select>
							<div class="col-4">
								<button class="cpt-btn3" @click="removeItem(index)" type="button">
									<span class="pin-text"></span>
								</button>
							</div>
						</div>
					</el-scrollbar>
				</div>
				<div class="popup-button-panel2">
					<button class="cpt-btn2" type="button" @click="popupAdd">
						<span class="add-text">
							Add
						</span>
					</button>
				</div>
			</template>
		</ConnectInstancesDialog>

		<!-- <SelectDevices v-if="showSelectDevice" :connectInstanceVo="connectInstanceVo"
			:integrationDeviceList="integrationDeviceList" :selectedDeviceKeys="selectedDeviceKeys" @clickClose="clickClose"
			@selectDevicesComfirm="selectDevicesComfirm">
		</SelectDevices> -->
	</div>

</template>

<script>
	import {
		createConnectInstance,
		updateConnectInstance,
		confirmConnectInstance,
	} from '../api'

	import ConnectInstancesDialog from './template/ConnectInstancesDialog.vue'
	// import SelectDevices from './SelectDevices.vue'

	// let id = 0;
	export default {
		components: {
			ConnectInstancesDialog,
			// SelectDevices,
		},
		name: 'inHealthSetting',
		props: {
			connect: Object,
			instance: Object,
			connectInstanceId: Number,
			isEdit: Boolean,
			isCreate: Boolean,
			msg: String,
			currentEditedConnectInstanceName: String,
			currentEditedUpdateAssetDataIntervalSec: String
		},
		emits: ['closeSetting'],
		data() {
			return {
				loadCity: {
					lazy: true,
					lazyLoad: this.cascaderLazyLoad,
				},
				show_message: '',
				connectInstanceVoName: '',
				testButtonLoadingStatus: false,
				confirmButtonLoadingStatus: false,
				loadPopupContent: false,
				connectInstanceVo: {
					instanceId: -1,
					connectId: -1,
					name: '',
					attr: {
						update_asset_data_interval_sec: null,
						disconnectedEmailNotify: 'false',
						oldProtocolsValue: 'false',
						gatewayAddr: null,
					}
				},
				showSelectDevice: false,
				integrationDeviceList: [],
				selectedDeviceKeys: [],
				inhealth_list: [{
					addr: '',
					sensor: '',
					name: ''
				}],
			}
		},
		methods: {
			createConectDetail() {
				this.show = 'popup-inHealth-setting';
				this.show_message = '';
				this.isCreate = true
				this.connectInstanceVoName = ''
			},
			clickClose() {
				this.$emit('closeSetting')
			},
			editConectDetail() {
				console.log('edit');
				console.log(this.connectInstanceId);
				this.connectInstanceVoName = this.currentEditedConnectInstanceName
				this.connectInstanceVo.attr.update_asset_data_interval_sec = this.currentEditedUpdateAssetDataIntervalSec;
				this.connectInstanceVo.attr.disconnectedEmailNotify = this.instance.attr.disconnectedEmailNotify
				this.connectInstanceVo.attr.oldProtocolsValue = this.instance.attr.oldProtocols
				this.connectInstanceVo.attr.gatewayAddr = this.instance.attr.gatewayAddr
				this.inhealth_list = JSON.parse(this.instance.attr.inhealth_list)
				this.show = 'popup-inHealth-setting';
				this.show_message = '';
				this.isEdit = true
			},

			popupConfirmCancel() {
				this.show = '';
				this.show_message = '';
			},

			async popupConfirm2() {
				// this.show = '';
				this.show_message = '';

				this.connectInstanceVo.connectId = this.connect.id
				this.connectInstanceVo.name = this.connectInstanceVoName

				this.connectInstanceVo.attr = {
					'update_asset_data_interval_sec': this.connectInstanceVo.attr.update_asset_data_interval_sec,
					'disconnectedEmailNotify': this.connectInstanceVo.attr.disconnectedEmailNotify,
					'inhealth_list': JSON.stringify(this.inhealth_list),
					'oldProtocols': this.connectInstanceVo.attr.oldProtocolsValue,
					'gatewayAddr': this.connectInstanceVo.attr.gatewayAddr
				}


				if (!this.connectInstanceVo.attr || !this.inhealth_list || !this.connectInstanceVo
					.name || !this.connectInstanceVo.attr.update_asset_data_interval_sec) {
					this.$message.error({
						center: true,
						message: 'Please enter the complete information!'
					});
					return;
				}

				this.confirmButtonLoadingStatus = true;
				if (this.isCreate) {
					await createConnectInstance(this.connectInstanceVo)
						.then((res) => {
							this.showStep2 = false
							this.showSelectDevice = true
							this.connectInstanceVo = res.connectInstanceVo
							this.integrationDeviceList = res.deviceList
						})
						.catch((error) => {
							if (error.response.data === 'over data points') {
								this.$message.error('over data points limit');
							} else if (error.response.data.code === 103) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
								this.show = 'popup-inHealth-setting'
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						}).finally(() => this.confirmButtonLoadingStatus = false)
				}
				if (this.isEdit) {
					await updateConnectInstance(this.connectInstanceId, this.connectInstanceVo, true)
						.then((res) => {
							this.showStep2 = false
							this.showSelectDevice = true
							this.connectInstanceVo = res.connectInstanceVo
							this.integrationDeviceList = res.deviceList
							this.selectedDeviceKeys = res.selectedDeviceKeys
						})
						.catch((error) => {
							if (error.response.data.code === 103) {
								this.$message.error({
									center: true,
									message: 'Duplicate name, please re-enter!'
								});
								this.show = 'popup-inHealth-setting'
							} else {
								this.$message.error({
									center: true,
									message: 'Network Error!'
								});
							}
						}).finally(() => this.confirmButtonLoadingStatus = false)
				}

				if (this.isEdit || this.isCreate) {
					await confirmConnectInstance(this.connectInstanceVo, this.integrationDeviceList).then(() => {
						this.$emit("closeSetting");
					}).catch(() => {
						this.$message.error("Network Error!");
					})
				}
				// this.$emit('closeSetting')
			},

			selectDevicesComfirm() {
				this.$emit('closeSetting')
			},

			popupAdd() {
				this.inhealth_list.push({
					addr: '',
					sensor: '',
					name: ''
				})
			},
			removeItem(index) {
				if (this.inhealth_list.length <= 1) {
					return
				}
				this.inhealth_list.splice(index, 1)
			},

		},

		mounted: function () {
			if (this.isCreate) {
				// console.log("this.createConectDetail()------------------------------>")
				this.createConectDetail()
			} else if (this.isEdit) {
				// console.log("this.editConectDetail()")
				this.editConectDetail()
			}
		},
	};
</script>

<style scoped>
	.popup-field1-inheath .label-block {
		margin: 12px 0;
		color: #cfd1d4;
		opacity: 0.8;
	}

	.popup-field1-inheath .item-row {
		display: flex;
		margin-bottom: 7px;
	}

	.popup-field1-inheath .item-row .col-2 {
		padding: 0 10px;
	}

	.popup-field1-inheath .item-row .col-3 {
		width: 14%;
	}

	.popup-field1-inheath .item-row .col-2 .cpt-btn2,
	.popup-field1-inheath .item-row .col-2 .cpt-btn3 {
		line-height: 25px;
	}

	.cpt-btn2 {
		background: #212A3A;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		padding: 7px 23px;
		display: inline;
		cursor: pointer;
		margin-top: 15px;
	}

	.cpt-btn2 span {
		font-weight: 500;
		color: #999DA5;
		line-height: 15px;
		font-size: 15px;
	}

	.cpt-input1 {
		box-sizing: content-box;
		height: 39px;
		width: 94%;
		border-radius: 4px;
		border: 1px solid #333b47;
		background: #182130;
		color: #ffffff;
		padding: 0px 10px;
		font-size: 14px;
		outline: none;
	}

	.cpt-btn3 {
		background: #182130;
		border-radius: 2px;
		display: flex;
		height: 100%;
		padding: 0 15px 20px 15px;
		justify-content: center;
		align-items: center;
		border-width: 0px;
		display: inline;
		cursor: pointer;
	}

	/* .cpt-btn3 span {font-weight: 500; color: #999DA5; line-height: 15px; font-size: 15px;} */
	.cpt-btn3 span {
		width: 12px;
		height: 1.5px;
		background: #D8D8D8;
		display: inline-block;
		margin-top: 20px;
		text-align: center;
	}

	::v-deep .el-scrollbar__wrap {
		overflow: scroll;
		width: 110%;
		height: 110%;
	}

	::v-deep .popup-field1-inheath .el-input__inner {
		-webkit-appearance: none;
		background-color: #333B47;
		background-image: none;

		width: 120px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333B47;

		box-sizing: border-box;
		color: #FFFFFF;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color .2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	::v-deep .col-3 .el-button--primary {
		display: flex;
		justify-content: center;
		color: #FFF;
		background-color: #212A3A;
		margin-left: 4px;
		width: 67px;
		height: 40px;
		border: none;
	}

	::v-deep .col-3 .el-button--primary span {
		margin-top: 2px;
	}

	::v-deep .popup-field-select .el-input__inner {
		background-color: #333b47;
		background-image: none;

		width: 355px !important;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #333b47;

		box-sizing: border-box;
		color: #ffffff;
		display: inline-block;
		font-size: inherit;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}


	::v-deep .popup-field-select .el-select__input {
		outline: none;
		color: #ffffff;
	}

	::v-deep .popup-field-select .el-select-dropdown__item {
		font-size: 14px;
		padding: 0 20px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #ffffff;
		height: 34px;
		line-height: 34px;
		box-sizing: border-box;
		cursor: pointer;

	}
</style>