<template>

    <div class="container page-assets">
        <div class="add_button-and-search-box">
            <!-- 后面会添加对应的api直接打开即可 -->
            <!-- <button id="add-button" type="button" class="cpt-btn1">
                <span id="add-text">
                    Add
                </span>
            </button> -->

            <div class="add-button"></div> <!-- 打开上面button注释掉此行 -->

            <div class="search-box">
                <input class="search-input-form" v-model="search" type="text" name="search-text" placeholder="Search">
            </div>
        </div>

        <el-table :empty-text="'No Data'" :header-cell-style="{borderBottom: '1px solid rgba(255, 255, 255, .04)'}"
            :data="tableDataFilter">
            <!-- Name -->
            <el-table-column prop="name" label="Name" min-width="230px">
                <template slot-scope="{row}">
                    <el-image class="table-label-name-image" v-if="row.attr && row.attr.asset_img"
                        :src="row.attr.asset_img" fit="fit">
                        <div slot="error" class="image-slot"></div>
                    </el-image>
                    <span v-if="row.name">{{ row.name }}</span>
                    <!-- <span>{{ row.attr.uhoo_type }}</span> -->
                    <span v-if="row.attr && row.attr.connect_name">
                        {{ row.attr.connect_name }} 
                        {{ row.connectInstanceId && `(${row.connectInstanceId})` }}
                    </span>
                </template>
            </el-table-column>
            <!-- Support -->
            <el-table-column prop="support" label="Support" min-width="100">
                <template slot-scope="{row}">
                    <span v-if="row.deviceInfo">{{ row.deviceInfo.support }}</span>
                </template>
            </el-table-column>
            <!-- Type -->
            <el-table-column prop="type" label="Type">
                <template slot-scope="{row}">
                    <span v-if="row.deviceInfo">{{ row.deviceInfo.type }}</span>
                </template>
            </el-table-column>
            <!-- Probe Readings -->
            <el-table-column prop="probeReadings" label="Probe Status" width="550">
                <template slot="header">
                    <span>Probe Status</span>
                    <el-image @click="refreshData" class="refresh-img" :src="refreshUrl" fit="fit"></el-image>
                </template>
                <template slot-scope="{row}">
                    <div v-if="assetViewData[row.deviceKey] !== undefined" class="table-label-probeReadings">
                        <!-- item -->
                        <div class="items" v-for="(val, key, index) in assetViewData[row.deviceKey].payload"
                            :key="index">
                            <img :src="iconMap[val.type]" class="asset-icon1 battery" />
                            <span class="name">{{ val.name }}</span>
                            <el-tooltip :disabled="disabledItemDataTooltip" :content="val.value.toString()" placement="top">
                                <div class='data' @mouseover="showItemValueTooltip(val.value)">
                                    <span :ref="val.value">{{ val.value }}</span>
                                </div>
                            </el-tooltip>
                        </div>
                        <!-- 展开与收起 -->
                        <div @click='expandFold(row.deviceKey)' class='fold'
                            v-show="assetViewData[row.deviceKey].showButton">
                            <p>{{ assetViewData[row.deviceKey].text }}</p>
                            <span>
                                <img :src='assetViewData[row.deviceKey].img'>
                            </span>
                        </div>
                        <!-- Last Updated -->
                        <div v-show="assetViewData[row.deviceKey].payload.length" class="last-update-div">
                            <img src="../assets/icons/icon-time@2x.png">
                            <span>Last Updated：{{ assetViewData[row.deviceKey].lastUpdateTime }} minutes ago</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script>
    import {
        getAllAssetVo,
        getDevicesDataByAssetIds
    } from '../api'

    import icon_Air_Pressure from '../assets/icons/icon_Air_Pressure@2x.png';
    import icon_CO from '../assets/icons/icon_CO@2x.png';
    import icon_CO2 from '../assets/icons/icon_CO2@2x.png';
    import icon_motion from '../assets/icons/icon_motion@2x.png';
    import icon_Ozone from '../assets/icons/icon_Ozone@2x.png';
    import icon_temperature2 from '../assets/icons/icon_temperature2.5@2x.png';
    import icon_TVOC from '../assets/icons/icon_TVOC@2x.png';
    import icon_temperature from '../assets/icons/icon_temperature@2x.png';
    import icon_battery from '../assets/icons/icon_battery@2x.png';
    import icon_closure from '../assets/icons/icon_closure@2x.png';
    import icon_humidity from '../assets/icons/icon_humidity@2x.png';
    import icon_PM1 from '../assets/icons/icon_PM1@2x.png';
    import icon_PM4 from '../assets/icons/icon_PM4@2x.png';
    import icon_PM10 from '../assets/icons/icon_PM10@2x.png';
    import icon_Sound from '../assets/icons/icon_sound@2x.png';
    import icon_Formaldehyde from '../assets/icons/icon_Formaldehyde@2x.png';
    import icon_Light from '../assets/icons/icon_Light@2x.png';
    import icon_fan_speed from '../assets/icons/icon_fan_speed@2x.png';
    import icon_switch from '../assets/icons/icon_switch@2x.png';
    import icon_voltage from '../assets/icons/icon_voltage@2x.png';
    import icon_no2 from '../assets/icons/icon_no2@2x.png';
    import icon_mode from '../assets/icons/icon_mode@2x.png';

    import downImg from '../assets/icons/down@2x.png';
    import upImg from '../assets/icons/up@2x.png';
    import refreshUrl from '../assets/icons/refresh@2x.png';


    const MIN_ASSET_NUMBER = 6;
    // const PAYLOAD_RULS = ['locked_state', 'battery_level', 'Temperature', 'Formaldehyde', 'Sound', 'CO2', 'Ozone', 'CO', 'NO2', 'Air_Pressure', 'TVOC', 'PM2.5', 'Relative_Humidity', 'PM1', 'PM4', 'PM10', 'Light']
    // const TABLE_DATA_RULES = ['name', 'support', 'type'];

    const iconMap = {
        'air_pressure': icon_Air_Pressure,
        'co': icon_CO,
        'co2': icon_CO2,
        'formaldehyde': icon_Formaldehyde,
        'light': icon_Light,
        'motion': icon_motion,
        'ozone': icon_Ozone,
        'pm1': icon_PM1,
        'pm2.5': icon_temperature2,
        'pm4': icon_PM4,
        'pm10': icon_PM10,
        'sound': icon_Sound,
        'tvoc': icon_TVOC,
        'temperature': icon_temperature,
        'battery_level': icon_battery,
        'battery': icon_battery,
        // 'locked_state': icon_closure,
        'last_open': icon_closure,
        'open_by': icon_motion,
        'relative_humidity': icon_humidity,
        'fan_speed': icon_fan_speed,
        'switch': icon_switch,
        'voltage': icon_voltage,
        'no2': icon_no2,
        'mode': icon_mode,
    }

    export default {
        // data(){
        // 	return{
        // 		menu: "assets"
        // 	}
        // },
        name: 'Assets',
        props: {
            // msg: String,
            // records: {
            // 	type: Array,
            // 	default: () => [
            // 		{id: 1, name: 'PROD-DS-1', description: 'Lifesmarts CUBE Door/Window sensor', support: 'Inbound', type: 'Native', value1: '90%', value2: 'Open', value3: 'Released', value4: 'Not detected'},
            // 		{id: 2, name: 'PROD-DS-2', description: 'Lifesmarts CUBE Door/Window sensor', support: 'Inbound', type: 'Native', value1: '90%', value2: 'Open', value3: 'Released', value4: 'Not detected'},
            // 		{id: 3, name: 'PROD-DS-3', description: 'Lifesmarts CUBE Door/Window sensor', support: 'Inbound', type: 'Native', value1: '90%', value2: 'Open', value3: 'Released', value4: 'Not detected'},
            // 	],
            // },
        },

        data() {
            return {
                expandStatus: {},
                assetVoList: [],
                iconMap: iconMap,
                // assetIds: '',
                deviceDataList: {
                    // '1':{'payload': {'Air_Pressure': 1, 'NO2': 2, 'CO2': 2, 'CO': 2, 'Ozone': 2, 'Temperature': 2, 'Relative_Humidity': 2, 'TVOC': 2}},
                    // '2':{'payload': {'Air_Pressure': 1, 'NO2': 2, 'CO2': 2, 'CO': 2, 'Ozone': 2, 'Temperature': 2, 'Relative_Humidity': 2, 'TVOC': 2}}
                },
                refreshUrl,
                search: '',
                disabledItemDataTooltip: false,
            }
        },
        computed: {
            assetViewData: function () {
                // 需要展示的数据
                let computedData = {};
                let nowTimeStamp = new Date().getTime()
                for (let key in this.deviceDataList) {
                    let oldPayload = this.deviceDataList[key].payload;
                    let newDeviceData = {
                        'expand': this.expandStatus[key] ? true : false
                    };
                    let payloadData = []; // 过滤后的对象的结果
                    oldPayload.forEach(oPayload => {
                        if (Object.keys(iconMap).indexOf(oPayload['type'].toLowerCase()) !== -1) {
                            payloadData = oldPayload
                        }
                    });
                    if (!newDeviceData.expand && Object.keys(oldPayload).length > MIN_ASSET_NUMBER) {
                        let newPayload = [];
                        for (let k of Object.keys(payloadData).slice(0, MIN_ASSET_NUMBER)) {
                            newPayload[k] = oldPayload[k];
                        }
                        newDeviceData.payload = newPayload;
                    } else {
                        newDeviceData.payload = payloadData;
                    }
                    if (Object.keys(oldPayload).length < MIN_ASSET_NUMBER + 1) {
                        newDeviceData.showButton = false;
                    } else {
                        newDeviceData.showButton = true;
                    }
                    if (newDeviceData.expand) {
                        newDeviceData.text = 'Fold';
                        newDeviceData.img = upImg;
                    } else {
                        newDeviceData.text = 'Expand';
                        newDeviceData.img = downImg;
                    }
                    if (this.deviceDataList[key].timestamp) {
                        newDeviceData.lastUpdateTime = parseInt(((nowTimeStamp - this.deviceDataList[key]
                            .timestamp) / 1000) / 60) // 去除毫秒 除 60 得到分钟
                    } else {
                        newDeviceData.lastUpdateTime = null
                    }
                    computedData[key] = newDeviceData;
                }
                return computedData;
            },
            tableDataFilter: function () {
                // 如果搜索框内没有值, 则显示默认数据, 否则进入if
                if (this.search) {
                    return this.assetVoList.filter(av => {
                        return String(av.name).toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
                            String(av.attr.support).toLowerCase().indexOf(this.search.toLowerCase()) !== -
                            1 || String(av.attr.type).toLowerCase().indexOf(this.search.toLowerCase()) !== -
                            1
                    });
                } else {
                    return this.assetVoList;
                }

                // var assetVoList = Object.assign(this.assetVoList, {});
                // if (this.search !== '') {
                //     var data = []; // 最终过滤后的数据
                //     // // 从数组中遍历出单个对象
                //     for (let i = 0; i < assetVoList.length; i++) {
                //         var flag = false; // name找到后，不从子级attr中搜索
                //         // 遍历对象的key, 通过key再取出值，将对象内的值和搜索的值进行判断
                //         for(let key in assetVoList[i]){
                //             // 根据 TABLE_DATA_RULES 中对应的 KEY 进行查询
                //             if (TABLE_DATA_RULES.indexOf(key) == -1) {
                //                 continue;
                //             }
                //             // console.log(key, assetVoList[i][key].toString().toLowerCase().indexOf(this.search.toString().toLowerCase()) != -1,'---',assetVoList[i][key].toString());
                //             if(assetVoList[i][key].toString().toLowerCase().indexOf(this.search.toString().toLowerCase()) != -1){
                //                 data.push(assetVoList[i]);
                //                 flag = true;
                //                 break;
                //             }
                //         }
                //         for(let key in assetVoList[i].attr){
                //             // 通过上一个循环将name找到并有匹配值后, 不查找attr里面的内容, 否则的话继续查找attr内的值是否匹配
                //             if (flag) {
                //                 break;
                //             }
                //             // 根据 TABLE_DATA_RULES 中对应的 KEY 进行查询
                //             if (TABLE_DATA_RULES.indexOf(key) == -1) {
                //                 continue;
                //             }
                //             // console.log(key, assetVoList[i].attr[key].toString().toLowerCase().indexOf(this.search.toString().toLowerCase()) != -1,'---',assetVoList[i].attr[key].toString());
                //             if(assetVoList[i].attr[key].toString().toLowerCase().indexOf(this.search.toString().toLowerCase()) != -1){
                //                 data.push(assetVoList[i])
                //                 break;
                //             }
                //         }
                //     }
                // } else {
                //     data = assetVoList;
                // }
                // return data;
            }
        },
        mounted: function () {
            this.getData();
        },
        methods: {
            showItemValueTooltip(str) {
                this.$nextTick(function () {
                    const tag = this.$refs[str][0]
                    const parentWidth = tag.parentNode.offsetWidth // 允许的最大宽度
                    const contentWidth = tag.offsetWidth // 文字的宽度
                    this.disabledItemDataTooltip = contentWidth <= parentWidth // 宽度不足时出现... 并显示 tooltip
                });
            },
            expandFold(key) {
                let newStatus = Object.assign({}, this.expandStatus);
                if (newStatus[key]) {
                    newStatus[key] = false;
                } else {
                    newStatus[key] = true;
                }
                this.expandStatus = newStatus;
            },
            getData() {
                getAllAssetVo().then(assetVos => {
                    this.assetVoList = assetVos
                    if (this.assetVoList && this.assetVoList.length > 0) {
                        let assetIds = this.assetVoList.map(vo => vo.assetId).join(',');
                        getDevicesDataByAssetIds(assetIds).then(mapDeviceData => {
                            this.deviceDataList = mapDeviceData
                            // console.log(mapDeviceData);
                            // console.log(this.deviceDataList[1])  ------数组没有这个值是返回 undefined
                        })
                    }
                })
            },
            refreshData() {
                this.assetVoList = [] // 页面刷新效果
                this.deviceDataList = {} // 页面刷新效果
                this.getData();
            }
        }
    }
</script>

<style scoped>
    /* el-table */
    ::v-deep .el-table {
        width: 100%;
        border-radius: 2px;
        margin-top: 24px;
        background-color: #182130;
        padding-bottom: 75px;
    }

    ::v-deep .el-table::before {
        height: 0px;
    }

    ::v-deep .el-table th,
    ::v-deep .el-table td {
        background-color: #182130;
        font-family: PingFangSC-Regular, PingFang SC;
        border: none;
        padding-left: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, .02);
    }

    ::v-deep .el-table tr {
        background-color: #182130;
    }

    ::v-deep .el-table th {
        height: 53px;
        font-size: 14px;
        font-weight: 400;
        color: #DDDDDD;
    }

    ::v-deep .el-table td {
        padding-bottom: 12px;
        padding-top: 16px;
        color: #FFFFFF;
        vertical-align: top;
    }

    ::v-deep .el-table tbody tr:hover>td {
        background-color: #182130 !important;
        opacity: 1;
    }

    /*  el-table => table-label-name */
    .table-label-name-image {
        width: 52px;
        height: 52px;
        background-color: #2C374A;
        margin-right: 23px;
        display: block;
        float: left;
        border-radius: 45%;
    }

    .table-label-name-image+span {
        font-size: 15px;
        font-weight: 500;
    }

    .table-label-name-image+span+span {
        font-size: 13px;
        font-weight: 400;
        opacity: .4;
        display: block;
    }

    /* el-table => table-label-probeReadings */
    .table-label-probeReadings .items {
        width: 150px;
        height: 57px;
        border: 1px solid rgba(151, 151, 151, .07);
        margin: 5px;
        float: left;
    }

    .table-label-probeReadings .items img {
        width: 20px;
        height: 20px;
        display: block;
        margin: 10px 5px 0px 12px;
        float: left;
    }

    .table-label-probeReadings .items .name {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, .5);
        margin-top: 5px;
        display: block;
    }

    .table-label-probeReadings .items .data {
        font-size: 15px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
        display: block;
    }

    /* el-table => fold */
    .table-label-probeReadings .fold {
        clear: both;
        height: 20px;
        cursor: pointer;
        text-align: center;
        line-height: 20px;
    }

    .table-label-probeReadings .fold p {
        width: 24px;
        height: 14px;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
        display: inline;
        margin-right: 6px;
        opacity: 0.5;
    }

    .table-label-probeReadings .fold span img {
        width: 12px;
        height: 12px;
        vertical-align: middle;
    }

    /* refresh-img */
    .refresh-img {
        width: 12px;
        height: 12px;
        vertical-align: middle;
        text-align: center;
        margin-left: 19px;
        cursor: pointer;
    }

    /* last-update */
    .last-update-div {
        clear: both;
    }

    .last-update-div img {
        width: 12px;
        height: 12px;
        vertical-align: middle;
        margin: 5px;
    }

    .last-update-div span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.4;
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* **********************************************************  container  *************************************************************************** */

    .container {
        z-index: -1;
        /* position: absolute; */
        /* left: 50%; */
        /* top: 50%; */
        /* margin-left: -600px; */
        /* margin-top: -295px; */
        margin-top: 25px;
        /* width: 1200px; */
        /*height: 537px;*/

        margin-left: 121px;
        margin-top: 18px;
        margin-right: 121px;
        padding-bottom: 36px;
    }

    /* Search Box (Home, Assets) */
    .search-box {
        float: right;
        width: 360px;
        height: 48px;
        border: none;
    }

    .search-input-form {
        display: inline-block;
        border: none;
        z-index: 2;
        padding-left: 58px;
        width: 300px;
        height: 48px;
        color: #FFFFFF;
        background: #252F40 url('../assets/dashboard-search-magnifier.png') no-repeat;
        background-position-x: 12px;
        background-position-y: 12px;
    }

    input::-webkit-input-placeholder {
        z-index: 3;
        width: 45px;
        height: 17px;
        font-size: 14px;
        font-family: Helvetica;
        color: #FFFFFF transparent;
        line-height: 17px;
    }

    /* Search Box */

    .container-first-box {
        display: flex;
        justify-content: space-between;
        z-index: 0;

        width: 1200px;
        height: 140px;
    }


    .search-box {
        display: inline-block;
        z-index: 1;
        margin-left: 723px;
        width: 360px;
        height: 48px;
        /* background: url(../assets/dashboard-search-magnifier.png) 24px 24px no-repeat; 
        background-position: 12px 12px; */
        /* background: url('../assets/dashboard-search-magnifier.png') 24px 24px no-repeat center; */
        /* background-image: url('../assets/dashboard-search-magnifier.png') no-repeat center; */
        /* background: url('../assets/dashboard-search-magnifier.png') 24px 24px no-repeat scroll right center transparent; */
        /* background: #252F40 url('../assets/dashboard-search-magnifier.png') 24px 24px no-repeat scroll left center transparent; */
        border: none;
    }


    /* #magnifier {
        display: inline-block;
        z-index: 0;
        position: absolute;

        margin-left: 735px;
        margin-top: 47px;
    }


    #search {
        display: inline-block;
        z-index: 0;
        position: absolute;
        margin-left: 783px;
        margin-top: 49px;
        width: 45px;
        height: 17px;
        font-size: 14px;
        font-family: Helvetica;
        color: #FFFFFF;
        line-height: 17px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    } */

    .container-last-box {
        z-index: 0;
        margin-top: 30px;

        width: 1200px;
        height: 284px;
        background: #182130;
        border-radius: 2px;
    }


    .password-set-box {
        z-index: 1;

        margin-top: 48px;

        width: 1003px;
        height: 47px;
    }

    .password {
        z-index: 2;
        width: 64px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
    }

    .set {
        z-index: 2;
        margin-top: 11px;
        width: 22px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
    }

    .edit-button {
        z-index: 2;
        position: absolute;
        margin-top: -38px;
        margin-left: 1001px;

        width: 67px;
        height: 29px;
        background: #FCB900;
        border-radius: 2px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    .edit-text {
        width: 29px;
        height: 15px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 13px;
    }

    .add_button-and-search-box {
        height: 50px;
    }
</style>