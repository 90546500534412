import { render, staticRenderFns } from "./CoolAutomationSetting.vue?vue&type=template&id=6b61f367&scoped=true"
import script from "./CoolAutomationSetting.vue?vue&type=script&lang=js"
export * from "./CoolAutomationSetting.vue?vue&type=script&lang=js"
import style0 from "./CoolAutomationSetting.vue?vue&type=style&index=0&id=6b61f367&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b61f367",
  null
  
)

export default component.exports