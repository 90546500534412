<template>

    <div>
        <div class="container page-connect" v-if="connectPage === true">
            <div id="connect_filter-and-search-box">
            </div>
            <div class="connect-list-box">
                <div class="connect-item" v-for="connect in connectList" :key="connect.id">
                    <div class="connect-name1">{{ connect.name }}</div>
                    <div class="connect-name2">ID:{{ connect.id }}</div>
                    <div class="connect-image1"><img :src="connect.logoUrl"></div>
                    <div class="connect-button1" @click="entryConnectDetail(connect)">
                        {{ connect.type }}
                    </div>
                </div>
            </div>
        </div>

        <a-i-detail v-if="connectDetailPage === true" :connect="connect" @goConnect="goConnect"></a-i-detail>
    </div>

</template>

<script>
    import {
        getAllConnect
    } from '../api'
    import AIDetail from './AIDetail.vue';

    export default {
        components: {
            AIDetail
        },
        name: 'AI',
        props: {},
        data() {
            return {
                connectList: [],
                currentConnect: null,

                connectPage: true,

                connect: Object,

                connectDetailPage: false
            }
        },
        methods: {
            entryConnectDetail(connect) {
                this.connectPage = false
                this.connectDetailPage = true
                this.connect = connect
            },
            goConnect() {
                this.connectDetailPage = false
                this.connectPage = true
            }
        },
        mounted: function () {
            getAllConnect().then(connects => {
                this.connectList = connects.filter(connect => connect.type === 'A.I.')
            })
        },
        // updated: function(){
        // 	console.log(this.IQs)
        // }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* **********************************************************  container  *************************************************************************** */
    .container {
        z-index: 0;
        position: relative;
        margin-left: 121px;
        margin-top: 25px;
        margin-right: 121px;
        margin-bottom: 121px;

        min-height: 537px;
    }

    .page-connect .cpt-connect-link1 {
        padding: 4px 33px;
        border: 1px solid #FCB900;
        border-radius: 4px;
        border: 1px solid transparent;
        margin-right: 33px;
    }

    .page-connect .cpt-connect-link1.active,
    .page-connect .cpt-connect-link1:hover {
        border-color: #FCB900;
    }

    .page-connect .cpt-connect-link1 span {
        font-size: 14px;
        color: #FFFFFF;
        line-height: 22px;
        font-weight: 500;
    }

    .page-connect .cpt-connect-link1.active span,
    .page-connect .cpt-connect-link1:hover span {
        color: #FCB900;
    }

    /* .page-connect #connect_filter-and-search-box {line-height:50px; margin-bottom:30px; } */
    /* 需要显示 add、search 将注释打开*/
    .page-connect .connect_filter-and-search-box {
        height: 15px;
        width: 1200px;
    }

    /* 起到隐藏未写的 add、search作用  上面打开，这里就注释掉*/
    /* display: flex; justify-content: center;  */
    /* .page-connect .connect-item {background: #182130; border-radius: 2px; width: 138px; margin-right: 16.5px; margin-bottom:17px; padding:24px; float:left; text-align:center; } */





    /* connect the display section */
    .connect-list-box {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
    }

    .page-connect .connect-item {
        background: #182130;
        border-radius: 2px;
        width: 138px;
        margin-right: 16.5px;
        margin-bottom: 17px;
        padding: 24px;
        float: left;
        text-align: center;
        /* flex-shrink: 0.9;  */
        /* justify-content:center; */
        /* align-items: center; */
        /* justify-content: ; */
    }

    /* .page-connect .connect-item:nth-child(6n+0) {margin-right:0px;} */
    .page-connect .connect-item .connect-name1 {
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
        margin-bottom: 9px;
    }

    .page-connect .connect-item .connect-name2 {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
        margin-bottom: 15px;
        opacity: 0.5;
    }

    .page-connect .connect-item .connect-image1 {
        margin: auto;
        width: 76px;
        height: 76px;
        background: #2C374A;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    .page-connect .connect-item .connect-button1 {
        margin: auto;
        text-align: center;
        background: #0E8CE3;
        color: #FFFFFF;
        border-radius: 2px;
        font-size: 15px;
        line-height: 14px;
        display: inline-block;
        padding: 8px 24px;
        cursor: pointer;
    }

    /* connect the display section */
</style>